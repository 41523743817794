import {
  Box,
  Button,
  Container,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store';

const CustomContainer = styled('div')(
  ({ theme }) => `
  width: 100%;
  
  padding: 95px 0 0;
  background: ${theme.colors.alpha.white[100]};

  @media (max-width: 1440px) {
    
  }

  @media (max-width: 768px) {
    height: auto;
  }
`
);

const MUIContainer = styled(Container)(
  ({ theme }) => `
  
`
);
const VideoBox = styled(Box)(
  ({ theme }) => `
  width: 35%;

  @media (max-width: 700px) {
    width: 100%;
  }
`
);
const TextBox = styled(Box)(
  ({ theme }) => `
  width: 600px;

  @media (max-width: 768px) {
    width: 100%;
  }
`
);
const ButtonsBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;

  

  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 20px;
  }

  @media (max-width: 425px) {
    flex-direction: column;

    .MuiButton-root {
      margin: 0 0 5px;
      width: 100%;
    }
  }
`
);
const CustomBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
);
const CustomGradient = styled('span')(
  ({ theme }) => `
  background: -webkit-linear-gradient(360deg,#020024 0%,${theme.colors.primary.main} 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
);

const CustomVideo = styled('video')(
  ({ theme }) => `
    object-fit: cover;
    border-radius: 15px;
    width: 100%;
    height: 680px;

  @media (max-width: 768px) {
    height: 550px;
  }
`
);

function Hero() {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    ConfigsReducer: { loggedIn, language }
  } = useSelector((state: RootState) => state);

  return (
    <MUIContainer>
      <CustomContainer>
        <CustomBox>
          <TextBox>
            <Typography
              variant="h1"
              mb={2}
              sx={{
                fontSize: {
                  md: 60,
                  sm: 30
                }
              }}
            >
              {/* Elevate Your Paid Social Media Campaigns with creators */}
              {/* {t('Unlock the Power of Collaborating with Vetted Creators')} */}
              {t('Work with Trusted')}<br></br>
              {t('Content Makers')}
            </Typography>
            {/* <Typography variant="h4" mb={3} lineHeight={1.6}>
              Enhance your marketing efforts by collaborating with multiple
              vetted creators simultaneously. Experience a consistent stream of
              authentic{' '}
              <CustomGradient>User Generated Content (UGC)</CustomGradient> for
              your brand, starting at an affordable rate of just $70 per video.
              Boost your advertising performance with whitelisted ads and reach
              new heights in your campaigns.
            </Typography> */}
            <Typography mb={1} lineHeight={1.6}>
              {t("Wanna take your marketing up a notch? It's time to tap into real deal")} 
              <CustomGradient>{t('User Generated Content (UGC)')}</CustomGradient>
              . {t("I'm talking authentic videos created just for you by awesome creators")}.
            </Typography>
            <Typography mb={1} lineHeight={1.6}>
              {t('Imagine having fresh UGC at your fingertips - real peeps sharing real experiences with your brand. This stuff performs so much better than generic stock content')}.
            </Typography>
            <Typography mb={3} lineHeight={1.6}>
              {t('The best part? You can work with a bunch of top-notch creators at once for an affordable price. Starting from a $70 per high-quality video. An absolute steal!')}
            </Typography>
            <Typography color={theme.colors.primary.main} sx={{ mt: 1, mb: 1 }}>
              {t('JOIN NOW')}
            </Typography>
            <ButtonsBox>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                size="large"
                onClick={() =>
                  navigate(loggedIn ? '/dashboard/main' : '/account/login')
                }
              >
                {t("I'm a brand")}
              </Button>
              <Button
                variant="outlined"
                // sx={{ ml: 1 }}
                size="large"
                onClick={() => navigate(`/become-a-creator`)}
              >
                {t("I'm a creator")}
              </Button>
            </ButtonsBox>
          </TextBox>
          <VideoBox>
            <CustomVideo
              playsInline={true}
              autoPlay
              muted
              loop
              preload="auto"
              src="/static/images/videos/hero-video.mp4"
              poster="/static/images/videos/1.png"
            />
          </VideoBox>
        </CustomBox>
      </CustomContainer>

      {/* <Container sx={{ mt: 2, mb: 4 }}>
        <Box alignItems={'center'} justifyContent={'center'} flexDirection={'column'} display={'flex'}>
          <Typography
            variant="h1"
            textAlign={'center'}
            sx={{
              fontSize: {
                md: 50,
                sm: 30,
                xs: 15
              }
            }}
          >
            Unlock your creative potential and join the{' '}
            <CustomGradient>exclusive Elli Creators Whitelist!</CustomGradient>
          </Typography>

          <Typography
            textAlign={'center'}
            mt={2}
            sx={{
              fontSize: {
                md: 25,
                sm: 25,
                xs: 15
              }
            }}
          >
            Register now to gain early access and receive a limited-time promo
            code, empowering you to bring your unique vision to life.
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{ mb: 5, mt: 5 }}
            onClick={() => {}}
          >
            Register Now!
          </Button>
        </Box>

        {/* <DiscountDialog
          setCloseDialog={setCloseDialog}
          closeDialog={closeDialog}
        /> */}
      {/* </Container> */}
    </MUIContainer>
  );
}

export default Hero;
