import {
  Box,
  styled,
  Typography,
  useTheme,
  Container,
  Grid,
  Divider
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import Brands from '../Home/Brands';
import { useNavigate } from 'react-router-dom';
import { AccessTime, ArrowForwardIos, Event } from '@mui/icons-material';

const OverviewWrapper = styled(Box)(
  ({ theme }) => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
`
);
const Content = styled('div')(
  ({ theme }) => `
   margin-top: 100px;
   padding: 30px 0;
`
);

const CustomImg = styled('img')(
  ({ theme }) => `
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
  
`
);
const CoverImageBox = styled('div')(
  ({ theme }) => `
  width: 100%;
`
);

const FooterText = styled('div')(
  ({ theme }) => `
  grid-column-gap: 1rem;
  padding-top: 0.8rem;
  display: flex;
  margin-top: 30px;
`
);
const Text = styled('div')(
  ({ theme }) => `
  grid-column-gap: 0.3rem;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: .5fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: flex;
`
);

const CustomBreadcrumb = styled('a')(
  ({ theme }) => `
  text-decoration: none;
  color: ${theme.colors.alpha.black[100]};
  font-weight: 700;
  transition: all 300ms ease-in-out;

  &:hover {
    color: ${theme.colors.primary.main};
  }
`
);

const TextContainer = styled(Box)(
  ({ theme }) => `
  border-radius: 1rem;
  padding: 4rem 4rem 2rem;
  margin-top: 50px;
  background: #f9f9f9;
`
);
const BoldText = styled('span')(
  ({ theme }) => `
  font-weight: 700;
`
);

function Ugc101() {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <OverviewWrapper>
      <Helmet>
        <title>{t('Elli Creators - UGC 101')}</title>
      </Helmet>
      <Header />

      <Content>
        <Container>
          <Box mb={3}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} md={6} lg={6}>
                <Box display={'flex'} alignItems={'center'} pt={2}>
                  <CustomBreadcrumb href="/blog">{t('Blog')}</CustomBreadcrumb>
                  <ArrowForwardIos style={{ fontSize: 12, margin: '0 5px' }} />
                  <Typography fontWeight={'700'}>{t('UGC 101')}</Typography>
                </Box>
                <Box mt={5}>
                  <Typography variant="h1">
                    {t('A Comprehensive Guide, to Excelling with User Generated Content, for Beginners')}
                  </Typography>
                </Box>
                <FooterText>
                  <Text>
                    <Event />
                    <div>{t('Sep')} 15, 2023</div>
                  </Text>
                  <Text>
                    <AccessTime />
                    <div>5</div>
                    <div>{t('Min')}</div>
                  </Text>
                </FooterText>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CoverImageBox>
                  <CustomImg alt="" src="/static/images/blog/ugc.jpeg" />
                </CoverImageBox>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <TextContainer>
            <Typography mb={2}>
              {t("Hey! If you're a brand owner I'm sure you're constantly, on the lookout for marketing strategies. Lately theres been some buzz around this concept called user generated content (UGC). What is it and how can you leverage it to boost your business?")}
            </Typography>
            <Typography mb={4}>
              {t("Well you're in luck because I've got all the information you need here in this beginners guide. We'll cover everything, from the basics to techniques so that you can start dominating with UGC.")}{' '}
            </Typography>
            <Typography variant="h3" mb={1}>
              {t('Get ready to level up like a pro!')}
            </Typography>
            <Typography variant="h4" mb={2}>
              {t('Lets start with the basics. What exactly is UGC?')}
            </Typography>
            <Typography mb={2}>
              {t("To put it simply UGC refers to any content that is created by your customers or enthusiastic fans. This can include media posts, videos, blog articles and more! Of your brand producing material it's your fans who take the lead.")}
            </Typography>
            <Typography mb={4}>
              {t("It's, like having your own team of brand advocates doing marketing for you! Now that's definitely something.")}
            </Typography>
            <Typography variant="h4" mb={2}>
              {t('Where does this incredible user generated content (UGC) originate from?')}
            </Typography>
            <Typography mb={2}>
              In cases UGC emerges naturally on social media platforms. Imagine
              a scenario where a customer's absolutely thrilled, with your
              product and decides to mention you in an Instagram post expressing
              their admiration. It's, like hitting the jackpot!
            </Typography>
            <Typography mb={2}>
              Alternatively you can actively encourage your audience to create
              UGC by organizing contests or campaigns. For instance you could
              invite people to share a photo featuring your product and tag your
              brand for a chance to win a prize!
            </Typography>
            <Typography mb={4}>
              Regardless of the method UGC stems directly from your supporters,
              which grants it that sought after authenticity.
            </Typography>
            <Typography mb={2} variant="h4">
              How is it different, from marketing?
            </Typography>
            <Typography mb={2}>
              That's a question! Lets compare user generated content (UGC) to
              strategies;
            </Typography>
            <Typography mb={1}>
              ● UGC is more cost effective compared to methods like radio ads or
              billboards. It's practically free!
            </Typography>
            <Typography mb={1}>
              ● You can easily measure the performance of UGC through metrics
              like likes and comments.
            </Typography>
            <Typography mb={1}>
              ● Your fans create UGC for you saving an amount of time. No need
              for production!
            </Typography>
            <Typography mb={1}>
              ●‍️ UGC doesn't require any skills or experience. Let your fans
              unleash their creativity!
            </Typography>
            <Typography mb={1}>
              ● Customers perceive UGC as genuine and trustworthy, than branded
              advertisements. Greater trust leads to increased sales!
            </Typography>

            <Typography mb={4} mt={2}>
              So basically user generated content (UGC) offers you marketing at
              a fraction of the cost and effort. It seems like the choice
              doesn't it?
            </Typography>
            <Typography mb={2} variant="h4">What makes it so special?</Typography>
            <Typography mb={2}>
              There are a few reasons why UGC should become your best friend;
            </Typography>
            <Typography mb={1}>
              ●‍️ UGC expands your reach. Boosts brand awareness. A single UGC
              post can reach all your fans and even the creators network!
            </Typography>
            <Typography mb={1}>
              ●‍️ It encourages engagement. People enjoy reacting to UGC created
              by individuals just, like them.
            </Typography>
            <Typography mb={1}>
              ●‍️ It provides proof. Potential customers tend to convert after
              seeing UGC shared by existing fans.
            </Typography>
            <Typography mb={1}>
              ●‍️ It leads to conversion rates! In fact 60% of consumers have
              stated that UGC makes them more likely to make a purchase. That's
              a win!
            </Typography>
            <Typography mb={2}>
              When executed effectively UGC acts as a growth hacking superpower,
              for your brand.. You might be wondering. What kinds of UGC options
              are there?
            </Typography>

            <Typography mb={2} mt={4}>
              Fans have a range of options when it comes to creating content.
              The possibilities are limitless. All they need is a touch of
              creativity. Let me share with you some of the favored user
              generated content formats;
            </Typography>
            <Typography mb={1}>
              ●‍️ Photos featuring your product or service
            </Typography>
            <Typography mb={1}>
              ●‍️ Short videos reviewing or unboxing your product
            </Typography>
            <Typography mb={1}>●‍️ Positive social media posts and tags</Typography>
            <Typography mb={1}>
              ●‍️ Blog posts about their experience with your brand
            </Typography>
            <Typography mb={1}>●‍️ Testimonials and customer quotes</Typography>
            <Typography mb={1}>
              ●‍️ Instagram stories featuring your business
            </Typography>
            <Typography mb={1}>
              ●‍️ Tutorials explaining how to use your product
            </Typography>

            <Typography mb={4}>
              Isn't it amazing how there are possibilities? Let your fans
              unleash their creativity!
            </Typography>

            <Typography mb={2}>
              When it comes to fans getting excited lets take a moment to
              explore some user generated content from brands.
            </Typography>
            <Typography mb={1} variant='h4'>
              Examples of user generated content, for your brand
            </Typography>
            <Typography mb={2}>
              To spark ideas for your user generated content it can be helpful
              to see whats been successful, for other brands. Brace yourself for
              some examples!
            </Typography>

            <Typography mb={2}>
              ●‍️ <BoldText>Coca Cola;</BoldText> The "Share a Coke" campaign proved to be incredibly
              successful, for Coca Cola. They printed the names of individuals
              on their bottles, which resonated with people who enjoyed sharing
              them with their loved ones. The campaigns triumph was so
              remarkable that it expanded to countries.
            </Typography>
            <Typography mb={2}>
              ●‍️ <BoldText>Apples</BoldText> "Shot, on iPhone" campaign exemplifies the success of
              user generated content (UGC) campaigns. Apple encouraged
              individuals to share the photos they captured using their iPhones.
              Selected some of the images to showcase in their advertisements.
              This initiative effectively showcased the world the photo quality,
              with an iPhone.
            </Typography>
            <Typography mb={2}>
              ●‍️ <BoldText>Flynas,</BoldText> an airline recently launched a campaign called "My
              Flynas Story" where they encouraged their customers to share their
              personal experiences and stories from their flights, with Flynas.
              These stories were then showcased on the companys website and
              various social media platforms. The campaign proved to be highly
              successful playing a role, in enhancing Flynass brand perception
              and reputation.
            </Typography>
            <Typography mb={2}>
              ●‍️ <BoldText>Zain Jordan,</BoldText> a telecommunications company, in Jordan organized
              a user generated content campaign called "Zain Selfie." They
              encouraged their customers to share selfies showcasing their usage
              of Zains products and services. The videos were prominently
              displayed on the companys website and social media platforms. This
              campaign proved to be extremely successful playing a role, in
              boosting Zains brand visibility.
            </Typography>

            <Typography mb={4}>
              You can see what we mean by the potential, for creativity right?
              It's no wonder you must be excited to dive into using user
              generated content (UGC) for your brand!
            </Typography>
            <Typography mb={2} variant='h4'>
              Now that you have a grasp of UGC and its ins and outs it's time to
              start strategizing. Here are five valuable tips to achieve UGC
              success;
            </Typography>
            <Typography mb={2}>
              ●‍️ Set UGC goals, such as sales targets or engagement objectives.
              This will help you stay focused.
            </Typography>
            <Typography mb={2}>
              ●‍️ Get together. Brainstorm some UGC campaign ideas and create
              hashtags that your audience will absolutely love participating in.
            </Typography>
            <Typography mb={2}>
              ●‍️ Make sure to provide calls to action across social media
              platforms guiding your fans on how they can engage with your UGC
              initiatives.
            </Typography>
            <Typography mb={2}>
              ●‍️ Reach out directly to your customers. Ask for their support in
              promoting your campaign. Their involvement can truly kickstart the
              momentum you need.
            </Typography>
            <Typography mb={2}>
              ●‍️ Consider offering incentives like giveaways, discounts or
              showcasing their content as a way to encourage creation. This
              creates a win win situation, for everyone involved!
            </Typography>

            <Typography mb={4}>
              So just take a moment unwind. Let your dedicated fans do their
              marketing wizardry!
            </Typography>
            <Typography mb={2} variant='h5'>
              So there you go. All the essentials to kickstart your UGC journey.
              It's the marketing technique you've been overlooking. Incorporate
              these pointers to supercharge your strategy and witness your brand
              flourish. Now go there. Make it happen!
            </Typography>
          </TextContainer>
        </Container>
      </Content>
      <Footer />
    </OverviewWrapper>
  );
}

export default Ugc101;
