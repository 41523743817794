import type { ReactNode } from 'react';
import {
  Collections,
  Dashboard,
  Group,
  LocalShipping,
  Task,
  WorkOutline
} from '@mui/icons-material';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        icon: Dashboard,
        link: '/dashboard/main'
      },
      {
        name: 'Tasks',
        icon: Task,
        link: '/dashboard/tasks'
      },
      {
        name: 'Creators',
        icon: Group,
        link: '/dashboard/creators'
      },
      {
        name: 'Delivery',
        icon: LocalShipping,
        link: '/dashboard/delivery'
      },
      {
        name: 'Videos',
        icon: Collections,
        link: '/dashboard/videos'
      },
      {
        name: 'Brand',
        icon: WorkOutline,
        link: '/dashboard/brand'
      }
    ]
  }
];

export default menuItems;
