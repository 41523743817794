import { Box, Button, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import DiscountDialog from 'src/components/DiscountDialog';
import VideoCarousel from './VideoCarousel';

const Container = styled('div')(
  ({ theme }) => `
  margin: 50px auto;
  text-align: center;
  padding: 30px;

  @media (max-width: 768px) {
    width: 100%;
  }
`
);
const CustomGradient = styled('span')(
  ({ theme }) => `
  background: -webkit-linear-gradient(360deg,#020024 0%,${theme.colors.primary.main} 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
);

function Examples() {
  const { t }: { t: any } = useTranslation();

  const [closeDialog, setCloseDialog] = useState(false);

  return (
    <Container>
      <Box>
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              md: 65,
              sm: 30,
              xs: 15
            }
          }}
        >
          {t('Find real people for')}{' '}
          <CustomGradient>{t('every niche')}</CustomGradient>
        </Typography>
        <Box marginTop={5}>
          <VideoCarousel />
        </Box>
        <Button
          component={RouterLink}
          to={'/creators'}
          variant="contained"
          size="large"
          sx={{ mb: 5, mt: 5 }}
        >
          {t('Show all creators')}
        </Button>
      </Box>

      <DiscountDialog
        setCloseDialog={setCloseDialog}
        closeDialog={closeDialog}
      />
    </Container>
  );
}

export default Examples;
