import { FC, ReactNode, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import i18n from 'src/i18n/i18n';
import { selectLanguage } from 'src/store/ConfigsReducer';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const lang = useSelector(selectLanguage);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <Box
      dir={lang === 'en' ? 'ltr' : 'rtl'}
      sx={{
        flex: 1,
        height: '100%'
      }}
    >
      {children || <Outlet />}
    </Box>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
