import { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Zoom
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { selectCategories } from 'src/store/CategoriesReducer';
import { useSelector } from 'react-redux';
import { brandControllerGetDiscount } from 'src/utils/APIs';
import { useSnackbar } from 'notistack';
import { RootState } from 'src/store';

interface DialogProps {
  setCloseDialog: (type: any) => void;
  closeDialog: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogContent = styled(DialogContent)(
  ({}) => `
   padding-bottom: 40px;
  `
);

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontWeight: '700' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DiscountDialog: FC<DialogProps> = ({ setCloseDialog, closeDialog }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    ConfigsReducer: { language }
  } = useSelector((state: RootState) => state);

  const categories = useSelector(selectCategories);

  const [loading, setLoading] = useState(false);

  const Schema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    email: Yup.string().required(t('Required')),
    industry: Yup.string().required(t('Required'))
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      industry: ''
    },
    onSubmit: () => submit(),
    validationSchema: Schema
  });

  const { errors, touched, values, handleBlur, handleChange, handleSubmit } =
    formik;

  const submit = async () => {
    setLoading(true);
    await brandControllerGetDiscount({
      name: values.name,
      email: values.email
    })
      .then(() => {
        setCloseDialog(false);
        setLoading(false);
        enqueueSnackbar(t('Please check your email!'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          TransitionComponent: Zoom
        });
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          TransitionComponent: Zoom
        });
      });
  };

  return (
    <Dialog
      open={closeDialog}
      dir={language === 'en' ? 'ltr' : 'rtl'}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '400px!important'
        }
      }}
      maxWidth="sm"
      onClose={() => setCloseDialog(false)}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setCloseDialog(false)}
      >
        {t('Claim my discount')}
      </BootstrapDialogTitle>

      <CustomDialogContent dividers>
        <form onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.name && errors.name)}
            sx={{ width: '350px' }}
            margin="normal"
            helperText={touched.name && errors.name}
            label={t('Name')}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.email && errors.email)}
            sx={{ width: '350px' }}
            margin="normal"
            helperText={touched.email && errors.email}
            label={t('Email')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            variant="outlined"
          />

          <FormControl
            sx={{ width: '350px' }}
            variant="outlined"
            margin="normal"
          >
            <InputLabel>{t('Category')}</InputLabel>
            <Select
              value={values.industry}
              name="industry"
              onChange={handleChange}
              label={t('Category')}
              error={Boolean(touched.industry && errors.industry)}
              MenuProps={{
                dir: language === 'en' ? 'ltr' : 'rtl'
              }}
            >
              {categories?.map((statusOption) => (
                <MenuItem key={statusOption.id} value={statusOption.id}>
                  {statusOption.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ color: '#FF1943' }}>
              {Boolean(touched.industry && errors.industry) && errors.industry}
            </FormHelperText>
          </FormControl>

          <Button
            type="submit"
            size="large"
            sx={{ mt: 1, width: '100%' }}
            variant="contained"
            disabled={loading}
            startIcon={loading ? <CircularProgress size="1rem" /> : null}
            onClick={() => {
              handleSubmit();
            }}
          >
            {t('Get 15% OFF discount code!')}
          </Button>
        </form>
      </CustomDialogContent>
    </Dialog>
  );
};

export default DiscountDialog;
