// @ts-nocheck
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';

import BB_ttf from './Assets/Fonts/BalooBhaijaan2-Regular.ttf'; // Import your BalooBhaijaan2-Regular font files
import BB_eot from './Assets/Fonts/BalooBhaijaan2-Regular.eot';
import BB_woff from './Assets/Fonts/BalooBhaijaan2-Regular.woff';
import BB_woff2 from './Assets/Fonts/BalooBhaijaan2-Regular.woff2';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import './i18n/i18n';
import CustomLoading from './components/CustomLoading';

import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { selectLanguage } from './store/ConfigsReducer';

const history = createBrowserHistory();

const persistor = persistStore(store);

const theme = createTheme(
  (() => {
    const arabicFontSrc = {
      ttf: BB_ttf,
      eot: BB_eot,
      woff: BB_woff,
      woff2: BB_woff2
    };

    return {
      body: {
        backgroundColor: 'red',
        direction: 'ltr'
      },
      typography: {
        fontFamily: 'BalooBhaijaan2-Regular'
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
              font-family: 'BalooBhaijaan2-Regular';
              src: url(${arabicFontSrc.ttf}) format('truetype'),
                  url(${arabicFontSrc.eot}) format('truetype'),
                  url(${arabicFontSrc.woff}) format('truetype'),
                  url(${arabicFontSrc.woff2}) format('truetype');
              font-weight: 300;
              font-style: normal;
              font-display: auto;
            }
        `
        }
      }
    };
  })()
);

ReactDOM.render(
  <HelmetProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SidebarProvider>
            <BrowserRouter>
              <ScrollTop />
              {/* <AuthProvider> */}
              <App />
              {/* </AuthProvider> */}
            </BrowserRouter>
          </SidebarProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
