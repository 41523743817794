import { Box, Button, styled } from '@mui/material';
import { slide as Menu } from 'react-burger-menu';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { selectAccount } from 'src/store/AuthReducer';
import Logo from '../Logo';
import { useTranslation } from 'react-i18next';

const LogoContainer = styled('div')(
  ({ theme }) => `
  margin-top: -15px;
  margin-bottom: 25px !important;
  `
);
const CustomBox = styled(Box)(
  ({ theme }) => `
    display: none !important;

    @media (max-width: 650px) {
        display: flex !important;
        align-items: center;
        position: absolute;
        bottom: 0;
    }
  `
);

export default (props) => {
  const { t }: { t: any } = useTranslation();
  const account = useSelector(selectAccount);

  return (
    <Menu {...props}>
      <LogoContainer>
        <Logo />
      </LogoContainer>

      <a className="menu-item" href="/creators">
        {t('Creators')}
      </a>

      {/* <a className="menu-item" href="/pricing">
        {t('Pricing')}
      </a> */}

      <a className="menu-item" href="/about-us">
        {t('About us')}
      </a>

      <a className="menu-item" href="/contact-us">
        {t('Contact us')}
      </a>

      <CustomBox>
        <a className="menu-item" href="/become-a-creator">
          {t('Become a creator')}
        </a>
        <Box>
          <Button
            component={RouterLink}
            to={account === null ? '/account/login' : '/business'}
            variant="contained"
          >
            {t('Log in / Sign up')}
          </Button>
        </Box>
      </CustomBox>
    </Menu>
  );
};
