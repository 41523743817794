import { Box, Typography, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Container = styled('div')(
  ({ theme }) => `
  margin: 70px auto;
  text-align: center;
  width: 900px;

  @media (max-width: 768px) {
    width: 100%;
  }
`
);

function NoFollowers() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Container>
      <Box
        mt={8}
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDirection="column"
        mb={10}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: 'center',
            fontSize: {
              md: 65,
              sm: 30,
              xs: 15
            }
          }}
        >
          {t('No followers, no worries')}.
        </Typography>
        <Typography variant="h3">
          {t('We bring brands, you bring content')}.
        </Typography>
        <Typography fontSize={18} textAlign="center" mt={3} paddingX={3}>
          {/* You don't have to be an influencer. All you need is a smartphone or a
          working camera and you're ready to start making money for your
          content. */}
          {t("All you need is a smartphone or a working camera, and you're all set to monetize your creative work. Get started today and turn your passion into profit")}!
        </Typography>
      </Box>
    </Container>
  );
}

export default NoFollowers;
