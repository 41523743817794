import React, { useRef, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Rating,
  Grid
} from '@mui/material';
import {
  ArrowBack,
  ArrowForward,
  Pause,
  PlayArrow,
  VolumeOff,
  VolumeUp
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const videoData = [
  {
    id: 1,
    name: 'Roaa F.',
    age: 33,
    niche: 'Health & Wellness',
    concept: 'Voice-over showcase',
    rating: 4.9,
    reviews: 200,
    image: '/api/placeholder/400/320',
    caption: 'makes it easier than ever to cook new meals at home',
    videoUrl: 'https://elli-production.s3.eu-central-1.amazonaws.com/homepage/6.mp4'
  },
  {
    id: 2,
    name: 'Rachel D.',
    age: 25,
    niche: 'Cosmetics',
    concept: 'Listicle',
    rating: 4.9,
    reviews: 850,
    image: '/api/placeholder/400/320',
    caption: '3. feeling super supportive',
    videoUrl: 'https://elli-production.s3.eu-central-1.amazonaws.com/homepage/8.mp4'
  },
  {
    id: 3,
    name: 'Marah M.',
    age: 21,
    niche: 'Beauty',
    concept: 'Tutorial',
    rating: 4.9,
    reviews: 150,
    image: '/api/placeholder/400/320',
    caption: 'Sharp',
    videoUrl: 'https://elli-production.s3.eu-central-1.amazonaws.com/homepage/4.mp4'
  },
  {
    id: 4,
    name: 'Abedalrahman A.',
    age: 48,
    niche: 'Health & Wellness',
    concept: 'Testimonial',
    rating: 4.9,
    reviews: 400,
    image: '/api/placeholder/400/320',
    caption: 'finally controlling blood sugar without strict diet',
    videoUrl: 'https://elli-production.s3.eu-central-1.amazonaws.com/homepage/2.mp4'
  },
  {
    id: 5,
    name: 'Basma A.',
    age: 48,
    niche: 'Cosmetics',
    concept: 'Testimonial',
    rating: 4.9,
    reviews: 400,
    image: '/api/placeholder/400/320',
    caption: 'finally controlling blood sugar without strict diet',
    videoUrl: 'https://elli-production.s3.eu-central-1.amazonaws.com/homepage/3.mp4'
  },
  {
    id: 6,
    name: 'Majd A.',
    age: 48,
    niche: 'Food Beverages',
    concept: 'Testimonial',
    rating: 4.9,
    reviews: 400,
    image: '/api/placeholder/400/320',
    caption: 'finally controlling blood sugar without strict diet',
    videoUrl: 'https://elli-production.s3.eu-central-1.amazonaws.com/homepage/1.mp4'
  },
  {
    id: 7,
    name: 'Tasnim A.',
    age: 48,
    niche: 'Consumer Goods',
    concept: 'Testimonial',
    rating: 4.9,
    reviews: 400,
    image: '/api/placeholder/400/320',
    caption: 'finally controlling blood sugar without strict diet',
    videoUrl: 'https://elli-production.s3.eu-central-1.amazonaws.com/homepage/11.mp4'
  },
  {
    id: 8,
    name: 'Shahed Y.',
    age: 48,
    niche: 'Marketing Advertising',
    concept: 'Testimonial',
    rating: 4.9,
    reviews: 400,
    image: '/api/placeholder/400/320',
    caption: 'finally controlling blood sugar without strict diet',
    videoUrl: 'https://elli-production.s3.eu-central-1.amazonaws.com/homepage/12.mp4'
  }
];

const VideoCard = ({ video }) => {
  const { t }: { t: any } = useTranslation();

  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const toggleSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <Card sx={{ height: '430px', position: 'relative', borderRadius: 5 }}>
      <Box>
        <video
          ref={videoRef}
          src={video.videoUrl}
          style={{
            width: '100%',
            height: '430px',
            objectFit: 'cover'
          }}
          loop
          muted={isMuted}
          playsInline
          autoPlay
        />
        <IconButton
          onClick={toggleSound}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            // top: 8,
            // right: 15,
            // background: 'white',
            color: 'white'
          }}
        >
          {isMuted ? (
            <VolumeOff sx={{ visibility: 'hidden' }} />
          ) : (
            <VolumeUp sx={{ visibility: 'hidden' }} />
          )}
        </IconButton>
      </Box>
      <CardContent
        sx={{
          bgcolor: 'rgba(206, 137, 168, 0.9)',
          color: 'white',
          width: '100%',
          position: 'absolute',
          bottom: 0,
          paddingBottom: '15px !important',
          textAlign: 'left'
        }}
      >
        <Typography variant="body2" color={'#ffffff'}>
          {t('Name')}: {video.name}
        </Typography>
        {/* <Typography variant="body2" color={'#ffffff'}>
          Age: {video.age}
        </Typography> */}
        <Typography variant="body2" color={'#ffffff'}>
         {t('Niche')}: {video.niche}
        </Typography>
        {/* <Typography variant="body2" color={'#ffffff'}>Concept: {video.concept}</Typography> */}
      </CardContent>
    </Card>
  );
};

const VideoCarousel = () => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const videosPerPage = 4;
  const pageCount = Math.ceil(videoData.length / videosPerPage);

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : pageCount - 1));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => (prevPage < pageCount - 1 ? prevPage + 1 : 0));
  };

  const currentVideos = videoData.slice(
    currentPage * videosPerPage,
    (currentPage + 1) * videosPerPage
  );

  return (
    <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
      <Grid container spacing={2}>
        {currentVideos.map((video) => (
          <Grid item xs={12} sm={6} md={3} key={video.id}>
            <VideoCard video={video} />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 4
        }}
      >
        <IconButton onClick={handlePrev}>
          <ArrowBack />
        </IconButton>
        <Typography sx={{ mx: 2 }}>
          {String(currentPage + 1).padStart(2, '0')} —{' '}
          {String(pageCount).padStart(2, '0')}
        </Typography>
        <IconButton onClick={handleNext}>
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

export default VideoCarousel;
