import {
  Box,
  styled,
  Typography,
  useTheme,
  Container,
  Grid,
  Divider
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import Brands from '../Home/Brands';
import { useNavigate } from 'react-router-dom';
import { AccessTime, ArrowForwardIos, Event } from '@mui/icons-material';

const OverviewWrapper = styled(Box)(
  ({ theme }) => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
`
);
const Content = styled('div')(
  ({ theme }) => `
   margin-top: 100px;
   padding: 30px 0;
`
);

const CustomImg = styled('img')(
  ({ theme }) => `
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
  
`
);
const CoverImageBox = styled('div')(
  ({ theme }) => `
  width: 100%;
`
);

const FooterText = styled('div')(
  ({ theme }) => `
  grid-column-gap: 1rem;
  padding-top: 0.8rem;
  display: flex;
  margin-top: 30px;
`
);
const Text = styled('div')(
  ({ theme }) => `
  grid-column-gap: 0.3rem;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: .5fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: flex;
`
);

const CustomBreadcrumb = styled('a')(
  ({ theme }) => `
  text-decoration: none;
  color: ${theme.colors.alpha.black[100]};
  font-weight: 700;
  transition: all 300ms ease-in-out;

  &:hover {
    color: ${theme.colors.primary.main};
  }
`
);

const TextContainer = styled(Box)(
  ({ theme }) => `
  border-radius: 1rem;
  padding: 4rem 4rem 2rem;
  margin-top: 50px;
  background: #f9f9f9;
`
);
const BoldText = styled('span')(
  ({ theme }) => `
  font-weight: 700;
`
);

function CreatorComprehensiveHandbook() {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <OverviewWrapper>
      <Helmet>
        <title>Elli Creators - Creators Comprehensive Handbook</title>
      </Helmet>
      <Header />

      <Content>
        <Container>
          <Box mb={3}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} md={6} lg={6}>
                <Box display={'flex'} alignItems={'center'} pt={2}>
                  <CustomBreadcrumb href="/blog">Blog</CustomBreadcrumb>
                  <ArrowForwardIos style={{ fontSize: 12, margin: '0 5px' }} />
                  <Typography fontWeight={'700'}>
                    Creators Comprehensive Handbook
                  </Typography>
                </Box>
                <Box mt={5}>
                  <Typography variant="h1">
                    Crafting Remarkable User Generated Content (UGC) Campaigns;
                    A Comprehensive Handbook
                  </Typography>
                </Box>
                <FooterText>
                  <Text>
                    <Event />
                    <div>Sep 15, 2023</div>
                  </Text>
                  <Text>
                    <AccessTime />
                    <div>3</div>
                    <div>Min</div>
                  </Text>
                </FooterText>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CoverImageBox>
                  <CustomImg alt="" src="/static/images/blog/creator.jpeg" />
                </CoverImageBox>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <TextContainer>
            <Typography mb={2}>
              Hey there folks! Are you interested, in establishing a connection
              with your audience? It's time to dive into the world of user
              generated content (UGC). Unleash its potential.
            </Typography>
            <Typography mb={4}>
              UGC adds that authentic human touch that people crave. It brings
              forth a sense of genuineness. Takes engagement to the level. This
              comprehensive guide will take your UGC game to the next level.
              Let's gooo!
            </Typography>

            <Typography variant="h3" mb={1}>
              Tip 1: Set Clear Goals and Strategies
            </Typography>
            <Typography mb={2}>
              Every awesome UGC campaign starts with well-defined goals and
              strategies. What exactly do you want to accomplish?
            </Typography>

            <Typography mb={2}>● Increase website traffic or sales?</Typography>
            <Typography mb={2}>● Boost brand awareness?</Typography>
            <Typography mb={2}>
              ● Foster deeper community engagement?
            </Typography>
            <Typography mb={3}>
              Clearly understanding your objectives lets you craft targeted
              strategies and meaningful metrics for success.
            </Typography>
            <Typography mb={2} fontStyle={'italic'}>
              Pro tip: Use S.M.A.R.T framework - Specific, Measurable,
              Achievable, Relevant, Time-bound. This keeps you focused and
              aligned.
            </Typography>
            <Typography mb={2} fontWeight={'600'}>
              For example: Our goal is to increase brand awareness among 18-25
              year olds by 15% in the next 3 months through UGC campaigns.
            </Typography>

            <Typography variant="h3" mb={1} mt={3}>
              Tip 2: Guide Your Audience to Quality UGC
            </Typography>
            <Typography mb={2}>
              While UGC is renowned for authenticity, providing prompts can
              elevate submission quality. Give your community ideas to spark
              their creativity.
            </Typography>
            <Typography mb={2}>● Launch photo or video challenges</Typography>
            <Typography mb={2}>● Share writing/storytelling prompts</Typography>
            <Typography mb={2}>
              ● Suggest review or testimonial templates
            </Typography>
            <Typography mb={2}>
              Even basic guidance like optimal lighting tips for photography
              contests can help level up UGC.
            </Typography>
            <Typography mb={2} fontWeight={'600'}>
              For example: "Show us your favorite way to use [product] this
              summer!" - gives a specific yet open prompt.
            </Typography>

            <Typography variant="h3" mb={1} mt={3}>
              Tip 3: Remove Participation Friction
            </Typography>
            <Typography mb={2}>
              Complex UGC participation hurts engagement. Optimize for an
              awesome user experience instead.
            </Typography>
            <Typography mb={2}>
              ● Use clear calls-to-action to contribute
            </Typography>
            <Typography mb={2}>● Simplify submission forms</Typography>
            <Typography mb={2}>
              ● Share trackable hashtags for social campaigns
            </Typography>
            <Typography mb={2} fontStyle={'italic'}>
              Quick win: Create a dedicated landing page for your UGC campaign
              with details, FAQs, and instant submission options.
            </Typography>

            <Typography variant="h3" mb={1}>
              Tip 4: Recognize and Reward Contributors
            </Typography>
            <Typography mb={2}>
              Consistency and quality stems from motivated creators. Recognize
              standout contributors.
            </Typography>
            <Typography mb={2}>Some ideas:</Typography>
            <Typography mb={2}>
              ● Monthly social features of top creators
            </Typography>
            <Typography mb={2}>● Exclusive perks and content access</Typography>
            <Typography mb={2}>
              ● Prizes or giveaways for top contributors
            </Typography>
            <Typography mb={2}>● Simple shoutouts and engagement</Typography>
            <Typography mb={2} fontStyle={'italic'}>
              Idea: Host "Contributor Spotlights" highlighting creators of the
              month. This motivates and ensures a steady stream of content.
            </Typography>

            <Typography variant="h3" mb={1} mt={3}>
              Tip 5: Foster Two-Way Interactions
            </Typography>
            <Typography mb={2}>
              Don't just take content - engage with your contributors!
            </Typography>
            <Typography mb={2}>● Share and comment on submissions</Typography>
            <Typography mb={2}>● Provide feedback and suggestions</Typography>
            <Typography mb={2}>● Answer questions and concerns</Typography>
            <Typography mb={2} fontStyle={'italic'}>
              Hot Tip: Host live Q&As on Instagram/Facebook. Discuss UGC
              submissions and chat real-time with your community.
            </Typography>

            <Typography variant="h3" mb={1} mt={3}>
              Tip 6: Follow UGC Best Practices
            </Typography>
            <Typography mb={2}>
              Respecting UGC legalities and best practices is crucial.
            </Typography>
            <Typography mb={2}>
              ● Always get direct permission to reuse content
            </Typography>
            <Typography mb={2}>
              ● Understand nuances like copyright and fair use
            </Typography>
            <Typography mb={2}>● Respect contributor privacy</Typography>
            <Typography mb={2}>● Credit creators for their work</Typography>
            <Typography mb={2}>
              Having clear usage rights guidelines and terms is key.
            </Typography>

            <Typography variant="h4" mb={1} mt={6}>
              Now Go Create Epic UGC!
            </Typography>
            <Typography>
              UGC facilitates authentic connections between creators and
              communities. Apply these tips to take your UGC game to the next
              level! Want hands-on help? Let's chat!
            </Typography>
          </TextContainer>
        </Container>
      </Content>
      <Footer />
    </OverviewWrapper>
  );
}

export default CreatorComprehensiveHandbook;
