import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { CategoryEntity } from '../utils/APIs';

interface InitialState {
  categories: CategoryEntity[] | null;
}

const initialState: InitialState = {
  categories: null
};

const slice = createSlice({
  name: 'Categories',
  initialState,
  reducers: {
    setCategories: (state, action: { payload: CategoryEntity[] }) => {
      state.categories = action.payload;
    },
  }
});
export default slice.reducer;

export const { setCategories } =
  slice.actions;
export const selectCategories = (state: RootState) =>
  state.CategoriesReducer.categories;
