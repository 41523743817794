import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
      color: ${theme.palette.text.primary};
      display: flex;
      text-decoration: none;
      width: 53px;
      margin: 0 auto;
      font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to="/overview">
      <img
        style={{ height: 40 }}
        alt="Elli Creators"
        src="/static/images/logo/logo.svg"
        loading="lazy"
      />
    </LogoWrapper>
  );
}

export default Logo;
