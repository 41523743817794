import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { ProductEntity } from '../utils/APIs';

interface InitialState {
  products: ProductEntity[] | null;
}

const initialState: InitialState = {
  products: null
};

const slice = createSlice({
  name: 'productsReducer',
  initialState,
  reducers: {
    setProducts: (state, action: { payload: ProductEntity[] }) => {
      state.products = action.payload;
    },
    updateProductById: (state, action: { payload: ProductEntity }) => {
      state.products = state.products.map((product) => {
        return product.id === action.payload.id ? action.payload : product;
      });
    },
    removeProductById: (state, action: { payload: number }) => {
      state.products = state.products.filter(
        (product) => product.id !== action.payload
      );
    },
    removeProducts: (state) => {
      state.products = null;
    }
  }
});
export default slice.reducer;

export const { setProducts, removeProductById, updateProductById } =
  slice.actions;
export const selectProducts = (state: RootState) =>
  state.ProductsReducer.products;
