import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const IconBg = styled('div')(
  ({ theme, color }) => `
  background: ${color};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto;
`
);
const CustomBox = styled(Box)(
  ({ theme, color }) => `
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 700px) {
    flex-direction: column;
  }
  
`
);
const CustomGradient = styled('span')(
  ({ theme }) => `
  background: -webkit-linear-gradient(360deg,#020024 0%,${theme.colors.primary.main} 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
);

function CreatorsPerks() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Container sx={{ mt: 8 }}>
      <Box>
        <Typography
          variant="h1"
          sx={{
            textAlign: 'center',
            fontSize: {
              md: 65,
              sm: 30,
              xs: 15
            }
          }}
        >
          <CustomGradient>{t('Love creating content?')}</CustomGradient>
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="h3" textAlign="center">
          {t('Creator Perks')}
        </Typography>
      </Box>

      <Grid container>
        <CustomBox width="100%" mt={5} mb={2}>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            display="flex"
            alignItems="center"
            flexDirection="column"
            minHeight={162}
            sx={{
              mb: {
                sm: 3,
                xs: 3
              }
            }}
          >
            <Box>
              <IconBg color={theme.colors.primary.light}>
                <img
                  alt="Extra money"
                  src="/static/images/svg/crown.svg"
                  height={30}
                />
              </IconBg>
              <Typography variant="h4" mt={1} mb={1}>
                {t('Extra earnings')}
              </Typography>
            </Box>
            <Typography textAlign="center">
              {t(
                'The greater the number of videos you create, the higher your earning potential becomes'
              )}
              .
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            display="flex"
            alignItems="center"
            flexDirection="column"
            minHeight={162}
            sx={{
              mb: {
                sm: 3,
                xs: 3
              }
            }}
          >
            <IconBg color={theme.colors.lightPurple.light}>
              <img
                alt="Free products"
                src="/static/images/svg/bus.svg"
                height={30}
              />
            </IconBg>
            <Typography variant="h4" mt={1} mb={1}>
              {t('Free products')}
            </Typography>
            <Typography textAlign="center">
              {t(
                'All the products that brands send for you to work with will remain in your possession even after the job is completed'
              )}
              .
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            display="flex"
            alignItems="center"
            flexDirection="column"
            minHeight={162}
            sx={{
              mb: {
                sm: 3,
                xs: 3
              }
            }}
          >
            <IconBg color={'#ed8f9c'}>
              <img
                alt="No price"
                src="/static/images/svg/money.svg"
                height={30}
              />
            </IconBg>
            <Typography variant="h4" mt={1} mb={1}>
              {t('No price negotiations')}
            </Typography>
            <Typography textAlign="center">
              {t(
                "You'll never find yourself in a position to negotiate rates. We offer fixed prices only, ensuring a straightforward and transparent experience for you"
              )}
              .
            </Typography>
          </Grid>
        </CustomBox>
      </Grid>
    </Container>
  );
}

export default CreatorsPerks;
