import { Box, Button, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DiscountDialog from 'src/components/DiscountDialog';

const Container = styled('div')(
  ({ theme }) => `
  margin: 70px auto;
  text-align: center;
  width: 900px;

  @media (max-width: 768px) {
    width: 100%;
  }
`
);
const CustomGradient = styled('span')(
  ({ theme }) => `
  background: -webkit-linear-gradient(360deg,#020024 0%,${theme.colors.primary.main} 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
);

function NewToElli() {
  const { t }: { t: any } = useTranslation();

  const [closeDialog, setCloseDialog] = useState(false);

  return (
    <Container>
      <Box>
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              md: 65,
              sm: 30,
              xs: 15
            }
          }}
        >
          {t('Welcome to Elli!')} <br></br>
          {t('Get')}{' '}
          <CustomGradient>{t('15% OFF your first UGC Video order!')}</CustomGradient>
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{ mb: 5, mt: 5 }}
          onClick={() => setCloseDialog(true)}
        >
          {t('Claim my discount!')}
        </Button>
      </Box>

      <DiscountDialog
        setCloseDialog={setCloseDialog}
        closeDialog={closeDialog}
      />
    </Container>
  );
}

export default NewToElli;
