import { Box, Typography, styled, useTheme, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'src/store/ConfigsReducer';

const CustomContainer = styled('div')(
  ({ theme }) => `
  background: ${theme.colors.lightPurple.lighter};
  border-radius: 10px;
  margin: 70px 0;
`
);
const ButtonsContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  align-items: center;
  margin-top: 30px;
`
);
const CustomBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
`
);
const DownloadImgs = styled('img')(
  ({ theme }) => `
  height: 30px;

  @media (max-width: 425px) {
    height: 18px;
  }
`
);
const CustomLink = styled('a')(
  ({ theme }) => `
    transition: opacity 300ms ease-in-out;
    background-color: #000; 
    border-style: none; 
    border-width: 0; 
    border-radius: 10px; 
    justify-content: center; 
    align-items: center;
    display: flex;
    padding: 10px 20px;
    &:hover {
      opacity: 0.5;
    }
`
);
const ContentContainer = styled('div')(
  ({ theme }) => `
  padding: 80px;

  @media (max-width: 600px) {
    padding: 30px;
  }
`
);
const CustomImg = styled('img')(
  ({ theme }) => `
  height: 400px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  @media (max-width: 1024px) {
    height: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }

  &.ar {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`
);

function AreYouACreator() {
  const { t }: { t: any } = useTranslation();
  const lang = useSelector(selectLanguage);
  const theme = useTheme();

  return (
    <Container>
      <CustomContainer>
        <CustomBox>
          <ContentContainer>
            <Typography color={theme.colors.primary.main}>
              {t('ARE YOU A CREATOR?')}
            </Typography>
            <Typography
              variant="h1"
              pb={2}
              sx={{
                fontSize: {
                  md: 35,
                  sm: 20,
                  xs: 15
                }
              }}
            >
              {t('Get creative. Enjoy the process')}. <br></br>
              {t('Earn while doing what you love')}.
            </Typography>
            <Typography>
              {t('Download the app and start earning today')}.
            </Typography>
            <ButtonsContainer>
              <CustomLink
                target="_blank"
                sx={{ mr: 1 }}
                href="https://apps.apple.com/jo/app/elli-creators/id1669536868"
              >
                <DownloadImgs
                  alt="Download for App Store"
                  src="/static/images/svg/apple.svg"
                  // height={30}
                />
              </CustomLink>
              <CustomLink
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.elli.elliapp&pli=1"
              >
                <DownloadImgs
                  alt="Download for Google Play"
                  src="/static/images/svg/google.svg"
                  // height={30}
                />
              </CustomLink>
            </ButtonsContainer>
          </ContentContainer>
          <Box height={400}>
            <CustomImg
              alt="Download for Google Play"
              src="/static/images/logo/mage.png"
              loading="lazy"
            />
          </Box>
        </CustomBox>
      </CustomContainer>
    </Container>
  );
}

export default AreYouACreator;
