import './style.scss';
import { Accordion, AccordionCell } from './Accordion';
import { Box, Button, Container, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CustomBox = styled(Box)(
  ({ theme }) => `
  display:flex;
  align-items: center;
  justify-content:center;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
);
const StartWorkingBox = styled(Box)(
  ({ theme }) => `
  display:flex;
  align-items: center;
  justify-content:space-between;

  @media (max-width: 700px) {
    flex-direction: column;

    button {
      margin-top: 20px;
    }
  }
`
);

function HowItWorks() {
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const {
    ConfigsReducer: { loggedIn, language }
  } = useSelector((state: RootState) => state);

  const accordionCells = [
    <AccordionCell key="accordion-cell-1" isActive={true} onClick={() => {}}>
      <h4>{t('Task')}</h4>
      <div className="accordion-cell-content">
        <CustomBox>
          <div className="cell-text">
            <h1>{t('Create a task')}</h1>
            <p>
              {t(
                'Fill out the task in a few minutes by selecting the relevant options for your need.'
              )}
            </p>
          </div>
          <Box>
            <img
              alt="task"
              src="/static/images/placeholders/task.png"
              loading="lazy"
            />
          </Box>
        </CustomBox>
      </div>
    </AccordionCell>,
    <AccordionCell key="accordion-cell-2" isActive={false} onClick={() => {}}>
      <h4>{t('Match Creators')}</h4>
      <div className="accordion-cell-content">
        <CustomBox>
          <div className="cell-text">
            <h1>{t('Get matched to the right creators')}</h1>
            <p>
              {t(
                'We will match you to the perfect creator profiles that fit with your brand and campaign goal. Review the selection and start your collaborations'
              )}
              .
            </p>
          </div>
          <Box>
            <img
              alt="task"
              src="/static/images/placeholders/creators.png"
              loading="lazy"
            />
          </Box>
        </CustomBox>
      </div>
    </AccordionCell>,
    <AccordionCell key="accordion-cell-3" isActive={false} onClick={() => {}}>
      <h4>{t('Get content')}</h4>
      <div className="accordion-cell-content">
        <CustomBox>
          <div className="cell-text">
            <h1>{t('Get content')}</h1>
            <p>
              {t(
                'Receive content from your creators. Request an edit or click approve, and download the content'
              )}
              .
            </p>
          </div>
          <Box>
            <img
              alt="task"
              src="/static/images/placeholders/videos.png"
              loading="lazy"
            />
          </Box>
        </CustomBox>
      </div>
    </AccordionCell>
  ];

  return (
    <Container>
      <div className={`${language === 'en' ? 'how-it-works' : 'how-it-works ar'}`}>
        <StartWorkingBox mb={5}>
          <Box maxWidth={'34rem'}>
            <Typography
              variant="h1"
              mb={2}
              sx={{
                fontSize: {
                  md: 35,
                  sm: 15
                }
              }}
            >
              {t('Start working with creators in 3 easy steps')}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontSize: {
                  md: 16,
                  sm: 13
                }
              }}
            >
              {t(
                'Every step is integrated into the platform to streamline your collaborations'
              )}
              .
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="large"
              onClick={() =>
                navigate(loggedIn ? '/dashboard/main' : '/account/login')
              }
            >
              {t('Try it out')}
              {language === 'en' ? (
                <ArrowForward sx={{ ml: 1 }} />
              ) : (
                <ArrowBack sx={{ ml: 1 }} />
              )}
            </Button>
          </Box>
        </StartWorkingBox>

        <Accordion>{accordionCells}</Accordion>
      </div>
    </Container>
  );
}

export default HowItWorks;
