const aeJSON = {
  'Become a creator': 'كن احدى صناع المحتوى',
  Creators: 'صناع المحتوى',
  Pricing: 'الاسعار',
  'About us': 'عن ايلي',
  'Contact us': 'تواصل معنا',
  'Log in / Sign up': 'تسجيل دخول / انشاء حساب',
  'Unlock the Power of Collaborating with Vetted Creators':
    'اكتشف قوة تأثير صناع المحتوى المعتمدين',
  "Wanna take your marketing up a notch? It's time to tap into real deal":
    'هل تريد رفع مستوى التسويق الخاص بك؟ حان الوقت لدخولك عالم المحتوى الحقيقي الذي ينشأه المستخدمون ',
  'User Generated Content (UGC)': '(UGC)',
  'Elli Creators - Login': 'ايلي لصناع المحتوى - تسجيل الدخول',
  'Elli Creators - Recover Password':
    'ايلي لصناع المحتوى - استعادة كلمة المرور',
  'Elli Creators - Register': 'ايلي لصناع المحتوى - التسجيل',
  'Elli Creators - Coming Soon': 'ايلي لصناع المحتوى - قريبًا',
  'Elli Creators - Become a Creator': 'ايلي لصناع المحتوى - كن منشئًا',
  'The Elli Creators': 'صناع المحتوى في ايلي',
  'Elli Creators - Edit Task': 'ايلي لصناع المحتوى - تعديل المهمة',
  'Elli Creators - Task Details': 'ايلي لصناع المحتوى - تفاصيل المهمة',
  "I'm talking authentic videos created just for you by awesome creators":
    'مقاطع فيديو حقيقية يتم صنعها خصيصاً لعلامتك التجارية من صناع المحتوى الأهم في العالم العربي',
  'Imagine having fresh UGC at your fingertips - real peeps sharing real experiences with your brand. This stuff performs so much better than generic stock content':
    'تخيل استبدال الفيديوهات الجاهزة بفيديوهات خاصة لأشخاص حقيقيون يشاركون تجاربهم  مع علامتك التجارية  لضمان تفاعل أكبر من جمهورك المستهدف بكبسة زر!',
  'The best part? You can work with a bunch of top-notch creators at once for an affordable price. Starting from a $70 per high-quality video. An absolute steal!':
    'اما الجزء الآفضل! هو أنك تستطيع العمل مع نخبة من صناع المحتوى من مكان واحد وبأسعار خيالية! 70$ فقط تستطيع الحصول على فيديو مخصص لعلامتك التجارية بجودة عالية جداً',
  'JOIN NOW': 'إنضم الان',
  "I'm a brand": 'نحن علامة تجارية',
  "I'm a creator": 'انا صانع محتوى',
  Task: 'المهمة',
  'Create a task': 'انشاء مهمة',
  'Fill out the task in a few minutes by selecting the relevant options for your need.':
    'أنشئ  المهمة في غضون دقيقة من خلال اختيار الخيارات الملائمة لاحتياجاتك',
  'Match Creators': 'مطابقة صانع المحتوى',
  'Get matched to the right creators':
    'سيتم عرض أكثر صناع المحتوى مطابقاً لاحتياجك',
  'We will match you to the perfect creator profiles that fit with your brand and campaign goal. Review the selection and start your collaborations':
    'سنقوم بعرض أكثر صناع المحتوى ملائمةً لعلامتك التجارية، هدف حملتك، وجمهورك المستهدف، اختر وابدأ حملتك.',
  'Get content': 'احصل على المحتوى',
  'Receive content from your creators. Request an edit or click approve, and download the content':
    'استلم المحتوى من صانع المحتوى الذي قمت باختياره، يمكنك طلب تعديلات أو انقر على الموافقة لتحميل المحتوى',
  'Start working with creators in 3 easy steps':
    'ابدأ العمل مع أفضل صناع المحتوى ب ٣ خطوات',
  'Every step is integrated into the platform to streamline your collaborations':
    'خطوات بسيطة لتعاون مُثمر ',
  'Try it out': 'جرب الآن',
  'Welcome to Elli!': 'مرحبًا بك في إيلي!',
  Get: 'احصل',
  '15% OFF your first UGC Video order!':
    'على خصم %15 عند  طلبك  لأول UGC فيديو',
  'Claim my discount!': 'الحصول على الخصم',
  'No followers, no worries': 'لا يوجد لديك متابعين، لا تقلق',
  'We bring brands, you bring content':
    'نحن نزودك بالعروض من العلامات التجارية، وانت تقدم المحتوى',
  "All you need is a smartphone or a working camera, and you're all set to monetize your creative work. Get started today and turn your passion into profit":
    'كل ما تحتاجه هو جهاز السمارتفون الخاص بك، أو كاميرا لتبدأ بجني الأرباح من عملك الإبداعي. ابدأ الآن وحول شغفك إلى أرباح',
  'Authentic UGC Videos Tailored to': 'مقاطع فيديو حقيقية مصممة خصيصاً',
  'Your Product': 'لعلامتك التجارية',
  "Don't wait any longer - sign up and start benefiting from the impact of UGC today! What's more, we proudly offer a 30-day money-back guarantee, ensuring you have nothing to lose":
    'لا  تنتظر أكثر - سجل الآن وابدأ في الاستفادة من تأثير محتوى المستخدمين اليوم! والأهم من ذلك،  نحن نفخر بتقديم ضمان استرداد الأموال لمدة 30 يومًا',
  'Order UGC Video Now!': 'اطلب الفيديو الآن',
  'Love creating content?': 'هل تحب صنع المحتوى',
  'Creator Perks': 'مزايا صناع المحتوى',
  'Extra earnings': 'جني أرباح إضافية',
  'The greater the number of videos you create, the higher your earning potential becomes':
    'كلما زاد عدد الفيديوهات التي تقوم بإنشائها، زادت ارباحك',
  'Free products': 'منتجات مجانية',
  'All the products that brands send for you to work with will remain in your possession even after the job is completed':
    'سيمكنك  الاحتفاظ  بجميع المنتجات التي يتم ارسالها لك من قبل العلامات التجارية حتى بعد اتمامك للمهمة',
  'No price negotiations': 'لا يوجد مفاوضات حول الأسعار',
  "You'll never find yourself in a position to negotiate rates. We offer fixed prices only, ensuring a straightforward and transparent experience for you":
    'لن تتعرض أبداً لمفاوضات حول الأسعار. نحن نقدم أسعار ثابتة لنضمن لك تجربة تتميز بالراحة والشفافية',
  'Trusted by the Leading Brands': 'علامات تجارية نفخر بثقتها بنا',
  'ARE YOU A CREATOR?': 'هل أنت صانع محتوى؟',
  'Get creative. Enjoy the process': 'أطلق العنان لابداعك، وتمتع بالتجربة',
  'Earn while doing what you love': 'اجني الأرباح بينما تفعل ما تحب',
  'Download the app and start earning today': 'حمل التطبيق وابدأ رحلتك اليوم',
  Dashboard: 'لوحة التحكم',
  Profile: 'ملفي',
  'Sign out': 'تسجيل خروج',
  'Elli Creators - Home Page': 'إيلي لصناع المحتوى - الصفحة الرئيسية',
  'Subscribe to the newsletter': 'اشترك في النشرة الإخبارية',
  'and stay in the know': 'وابق على اطلاع',
  'The email provided should be a valid email address':
    'البريد الإلكتروني المُقدم يجب أن يكون عنوان بريد إلكتروني صالحاً.',
  'Field is required': 'الحقل مطلوب',
  'Subscribed to newsletter successfully!':
    'تم الاشتراك في النشرة الإخبارية بنجاح!',
  'Email Address': 'الايميل',
  Blogs: 'المدونة',
  FAQs: 'الأسئلة الشائعة',
  'Terms & conditions': 'الشروط والأحكام',
  'Privacy policy': 'سياسة الخصوصية',
  '© 2024 Elli. All rights reserved': '© 2024 إيلي. جميع الحقوق محفوظة',
  'Answers from product preference form': 'إجابات استمارة تفضيلات المنتج',
  'Use these answers to ship the right products to the creators':
    'استخدم هذه الإجابات لشحن المنتجات المناسبة إلى صناع المحتوى',
  Close: 'إغلاق',
  Algeria: 'الجزائر',
  Bahrain: 'البحرين',
  Egypt: 'مصر',
  Iraq: 'العراق',
  Jordan: 'الأردن',
  Kuwait: 'الكويت',
  Lebanon: 'لبنان',
  Libya: 'ليبيا',
  Morocco: 'المغرب',
  Oman: 'سلطنة عمان',
  Bahrin: 'البحرين',
  Qatar: 'قطر',
  'Saudi Arabia': 'السعودية',
  SaudiArabia: 'السعودية',
  Sudan: 'السودان',
  Tunisia: 'تونس',
  UAE: 'الإمارات',
  'United Arab Emirates': 'الإمارات',
  "'s profile": 'ملف التعريف الخاص بـ',
  for: 'لـ',
  "You'll be able to see the 3 most recent videos created by the creator on the Elli platform as well as their Video Pitch. In case when the creator is new on Elli, you'll be able to see just their Video pitch to check how they act in front of the camera and see if you like their style of filming.":
    'سيكون بإمكانك رؤية آخر 3 فيديوهات تم إنشاؤها من قبل صانع المحتوى على منصة ايلي، بالإضافة إلى فيديو العرض التقديمي الخاص بهم. في حالة كون صانع المحتوى جديدًا على ايلي، ستتمكن فقط من رؤية فيديو العرض التقديمي الخاص بهم للتحقق من كيفية تصرفهم أمام الكاميرا ومعرفة ما إذا كنت تحب أسلوبهم في التصوير.',
  'Creator statistics': 'إحصائيات صانع المحتوى',
  'About creator': 'عن صانع المحتوى',
  'Creator has been rejected successfully!': 'تم رفض صانع المحتوى بنجاح!',
  Reject: 'رفض صانع المحتوى',
  'Creator has been approved successfully!':
    'تمت الموافقة على صانع المحتوى بنجاح!',
  Approve: 'الموافقة على صانع المحتوى',
  'Please check your email!': 'يرجى التحقق من بريدك الإلكتروني!',
  'Claim my discount': 'المطالبة بخصمي',
  'Get 15% OFF discount code!': 'احصل على رمز خصم 15%',
  'Edit product': 'تحرير المنتج',
  'New product': 'منتج جديد',
  'Please fill out all fields before adding a new one.':
    'يرجى ملء جميع الحقول قبل إضافة جديدة.',
  'Should be a valid url': 'يجب أن يكون رابطًا صالحًا',
  'Product edited successfully!': 'تم تحرير المنتج بنجاح!',
  'Physical product': 'منتج ملموس',
  'Add a picture for your product': 'أضف صورة لمنتجك',
  'Product description': 'وصف المنتج',
  'Product name': 'اسم المنتج',
  'This product will be a giveaway for the creator':
    'سيكون هذا المنتج هدية لصانع المحتوى',
  'I want to offer the creator a discount': 'أريد تقديم خصم لصانع المحتوى',
  'What do you intend to offer the creator as a giveaway?':
    'ما الذي تنوي تقديمه لصانع المحتوى كهدية؟',
  'Product price': 'سعر المنتج',
  'Product URL': 'رابط المنتج',
  'How to access your digital product or service?':
    'كيف يمكن الوصول إلى منتجك الرقمي أو خدمتك؟',
  'Product access link': 'رابط وصول المنتج',
  'How can the creator access the product? (website, App store link, etc.)':
    'كيف يمكن لصانع المحتوى الوصول إلى المنتج؟ (رابط الموقع، رابط متجر التطبيقات، إلخ.)',
  'Product access instructions': 'تعليمات وصول المنتج',
  'Use product preferences form to request additional information':
    'استخدم استمارة تفضيلات المنتج لطلب معلومات إضافية',
  Submit: 'إرسال',
  Required: 'مطلوب',
  'Thank you for the rating!': 'شكرًا للتقييم!',
  'Rate creator': 'تقييم صانع المحتوى',
  Performance: 'الأداء',
  'Video quality': 'جودة الفيديو',
  'Audio quality': 'جودة الصوت',
  'Turnaround time': 'وقت التسليم',
  Comment: 'تعليق',
  'TO DELIVER': 'لتسليم',
  'Creator have to share the video in their feeds and their SM stories':
    'يجب على صانع المحتوى مشاركة الفيديو في تغريداتهم وقصصهم على وسائل التواصل الاجتماعي',
  'Requested edits': 'التعديلات المطلوبة',
  Comments: 'تعليقات',
  'Creator preference': 'تفضيل صانع المحتوى',
  Requirements: 'المتطلبات',
  'VIDEO TYPE': 'نوع الفيديو',
  DURATION: 'المدة',
  seconds: 'ثواني',
  'ASPECT RATIO': 'نسبة العرض إلى الارتفاع',
  'Schedule visit': 'جدولة الزيارة',
  'Visit appointments': 'مواعيد الزيارة',
  Scenario: 'السيناريو',
  'What to mention': 'ما يجب ذكره',
  'Content examples': 'أمثلة على المحتوى',
  'Additional details': 'تفاصيل إضافية',
  'A NOTE FROM THE BRAND': 'ملاحظة من العلامة التجارية',
  'Approve video?': 'الموافقة على الفيديو؟',
  'Application has been approved successfully!':
    'تمت الموافقة على الطلب بنجاح!',
  'This video will become final and you will be able to download it.':
    'سيصبح هذا الفيديو نهائيًا وستتمكن من تنزيله.',
  'Yes, approve video': 'نعم، أوافق على الفيديو',
  'Elli Creators - About Us': 'ايلي لصناع المحتوى - من نحن',
  'OUR MISSION': 'مهمتنا',
  'Releasing the creativity of the world': 'إطلاق الإبداع في العالم',
  'Elli is a brand connection platform that connects businesses with creators. We believe that every brand has a unique story to tell, and we want to help them share that story with the world. Our platform makes it easy for brands to find the right creators for their campaigns, and for creators to connect with brands that align with their values and style.':
    'ايلي هي منصة تربط بالعلامات التجارية  بصناع المحتوى. نحن نؤمن بأن لكل علامة تجارية قصة فريدة لترويها، ونحن نرغب في مساعدتهم على مشاركة تلك القصة مع العالم. تقوم  منصتنا على  تسهيل المهمة على العلامات التجارية حيث تساعدهم للوصول إلى  صناع المحتوى المناسبين لحملاتها، وتقوم باعطاء الفرصة لصناع المحتوى للتواصل مع العلامات التجارية التي تتماشى مع قيمهم وأسلوبهم.',
  'Our platform makes it easy to find the perfect match for your brand, and our team of experienced marketers and creative professionals are on hand to ensure the process is seamless. We work closely with you to understand your goals and objectives, and then match you with creators who align with your values and style.':
    'منصتنا تجعل من السهل العثور على التوافق المثالي لعلامتك، وفريقنا المؤلف من المسوقين ذوي الخبرة والمحترفين الإبداعيين متاح لضمان سلاسة العملية. نعمل بشكل وثيق مع العلامات التجارية لفهم أهدافها ومبادئها، ثم نقوم بمطابقة العلامات التجارية مع صناع المحتوى الأكثر  ملائمةٍ',
  "With Elli, you can tell your story in a unique and authentic way, and reach new audiences through the power of creativity. So why not unlock your brand's potential and join us on our mission to connect businesses with the world's best creators.":
    'مع ايلي ستتمكن من اخبار قصة علامتك التجارية الفريدة من نوعها بالطريقة التي تناسبك، لتصل الى جمهورك المستهدف من خلال صنع محتوى ابداعي سريع الانتشار. اكشف إمكانيات علامتك التجارية وانضم لعائلتنا لتشاركنا مهمة ربط العلامات التجارية مع افضل المبدعين وصناع المحتوى في العالم.',
  THEREFORE: 'لذلك..............',
  "If you're a brand looking to connect with creators, or a creator looking for new opportunities, we would love to hear from you. Contact us today to learn more about how Elli can help your business succeed.":
    'اذا كنت علامة تجارية تبحث عن  نخبة صناع المحتوى، أو كنت صانع محتوى تبحث عن فرص جديدة، فهذه هي وجهتك. تواصل معنا اليوم لتعرف المزيد عن ما تقدمه منصة ايلي لنجاح اعمالك ',
  'What are you': 'ماذا',
  'waiting for?': 'تنتظر؟',
  'Become one of the brands': 'وكن واحدًا من العلامات التجارية',
  'utilizing creator marketing': 'التي تستخدم تسويق الصانعين.',
  'Join us': 'انضم إلينا',
  'Are you a': 'هل أنت',
  'Creator?': 'صانع محتوى؟',
  'Get creative. Enjoy the process.': 'ابدع. وتمتع بالعملية.',
  'Earn while doing what you love.': 'اكسب أثناء ممارسة ما تحبه.',
  'Elli Creators - Blog': 'إيلي لصناع المحتوى - المدونة',
  Blog: 'المدونة',
  'Discover proven tips and strategies to leverage user-generated content and creator partnerships for authentic and impactful marketing.':
    'الكشف عن نصائح واستراتيجيات مجربة للاستفادة من محتوى المستخدم وشراكات صانع المحتوى لتسويق أصيل وذو تأثير.',
  'Key Concepts for First-Timers': 'المفاهيم الرئيسية للمبتدئين',
  'User-generated content 101': 'محتوى المستخدم 101',
  "The Beginner's Guide to Slaying It With user-generated content":
    'دليل المبتدئين للتفوق مع محتوى UGC ',
  Sep: 'Sep',
  Min: 'Min',
  'Creators, This is For You': 'صناع المحتوى، هذا لكم',
  'Crafting Remarkable User Generated Content (UGC) Campaigns; A Comprehensive Handbook':
    'تصنيع حملات ملحوظة لمحتوى المستخدم (UGC)؛ دليل شامل',
  'In the ever-evolving landscape of digital marketing, UGC has emerged as a game-changer.':
    'في الساحة المتطورة باستمرار للتسويق الرقمي، ظهر محتوى UGC  كمحدث للعبة.',
  'UGC 101': 'محتوى المستخدم 101',
  'A Comprehensive Guide, to Excelling with User Generated Content, for Beginners':
    'دليل شامل للتفوق مع محتوى UGC ، للمبتدئين',
  'Elli Creators - UGC 101': 'إيلي لصناع المحتوى - محتوى UGC  101',
  "Hey! If you're a brand owner I'm sure you're constantly, on the lookout for marketing strategies. Lately theres been some buzz around this concept called user generated content (UGC). What is it and how can you leverage it to boost your business?":
    'مرحبًا! إذا كنت مالكًا لعلامة تجارية، فأنت بالتأكيد دائمًا في البحث عن استراتيجيات التسويق. في الآونة الأخيرة، كان هناك بعض الضجة حول مفهوم يُسمى محتوى UGC  (UGC). ما هو وكيف يمكنك الاستفادة منه لتعزيز عملك التجاري؟',
  "Well you're in luck because I've got all the information you need here in this beginners guide. We'll cover everything, from the basics to techniques so that you can start dominating with UGC.":
    'حسنًا، فأنت محظوظ لأن لدي كل المعلومات التي تحتاجها هنا في دليل المبتدئين هذا. سنغطي كل شيء، من الأساسيات إلى التقنيات حتى تتمكن من البدء في السيطرة باستخدام محتوى UGC .',
  'Get ready to level up like a pro!': 'استعد للارتقاء كمحترف!',
  'Lets start with the basics. What exactly is UGC?':
    'لنبدأ بالأساسيات. ما هو بالضبط محتوى UGC ؟',
  "To put it simply UGC refers to any content that is created by your customers or enthusiastic fans. This can include media posts, videos, blog articles and more! Of your brand producing material it's your fans who take the lead.":
    'ببساطة، محتوى UGC  يشير إلى أي محتوى يتم إنشاؤه من قبل عملائك أو المعجبين المتحمسين. يمكن أن يشمل ذلك مشاركات وسائل الإعلام، ومقاطع الفيديو، ومقالات المدونات، وأكثر من ذلك! من مواد علامتك التجارية، فإن معجبيك هم من يتولون القيادة.',
  "It's, like having your own team of brand advocates doing marketing for you! Now that's definitely something.":
    'إنه كما لو كان لديك فريق خاص بك من داعمي العلامات التجارية يقومون بالتسويق لك! الآن هذا بالتأكيد شيء مذهل.',
  'Where does this incredible user generated content (UGC) originate from?':
    'من أين ينبع هذا المحتوى المذهل المُنشأ ؟',
  'Your message has been received, we will get back to you as soon as possible!':
    'تم استلام رسالتك، سنعود إليك في أقرب وقت ممكن!',
  'Elli Creators - Contact Us': 'إيلي لصناع المحتوى - اتصل بنا',
  'Say hey!': 'مرحبا!',
  'First name': 'الاسم الأول',
  'Last name': 'الاسم الأخير',
  Email: 'البريد الإلكتروني',
  Company: 'الشركة',
  'How can we help you?': 'كيف يمكننا مساعدتك؟',
  Region: 'المنطقة',
  Message: 'الرسالة',
  'Elli Creators - Creators': 'إيلي لصناع المحتوى - الصانعون',
  Country: 'البلد',
  'No data!': 'لا توجد بيانات!',
  'Take the leap and hire your UGC creator today!':
    'انطلق وقم بتوظيف صانع محتوى UGC  الخاص بك اليوم!',
  'Get started today and hire your first': 'ابدأ اليوم وقم بتوظيف أول',
  'UGC creator': 'صانع محتوى UGC ',
  'with a $20 discount!': 'مع خصم 20 دولار',
  'Receive a $20 discount code instantly in your inbox! Moreover, take advantage of our 30-day money-back guarantee, ensuring you have absolutely nothing to risk!':
    'احصل على كود خصم بقيمة 20 دولارًا على الفور في بريدك الوارد! علاوة على ذلك، استفد من ضمان استرداد الأموال لمدة 30 يومًا، مما يضمن عدم وجود أي مخاطر تمامًا!',
  'Unlock a $20 discount code!': 'فتح كود خصم بقيمة 20 دولارًا!',
  'Hire me for your next video': 'قم بتوظيفي للفيديو التالي',
  Videos: 'مقاطع الفيديو',
  'Total spent': 'الإجمالي المنفق',
  Tasks: 'المهام',
  'Approve Creators': 'الموافقة على صناع المحتوى',
  creator: 'صانع المحتوى',
  creators: 'صناع المحتوى',
  'to approve': 'للموافقة عليه',
  'Approve creators': 'الموافقة على صناع المحتوى',
  'creator applied for': 'صانع المحتوى قدم طلبًا ل',
  'Waiting for creators to apply': 'في انتظار تقديم صناع المحتوى للطلب',
  'Create a task for creators to apply': 'إنشاء مهمة لصناع المحتوى للتقديم',
  'Usually takes up a few hours': 'عادة ما يستغرق بضع ساعات',
  'Create task': 'إنشاء مهمة',
  'Approve videos': 'الموافقة على مقاطع الفيديو',
  video: 'فيديو',
  videos: 'مقاطع الفيديو',
  'Approve video': 'الموافقة على الفيديو',
  'creator submitted a video for': 'قدم صانع المحتوى فيديو لـ',
  'Waiting for the creators to upload videos':
    'في انتظار رفع صناع المحتوى لمقاطع الفيديو',
  'Takes up to 7 days after the product is shipped to creators':
    'يستغرق ما يصل إلى 7 أيام بعد شحن المنتج إلى صناع المحتوى',
  'No videos to approve': 'لا توجد مقاطع فيديو للموافقة عليها',
  'Something shows up here once the creator uploads a video. Stay tuned':
    'سيظهر شيء هنا بمجرد رفع صانع المحتوى لفيديو. ترقب!',
  'Ship products': 'شحن المنتجات',
  product: 'منتج',
  products: 'منتجات',
  'to ship': 'للشحن',
  'product to ship for': 'المنتج للشحن لـ',
  'All products shipped!': 'تم شحن جميع المنتجات!',
  'No products to ship': 'لا يوجد منتجات للشحن',
  'Something shows up here once you approve the creators you wanna work with':
    'سيظهر شيء هنا بمجرد الموافقة على صناع المحتوى الذين ترغب في العمل معهم',
  'Elli Creators - Dashboard': 'Elli Creators - Dashboard',
  'Authentic videos from our video content creators':
    'Authentic videos from our video content creators',
  'How Elli works?': 'How Elli works?',
  'Start with your first video task': 'ابدأ بمهمتك الأولى للفيديو',
  "Add your product and how you'd like to showcase it. We'll guide you every step of the way":
    'أضف منتجك وكيفية عرضه. سنرشدك في كل خطوة على الطريق',
  'Start Now': 'ابدأ الآن',
  Welcome: 'مرحبا',
  'These are your analytics stats for today':
    'هذه هي إحصائيات التحليل لديك لليوم',
  'Complete brand data': 'Complete brand data',
  'Card added successfully': 'تمت إضافة البطاقة بنجاح',
  'An error occurred while adding the card': 'حدث خطأ أثناء إضافة البطاقة',
  'Add Payment Method': 'إضافة طريقة الدفع',
  'Brand data edited saved successfully!':
    'تم حفظ تحرير بيانات العلامة التجارية بنجاح!',
  'Edit brand': 'تحرير العلامة التجارية',
  'Cover image': 'صورة الغلاف',
  Save: 'حفظ',
  Cancel: 'إلغاء',
  Delete: 'حذف',
  'Product has been deleted successfully!': 'تم حذف المنتج بنجاح!',
  'Something went wrong, please try later!':
    'حدث خطأ ما، يرجى المحاولة لاحقًا!',
  'Go back': 'العودة',
  'Digital product': 'منتج رقمي',
  'Digital product or service': 'منتج او خدمة رقمية',
  'Device mockup or a screenshot': 'نموذج جهاز أو لقطة شاشة',
  'Brand color': 'لون العلامة التجارية',
  Name: 'الاسم',
  Type: 'النوع',
  Category: 'الفئة',
  'Brand country': 'بلد العلامة التجارية',
  Website: 'الموقع الإلكتروني',
  'Brand description': 'وصف العلامة التجارية',
  'Facebook URL': 'رابط فيسبوك',
  'Instagram URL': 'رابط انستغرام',
  'Tiktok URL': 'رابط تيك توك',
  'Youtube URL': 'رابط يوتيوب',
  'Twitter URL': 'رابط تويتر',
  'Linkedin URL': 'رابط لينكد إن',
  'Snapchat URL': 'رابط سناب شات',
  'Amazon URL': 'رابط أمازون',
  'Pinterest URL': 'رابط Pinterest',
  'The card has been set to primary': 'تم تعيين البطاقة كبطاقة رئيسية',
  'An error occurred while setting the card to primary':
    'حدث خطأ أثناء تعيين البطاقة كبطاقة رئيسية',
  'The card has been removed successfully': 'تمت إزالة البطاقة بنجاح',
  'An error occurred while removing the card': 'حدث خطأ أثناء إزالة البطاقة',
  'Saved Payment Methods': 'طرق الدفع المحفوظة',
  'Add new card': 'إضافة بطاقة جديدة',
  'Saved Cards': 'البطاقات المحفوظة',
  'No saved cards!': 'لا توجد بطاقات محفوظة!',
  Expires: 'تاريخ انتهاء',
  Primary: 'رئيسي',
  'Remove this card': 'إزالة هذه البطاقة',
  'Recent Transactions': 'المعاملات الأخيرة',
  'Product information': 'معلومات المنتج',
  Edit: 'تعديل',
  'Product image': 'صورة المنتج',
  'Product type': 'نوع المنتج',
  Description: 'الوصف',
  'Access instructions': 'تعليمات الوصول',
  Homepage: 'الصفحة الرئيسية',
  'Balance credit': 'رصيد الائتمان',
  'View deals': 'عرض العروض',
  Balance: 'الرصيد',
  Deals: 'العروض',
  'Choose Content Creation Deal That Is Right For You':
    'اختر صفقة إنشاء المحتوى المناسبة لك',
  'Brand information': 'معلومات العلامة التجارية',
  Phone: 'الهاتف',
  'Social URLs': 'روابط التواصل الاجتماعي',
  Facebook: 'فيسبوك',
  Instagram: 'انستغرام',
  'Brand details': 'تفاصيل العلامة التجارية',
  Products: 'المنتجات',
  Billing: 'الفواتير',
  'Elli Creators - Brand': 'إيلي لصناع المحتوى - العلامة التجارية',
  Brand: 'علامة تجارية',
  Create: 'إنشاء',
  'No available products yet!': 'لا توجد منتجات متاحة حتى الآن!',
  URL: 'رابط',
  'Active Subscriptions': 'الاشتراكات النشطة',
  'View all subscriptions': 'عرض جميع الاشتراكات',
  'High Growth': 'نمو مرتفع',
  'due in': 'مستحق في',
  days: 'أيام',
  'Up to 50 videos': 'حتى 50 فيديو',
  Pay: 'دفع',
  Invite: 'دعوة',
  'Brand role': 'دور العلامة التجارية',
  'Waiting for approval': 'في انتظار الموافقة',
  'My creators': 'صناع المحتوى الخاص بي',
  'No applications available. Yet!': 'لا توجد طلبات متاحة. بعد!',
  Completed: 'مكتمل',
  Applied: 'مقدم',
  Working: 'يعمل',
  Gender: 'الجنس',
  'No applications available yet!': 'لا توجد طلبات متاحة بعد!',
  'The watermark logo is removed upon final video approval.':
    'يتم إزالة شعار العلامة المائية عند الموافقة النهائية على الفيديو.',
  'Required # of creators': 'العدد المطلوب من صناع المحتوى',
  Pending: 'قيد الانتظار',
  Approved: 'تمت الموافقة',
  Rejected: 'مرفوض',
  Answers: 'إجابات',
  'Reject Creator': 'رفض صانع المحتوى',
  'This creator will be rejected! Are you sure?':
    'سيتم رفض هذا الصانع! هل أنت متأكد؟',
  'Approve Creator': 'الموافقة على صانع المحتوى',
  'Creator will be approved to a task.': 'سيتم الموافقة على الصانع لمهمة.',
  "Yes, I'm sure!": 'نعم، أنا متأكد!',
  'Due to ship': 'من المقرر شحنه',
  Shipped: 'تم الشحن',
  Delivered: 'تم التسليم',
  'Data added successfully!': 'تمت إضافة البيانات بنجاح!',
  'No products delivery available yet!': 'لا توجد تسليمات للمنتجات متاحة بعد!',
  Status: 'الحالة',
  Address: 'العنوان',
  Tracking: 'التتبع',
  "Creator's action": 'إجراء الصانع',
  Details: 'تفاصيل',
  'Not received yet': 'لم يتم الاستلام بعد',
  'Received damaged': 'تم الاستلام تالف',
  'Marked as received': 'تم وضع علامة تم الاستلام',
  'Mark as shipped': 'تحديد كتم شحنه',
  'Marked as shipped': 'تم تحديد كتم شحنه',
  'Provide shipping details': 'تقديم تفاصيل الشحن',
  'Shipping to': 'الشحن إلى',
  'Company name': 'اسم الشركة',
  'Using a tracking number': 'استخدام رقم تتبع',
  'Without a tracking number': 'بدون رقم تتبع',
  'Tracking number': 'رقم التتبع',
  Confirm: 'تأكيد',
  'Provide shipping details to': 'تقديم تفاصيل الشحن إلى',
  'All shipments': 'جميع الشحنات',
  'Elli Creators - Delivery': 'إيلي لصناع المحتوى - التسليم',
  'No deliveries available yet!': 'لا توجد تسليمات متاحة بعد!',
  'Authentic videos from our video content creators.':
    'مقاطع فيديو أصلية من صناع محتوى الفيديو الخاصين بنا.',
  "Add your product and how you'd like to showcase it. We'll guide you every step of the way.":
    'أضف منتجك وكيفية عرضه. سنرشدك في كل خطوة على الطريق.',
  'Get video': 'احصل على فيديو',
  'Need inspiration?': 'تحتاج إلى إلهام؟',
  'Pre-filled video templates to help you get started.':
    'قوالب فيديو مسبقة التعبئة لمساعدتك في البدء.',
  'Profile data saved successfully!': 'تم حفظ بيانات الملف الشخصي بنجاح!',
  'Edit profile': 'تعديل الملف الشخصي ',
  'Basic information': 'المعلومات الأساسية',
  'Contact information': 'معلومات الاتصال',
  'Mobile number': 'رقم الجوال',
  'Date of birth': 'تاريخ الميلاد',
  City: 'المدينة',
  Street: 'الشارع',
  State: 'الولاية',
  'My profile': 'ملفي الشخصي',
  'Elli Creators - Profile Details': 'إيلي لصناع المحتوى - تفاصيل الملف الشخصي',
  'Where your video ad will be displayed':
    'أين سيتم عرض إعلان الفيديو الخاص بك',
  Tiktok: 'Tiktok',
  Youtube: 'Youtube',
  Snapchat: 'Snapchat',
  Twitter: 'Twitter',
  Amazon: 'Amazon',
  'Elli Creators - Creating Task': 'إيلي لصناع المحتوى - إنشاء مهمة',
  TASK: 'مهمة',
  ORDER: 'طلب',
  BRIEF: 'موجز',
  'You must agree to our terms and conditions':
    'يجب أن توافق على الشروط والأحكام',
  'You have to add a product!': 'يجب عليك إضافة منتج!',
  'will be invited to this task': 'سيتم دعوته لهذه المهمة',
  'Re-ordering from the previous video: options are pre-selected but feel free to change them.':
    'إعادة الطلب من الفيديو السابق: الخيارات محددة مسبقًا ولكن يمكنك تغييرها بحرية.',
  'Revoke invitation': 'إلغاء الدعوة',
  'Save draft': 'حفظ المسودة',
  'Next step': 'الخطوة التالية',
  'Creator criteria': 'معايير صانع المحتوى',
  'Age range': 'نطاق العمر',
  'Looking for anything specific? Describe creator qualities (e.g. has a pet / can film in a car)':
    'تبحث عن شيء محدد؟ صف خصائص صانع المحتوى (مثل لديه حيوان أليف / يمكنه التصوير في سيارة)',
  'Invite creators I worked with in the past to apply for this task (if they fit the criteria)':
    'دعوة صانعي المحتوى الذين عملت معهم في الماضي للتقديم لهذه المهمة (إذا كانوا يستوفون المعايير)',
  Delivery: 'التسليم',
  'Doorstep delivery': 'توصيل حتى الباب',
  'The brand will take care of delivering the product directly to your doorstep, making it a stress-free experience for the creator.':
    'ستتولى العلامة التجارية توصيل المنتج مباشرة إلى بابك، مما يجعلها تجربة خالية من التوتر لصانع المحتوى.',
  'Schedule a visit': 'جدولة زيارة',
  "The creator will visit the brand's designated location for a photoshoot, allowing for a more controlled and authentic environment to showcase the product.":
    'سيزور صانع المحتوى موقع العلامة التجارية المخصص لجلسة تصوير، مما يتيح بيئة أكثر تحكمًا وأصالة لعرض المنتج.',
  Option: 'خيار',
  'Estimated video delivery': 'التسليم المتوقع للفيديو',
  'Show less': 'عرض أقل',
  'See delivery breakdown': 'عرض تفصيل التسليم',
  'Creator approval': 'موافقة صانع المحتوى',
  by: 'بواسطة',
  'Product shipped to creator': 'تم شحن المنتج إلى صانع المحتوى',
  'Note: video delivery may be delayed by product and creator logistics':
    'ملاحظة: قد يتأخر تسليم الفيديو بسبب لوجستيات المنتج وصانع المحتوى',
  'Creators receive products, produce and submit videos':
    'يتلقى صانعو المحتوى المنتجات، ينتجون ويقدمون الفيديوهات',
  'You review and approve videos': 'تقوم بمراجعة والموافقة على الفيديوهات',
  'If edits are needed, expect videos between':
    'إذا كانت التعديلات مطلوبة، توقع الفيديوهات بين',
  and: 'و',
  'I agree to': 'أوافق على',
  'terms and conditions': 'الشروط والأحكام',
  'Privacy Policy': 'سياسة الخصوصية',
  'I understand that there may be a delay caused by product logistics, my approvals or edit requests.':
    'أفهم أن هناك قد يكون هناك تأخير بسبب لوجستيات المنتج، موافقتي أو طلبات التعديل.',
  'You have to accept the terms & conditions': 'يجب عليك قبول الشروط والأحكام',
  'Number of videos': 'عدد الفيديوهات',
  'Digital marketers see best results with 4-6 creatives per ad campaign!':
    'المسوقون الرقميون يرون أفضل النتائج مع 4-6 إبداعات لكل حملة إعلانية!',
  "How many videos you'd like to order?":
    'كم عدد الفيديوهات التي ترغب في طلبها؟',
  'Order will be carried out by': 'سيتم تنفيذ الطلب بواسطة',
  'Your product access': 'وصول منتجك',
  'Make sure you have provided all the necessary access instructions when creating your product. The faster and more detailed you note the access instructions, the faster you can expect to receive the final videos.':
    'تأكد من أنك قد قدمت جميع تعليمات الوصول اللازمة عند إنشاء منتجك. كلما كانت تعليمات الوصول أسرع وأكثر تفصيلاً، كلما كان بإمكانك توقع الحصول على الفيديوهات النهائية بشكل أسرع.',
  'Please Note that': 'يرجى ملاحظة أن',
  'You will need to provide your product to each selected creator.':
    'ستحتاج إلى تقديم منتجك لكل صانع محتوى تم اختياره.',
  'Currently we work with creators located in Jordan only':
    'حاليًا نعمل مع صانعي المحتوى الموجودين في الأردن فقط',
  'Order summary': 'ملخص الطلب',
  'share video': 'مشاركة الفيديو',
  Total: 'المجموع',
  'Your product': 'منتجك',
  'More products': 'المزيد من المنتجات',
  'Create a new product': 'إنشاء منتج جديد',
  'All your products': 'جميع منتجاتك',
  Select: 'اختيار',
  'Product name pronunciation (optional)': 'نطق اسم المنتج (اختياري)',
  'Record how to pronounce your brand & product names so the creator can say them correctly when shooting a video.':
    'سجل كيفية نطق أسماء علامتك التجارية ومنتجاتك حتى يتمكن صانع المحتوى من قولها بشكل صحيح عند تصوير الفيديو.',
  Pause: 'إيقاف مؤقت',
  Start: 'ابدأ',
  Stop: 'توقف',
  'Ask creators about': 'اسأل صانعي المحتوى عن',
  size: 'الحجم',
  color: 'اللون',
  'product options': 'خيارات المنتج',
  Question: 'سؤال',
  'Add question': 'أضف سؤال',
  'Brand name': 'اسم العلامة التجارية',
  'Do not mention brand name': 'لا تذكر اسم العلامة التجارية',
  'Easy to use': 'سهل الاستخدام',
  'Saves time': 'يوفر الوقت',
  'Game changer': 'مغير اللعبة',
  'Changed my life/routine': 'غير حياتي / روتيني',
  'Free shipping': 'شحن مجاني',
  '50% off': 'خصم 50%',
  '30% off': 'خصم 30%',
  '20% off': 'خصم 20%',
  'Limited time offer': 'عرض لفترة محدودة',
  'Buy one, get ...': 'اشترِ واحدًا، واحصل على ...',
  "Don't miss out": 'لا تفوت',
  'Shop now': 'تسوق الآن',
  'Sign up': 'اشترك',
  'Join for free': 'انضم مجانًا',
  Subscribe: 'اشترك',
  'Book now': 'احجز الآن',
  'Creative mode': 'الوضع الإبداعي',
  'Simplified scenario - give creators freedom on how they showcase your product':
    'سيناريو مبسط - امنح صانعي المحتوى الحرية في كيفية عرض منتجك',
  'Scenes describe what can be seen in a video.':
    'المشاهد تصف ما يمكن رؤيته في الفيديو.',
  Scenes: 'مشاهد',
  'Add a scenario': 'أضف سيناريو',
  'Anything the creator should mention or highlight':
    'أي شيء يجب على صانع المحتوى ذكره أو تسليط الضوء عليه',
  Mentions: 'الذكر',
  'Brand & product': 'العلامة التجارية والمنتج',
  'Product feature': 'ميزة المنتج',
  Promo: 'عرض ترويجي',
  'Call-To-Action': 'دعوة للعمل',
  Mention: 'ذكر',
  'Add mention': 'أضف ذكر',
  'Anything that would help the creator deliver a perfect video':
    'أي شيء من شأنه أن يساعد صانع المحتوى على تقديم فيديو مثالي',
  'Please provide links to the content you like':
    'يرجى تقديم روابط للمحتوى الذي تحبه',
  Link: 'رابط',
  'Add link': 'أضف رابط',
  'I would like the creator to distribute the video to their audience. (+ $24.99)':
    'أود أن يقوم صانع المحتوى بتوزيع الفيديو على جمهوره. (+ 24.99 دولار)',
  'Video duration': 'مدة الفيديو',
  'Choose video format': 'اختر تنسيق الفيديو',
  Horizontal: 'أفقي',
  'Display ads in Youtube, Twitter and Amazon':
    'عرض الإعلانات في يوتيوب، تويتر وأمازون',
  Square: 'مربع',
  'Carousel and Instagram Shop ads': 'إعلانات الكاروسيل ومتجر إنستغرام',
  Vertical: 'عمودي',
  'Feed placement ads': 'إعلانات في الخلاصة',
  'Stories, Reels or Shorts': 'القصص، الريلز أو الشورتات',
  'Video type': 'نوع الفيديو',
  'Video ad': 'إعلان فيديو',
  'Flexible ad for any digital product or a service':
    'إعلان مرن لأي منتج رقمي أو خدمة',
  'Grow views with organic TikTok videos or turn them into TikTok Spark Ads.':
    'نمو المشاهدات مع فيديوهات تيك توك العضوية أو تحويلها إلى إعلانات تيك توك سبارك.',
  'Reveal benefits of your app with an over-the-shoulder device shot':
    'اكشف فوائد تطبيقك بلقطة من فوق الكتف للجهاز',
  Testimonial: 'شهادة',
  'Benefits of your app in a split screen video or from a point of view.':
    'فوائد تطبيقك في فيديو شاشة منقسمة أو من منظور معين.',
  'How-to': 'كيفية',
  'Explainer video for your product.': 'فيديو توضيحي لمنتجك.',
  Unboxing: 'فتح صندوق',
  'Display the unboxing experience of your product.':
    'عرض تجربة فتح صندوق منتجك.',
  Explainer: 'توضيحي',
  'Run-through your app to explain how it works and what it does.':
    'استعراض لتطبيقك لشرح كيفية عمله وما يفعله.',
  Preview: 'معاينة',
  'Intro video for the app store listing.':
    'فيديو مقدمة لقائمة متجر التطبيقات.',
  '360 showcase': 'عرض 360',
  'Show your product from every angle.': 'عرض منتجك من كل زاوية.',
  'Honest review': 'مراجعة صادقة',
  'A genuine opinion about your product from your target audience.':
    'رأي صادق حول منتجك من جمهورك المستهدف.',
  Invoice: 'فاتورة',
  'Amman, Jordan': 'عمان، الأردن',
  'Invoice for': 'فاتورة لـ',
  'Issued on': 'تاريخ الإصدار',
  'Due on': 'تاريخ الاستحقاق',
  'Balance due': 'الرصيد المستحق',
  Item: 'عنصر',
  Qty: 'الكمية',
  Discount: 'خصم',
  'Download PDF': 'تنزيل PDF',
  'Preview PDF': 'معاينة PDF',
  'Deal added successfully!': 'تمت إضافة الصفقة بنجاح!',
  'Submit secure payment': 'إرسال الدفع الآمن',
  "Here's your order summary": 'إليك ملخص طلبك',
  'Editing services': 'خدمات التحرير',
  'Order total': 'إجمالي الطلب',
  'Enter promo code': 'أدخل رمز العرض الترويجي',
  'Code applied successfully!': 'تم تطبيق الرمز بنجاح!',
  'Promo code': 'رمز العرض الترويجي',
  Apply: 'تطبيق',
  'Remove code': 'إزالة الرمز',
  'On average, an agency-produced UGC video costs around $500! We offer the same (some even say better!) quality videos for a fraction of that price.':
    'في المتوسط، يكلف فيديو UGC الذي تنتجه وكالة حوالي 500 دولار! نحن نقدم نفس الجودة (بل يقول البعض أفضل!) للفيديوهات بجزء من هذا السعر.',
  "You pay for your videos up front. This assures creators you're able to pay when they create content and you approve it.":
    'تدفع مقابل الفيديوهات مقدمًا. هذا يضمن لصانعي المحتوى أنك قادر على الدفع عندما ينشئون المحتوى وتوافق عليه.',
  'Payment Methods': 'طرق الدفع',
  'Select an existing payment method': 'اختر طريقة دفع موجودة',
  'Add a new payment method': 'أضف طريقة دفع جديدة',
  'Quality guaranteed': 'الجودة مضمونة',
  'edit requests for each video': 'طلبات التعديل لكل فيديو',
  "Refund if you won't find creators":
    'استرداد الأموال إذا لم تجد صانعي المحتوى',
  Brief: 'موجز',
  'General information': 'معلومات عامة',
  'Consideration (engagements / conversation / video views)':
    'الاعتبارات (التفاعلات / المحادثة / مشاهدات الفيديو)',
  'In progress': 'قيد التنفيذ',
  'In review': 'قيد المراجعة',
  Error: 'خطأ',
  'Product details': 'تفاصيل المنتج',
  'Video specifications': 'مواصفات الفيديو',
  Order: 'طلب',
  Duration: 'المدة',
  'Aspect ratio': 'نسبة العرض إلى الارتفاع',
  Female: 'أنثى',
  Male: 'ذكر',
  Any: 'أي',
  Giveaway: 'هدية',
  'Product giveaway': 'هدية المنتج',
  'Items will be given away': 'سيتم إعطاء العناصر',
  'Giveaway value': 'قيمة الهدية',
  'Video pricing': 'تسعير الفيديو',
  'Total budget': 'إجمالي الميزانية',
  'Application approved successfully!': 'تمت الموافقة على التطبيق بنجاح!',
  'No videos available yet!': 'لا توجد فيديوهات متاحة بعد!',
  'Card content': 'محتوى البطاقة',
  'Rate video': 'قيم الفيديو',
  'Re-order': 'إعادة الطلب',
  Download: 'تنزيل',
  Applications: 'تطبيقات',
  'Product delivery': 'توصيل المنتج',
  'Elli Creators - Tasks': 'ايلي لصناع المحتوى - المهام',
  'Task duplicated successfully!': 'تم تكرار المهمة بنجاح!',
  'Videos approved': 'الفيديوهات الموافق عليها',
  Shipping: 'الشحن',
  Canceled: 'ملغاة',
  Content: 'المحتوى',
  'Request edits': 'طلب تعديلات',
  STATUS: 'الحالة',
  'Visible to Client': 'مرئي للعميل',
  'Quality Check passed': 'اجتاز فحص الجودة',
  'Download video': 'تنزيل الفيديو',
  'Please note that requesting edits will delay the video delivery time by 2-5 business days.':
    'يرجى ملاحظة أن طلب التعديلات سيؤخر وقت تسليم الفيديو من 2 إلى 5 أيام عمل.',
  'Edits submitted successfully, and application has been approved!':
    'تم تقديم التعديلات بنجاح، وتمت الموافقة على التطبيق!',
  Change: 'تغيير',
  'Add request': 'أضف طلب',
  'My content': 'محتواي',
  'Elli Creators - Videos': 'ايلي لصناع المحتوى - الفيديوهات',
  'Total videos': 'إجمالي الفيديوهات',
  'Get more': 'احصل على المزيد',
  Help: 'مساعدة',
  improve: 'تحسين',
  'Rate this video': 'قيم هذا الفيديو',
  "I'll do this later": 'سأفعل ذلك لاحقًا',
  "Elli Creators - FAQ's": 'ايلي لصناع المحتوى - الأسئلة الشائعة',
  'How does Elli work?': 'كيف يعمل ايلي؟',
  'Who owns the content?': 'من يملك المحتوى؟',
  'Rest assured, the brands retain full ownership of the content they purchase.':
    'كن مطمئنًا، تحتفظ العلامات التجارية بالملكية الكاملة للمحتوى الذي تشتريه.',
  'What is an Honest review?': 'ما هي المراجعة الصادقة؟',
  'The ultimate way to gain authentic insights. Discover firsthand the pros and cons of your product, ensuring an honest and valuable perspective.':
    'الطريقة المثلى للحصول على رؤى حقيقية. اكتشف مزايا وعيوب منتجك بنفسك، مما يضمن وجهة نظر صادقة وقيّمة.',
  'What is an Unboxing?': 'ما هو فتح الصندوق؟',
  "Unboxing videos typically feature the product's packaging and the exciting unveiling process.":
    'عادةً ما تتضمن مقاطع الفيديو الخاصة بفتح الصندوق تغليف المنتج وعملية الكشف المثيرة.',
  'What happens if I did not find the creators I want to work with?':
    'ماذا يحدث إذا لم أجد صانعي المحتوى الذين أرغب في العمل معهم؟',
  'In such instances, our team can assist you by personally inviting additional creators to join your project. We value your input on what specific qualities or skills you desire in a creator. Furthermore, broadening your target audience often leads to a higher number of applications, providing you with a larger talent pool of creators to select from.':
    'في مثل هذه الحالات، يمكن لفريقنا مساعدتك من خلال دعوة صانعي محتوى إضافيين للانضمام إلى مشروعك. نحن نقدر مساهمتك في تحديد الصفات أو المهارات المحددة التي ترغب فيها في صانع المحتوى. علاوة على ذلك، فإن توسيع جمهورك المستهدف يؤدي غالبًا إلى زيادة عدد الطلبات، مما يوفر لك مجموعة أكبر من المواهب للاختيار منها.',
  'Sign in': 'تسجيل الدخول',
  'Fill in the fields below to sign into your account.':
    'املأ الحقول أدناه لتسجيل الدخول إلى حسابك.',
  'The email field is required': 'حقل البريد الإلكتروني مطلوب',
  'The password field is required': 'حقل كلمة المرور مطلوب',
  'Email address': 'عنوان البريد الإلكتروني',
  Password: 'كلمة المرور',
  'I accept the': 'أقبل',
  'Don’t have an account, yet?': 'لا تملك حسابًا بعد؟',
  'Sign up here': 'اشترك هنا',
  'Recover Password': 'استعادة كلمة المرور',
  'Enter the email used for registration to reset your password.':
    'أدخل البريد الإلكتروني المستخدم للتسجيل لإعادة تعيين كلمة المرور الخاصة بك.',
  'Send me a new password': 'أرسل لي كلمة مرور جديدة',
  'Want to try to sign in again?': 'هل تريد المحاولة لتسجيل الدخول مرة أخرى؟',
  'Click here': 'انقر هنا',
  'The password reset instructions have been sent to your email':
    'تم إرسال تعليمات إعادة تعيين كلمة المرور إلى بريدك الإلكتروني',
  'Check your email for further instructions':
    'تحقق من بريدك الإلكتروني لمزيد من التعليمات',
  'Continue to login': 'تابع لتسجيل الدخول',
  'Enter your brand information to get started':
    'أدخل معلومات علامتك التجارية للبدء',
  'Complete registration': 'أكمل التسجيل',
  'What business are you in?': 'في أي مجال تعمل؟',
  'Physical products': 'منتجات مادية',
  'Digital products': 'منتجات رقمية',
  'How did you find out about Elli?': 'كيف عرفت عن ايلي؟',
  Done: 'تم',
  'Fill in the fields below to sign up for an account.':
    'املأ الحقول أدناه للاشتراك في حساب.',
  'Create account': 'إنشاء حساب',
  'Already have an account?': 'هل لديك حساب بالفعل؟',
  'Sign in here': 'تسجيل الدخول هنا',
  'Create your account': 'أنشئ حسابك',
  'Who are our Content Creators?': 'من هم صانعو المحتوى لدينا؟',
  "Impactors, Not influence's": 'صنّاع التأثير، ليسوا المؤثرين',
  'Our content creators are creatives who are carefully selected because they are beautiful, talented, and have a nice way with words or actions, so that makes for really good content.':
    'صانعو المحتوى لدينا هم مبدعون يتم اختيارهم بعناية لأنهم جميلون، موهوبون، ولديهم أسلوب رائع في الكلمات أو الأفعال، مما يجعل المحتوى جيدًا للغاية.',
  'They are are real, every day, authentic people who have a passion for trying out products, reviewing them and spreading the good word about them. They do it eloquently and creatively.':
    'هم أشخاص حقيقيون، يوميون، أصليون، لديهم شغف بتجربة المنتجات، مراجعتها ونشر الكلمة الطيبة عنها. يقومون بذلك بشكل بليغ وإبداعي.',
  'This means we expect all the content to be 100% real, and most importantly, authentic.':
    'هذا يعني أننا نتوقع أن يكون كل المحتوى حقيقيًا بنسبة 100%، والأهم من ذلك، أصيل.',
  'Join our community of': 'انضم إلى مجتمعنا من',
  'video creators': 'صانعي الفيديو',
  'Create short videos for the brands you love – get free products and cash rewards.':
    'أنشئ مقاطع فيديو قصيرة للعلامات التجارية التي تحبها – احصل على منتجات مجانية ومكافآت نقدية.',
  'It’s super easy to join!': 'الانضمام سهل للغاية!',
  'Just Starting Your': 'فقط تبدأ',
  Career: 'مسيرتك المهنية',
  'or Are You': 'أم أنت',
  'a PRO?': 'محترف؟',
  'Free app. No fees': 'تطبيق مجاني. لا توجد رسوم',
  'Monetize your talent by joining our community':
    'استفد من موهبتك بالانضمام إلى مجتمعنا',
  'Frequently asked': 'الأسئلة المتكررة',
  questions: 'الأسئلة',
  'Who can become a creator on Elli?': 'من يمكنه أن يصبح صانع محتوى على ايلي؟',
  'You need to have skills to produce an awesome video!':
    'تحتاج إلى مهارات لإنتاج فيديو رائع!',
  'How do I get paid?': 'كيف أحصل على المال؟',
  'As soon as your video gets approved, the payout amount is added to your earnings. Earnings get transferred to your PayPal account twice a month.':
    'بمجرد الموافقة على الفيديو الخاص بك، تتم إضافة مبلغ الدفع إلى أرباحك. يتم تحويل الأرباح إلى حساب PayPal الخاص بك مرتين في الشهر.',
  'Can I choose a brand to work with?':
    'هل يمكنني اختيار علامة تجارية للعمل معها؟',
  'Yes. You’ll be able to choose between offers from different brands, read the requirements beforehand and decide if you’d like to apply.':
    'نعم. ستتمكن من الاختيار بين العروض من علامات تجارية مختلفة، وقراءة المتطلبات مسبقًا وتقرير ما إذا كنت ترغب في التقديم.',
  'How will I get the product?': 'كيف سأحصل على المنتج؟',
  'Products will get shipped to your address free of charge. Products are considered as a part of your reward, so you never have to pay for them.':
    'سيتم شحن المنتجات إلى عنوانك مجانًا. تعتبر المنتجات جزءًا من مكافأتك، لذا لن تضطر أبدًا لدفع ثمنها.',
  'Do I need to post videos on my social media?':
    'هل أحتاج إلى نشر مقاطع الفيديو على وسائل التواصل الاجتماعي الخاصة بي؟',
  'No, you don’t post videos on your social media, you only have to upload them on Elli app.':
    'لا، لا تنشر مقاطع الفيديو على وسائل التواصل الاجتماعي الخاصة بك، يجب عليك فقط تحميلها على تطبيق ايلي.',
  'Do I need special equipment to film a video?':
    'هل أحتاج إلى معدات خاصة لتصوير فيديو؟',
  'If you are applying to be a creator, it’s enough to have a smartphone. When it comes to creators PRO, we expect you to have equipment that will level-up your video, such as studio lights, backdrops, etc., to ensure higher video and audio quality.':
    'إذا كنت تتقدم لتصبح صانع محتوى، يكفي أن يكون لديك هاتف ذكي. عندما يتعلق الأمر بالمحترفين، نتوقع منك أن تمتلك معدات ترفع من مستوى الفيديو الخاص بك، مثل أضواء الاستوديو، والخلفيات، وما إلى ذلك، لضمان جودة أعلى للفيديو والصوت.',
  'Error fetching countries': 'Error fetching countries',
  All: 'الكل',
  KSA: 'المملكة العربية السعودية',
  'Not set': 'غير محدد',
  Apparel: 'الملابس',
  Automotive: 'سيارات',
  Games: 'الألعاب',
  Software: 'البرمجيات',
  'Consumer electronics': 'أإلكترونيات',
  'Consumer goods': 'سلع استهلاكية',
  Services: 'خدمات',
  Cosmetics: 'مستحضرات التجميل',
  Entertainment: 'ترفيه',
  'Food & Beverages': 'الطعام والمشروبات',
  'Health, Wellness, Fitness': 'صحة واللياقة البدنية',
  'Leisure, Travel, Tourism': 'السياحة والسفر',
  'Luxury Goods, Jewelry': 'السلع الفاخرة والمجوهرات',
  'Marketing, Advertising': 'التسويق والإعلان',
  Retail: 'المحلات التجارية',
  Sports: 'الرياضة',
  Home: 'المنزل',
  Pets: 'الحيوانات الأليفة',
  'Kids & Babies': 'الأطفال والرضع',
  Other: 'أخرى',
  '1 - 10 employees': '1 - 10 موظفين',
  '11 - 50 employees': '11 - 50 موظفاً',
  '51 - 200 employees': '51 - 200 موظف',
  '201 - 500 employees': '201 - 500 موظف',
  '500+ employees': 'أكثر من 500 موظف',
  Google: 'جوجل',
  LinkedIn: 'لينكد إن',
  FacebookAd: 'إعلان فيسبوك',
  Newsletter: 'النشرة الإخبارية',
  'Sales team': 'فريق المبيعات',
  'Another person': 'شخص آخر',
  'Fix aspect ratio to fit the Brief': 'ثابت نسبة العرض لتناسب الملخص',
  'Video needs to look more natural': 'يجب أن يبدو الفيديو أكثر طبيعية',
  'Video needs more energy': 'يحتاج الفيديو إلى المزيد من الطاقة',
  'Remove filters or effects': 'إزالة الفلاتر أو التأثيرات',
  'Remove voice over/music': 'إزالة التعليق الصوتي/الموسيقى',
  'Incorrect pronunciation': 'النطق غير صحيح',
  'Video/audio quality issues': 'مشاكل جودة الفيديو/الصوت',
  'Product not shown': 'المنتج غير معروض',
  'Inaccurate scene': 'المشهد غير دقيق',
  'Incorrect mention': 'الإشارة غير صحيحة',
  'Film it differently': 'تصويره بطريقة مختلفة',
  'Add a link to the product you prefer': 'أضف رابط المنتج الذي تفضله',
  'Add a screenshot of the product you prefer':
    'أضف لقطة شاشة للمنتج الذي تفضله',
  'What is your shoe size?': 'ما هو مقاس حذائك؟',
  'What is your pants size?': 'ما هو مقاس سروالك؟',
  'What is your tops size?': 'ما هو مقاس قميصك؟',
  'What is your height?': 'ما هو طولك؟',
  'What is your car model?': 'ما هو نوع سيارتك؟',
  'What is your pet breed?': 'ما هي سلالة حيوانك الأليف؟',
  'What is your phone model?': 'ما هو نوع هاتفك؟',
  'What is your computer model?': 'ما هو نوع حاسوبك؟',
  'What is the size you need?': 'ما هو الحجم الذي تحتاجه؟',
  'Which color do you prefer?': 'ما هو اللون الذي تفضله؟',
  'Which taste do you prefer?': 'ما هو الطعم الذي تفضله؟',
  'Which style do you prefer?': 'ما هو النمط الذي تفضله؟',
  'Which type do you prefer?': 'ما هو النوع الذي تفضله؟',
  'Other question (enter below)': 'سؤال آخر (أدخله أدناه)',
  Creator: 'منشئ المحتوى',
  Press: 'الصحافة',
  'Middle East': 'الشرق الأوسط',
  'North America': 'أمريكا الشمالية',
  'South America': 'أمريكا الجنوبية',
  Europe: 'أوروبا',
  Asia: 'آسيا',
  Africa: 'أفريقيا',
  Australia: 'أستراليا',
  Agency: 'وكالة',
  'Kids Babies': 'الأطفال والرضع',
  'Marketing Advertising': 'التسويق والإعلان',
  'Luxury Goods Jewelry': 'السلع الفاخرة والمجوهرات',
  'Leisure Travel Tourism': 'الترفيه والسفر والسياحة',
  'Health Wellness Fitness': 'الصحة والعافية واللياقة البدنية',
  'Food Beverages': 'الطعام والمشروبات',
  'Consumer Goods': 'السلع الاستهلاكية',
  'Consumer Electronics': 'الإلكترونيات الاستهلاكية',
  'Elli is a hassle-free platform that bridges the gap between brands and content creators, enabling seamless collaboration to source top-quality content for ads and social media. We take care of the entire process, starting from matching you with the perfect content creators who align with your brand values, all the way to ensuring the work delivered meets our high standards and deadlines. With Elli, you can sit back and relax while we handle the intricacies of content creation, ensuring you receive exceptional results without any headaches.':
    'إيلي هي منصة خالية من المتاعب تربط بين العلامات التجارية ومنشئي المحتوى، مما يمكن من التعاون السلس للحصول على محتوى عالي الجودة للإعلانات ووسائل التواصل الاجتماعي. نحن نعتني بكامل العملية، بدءًا من مطابقتك مع منشئي المحتوى المثاليين الذين يتماشون مع قيم علامتك التجارية، وصولاً إلى ضمان أن العمل المُقدم يلبي معاييرنا العالية والمواعيد النهائية. مع إيلي، يمكنك الجلوس والاسترخاء بينما نتولى التعقيدات المتعلقة بإنشاء المحتوى، لضمان حصولك على نتائج استثنائية دون أي صداع.',
  'Language Switcher': 'تغير اللغة',
  'Marked as Delivered': 'تم التسليم',
  at: 'في',
  'Phone number': 'رقم الهاتف',
  Yemen: 'اليمن',
  'Saudi arabia': 'السعودية',
  Syria: 'سوريا',
  Duplicate: 'تكرار',
  Price: 'السعر',
  Video: 'فيديو',
  'Campaign goal': 'هدف الحملة',
  'Record how to pronounce your brand & product names so the creator can say them correctly when shooting a video':
    'سجّل كيفية نطق أسماء علامتك التجارية ومنتجاتك حتى يتمكن المبدع من قولها بشكل صحيح عند تصوير الفيديو',
  'Product preferences form (optional)': 'نموذج تفضيلات المنتج (اختياري)',
  'Best choice if you plan to order a few videos':
    'أفضل خيار إذا كنت تخطط لطلب بعض الفيديوهات',
  'Best choice if you need fresh videos on a regular basis':
    'أفضل خيار إذا كنت تحتاج إلى فيديوهات جديدة بانتظام',
  'Best choice if you want to double down on video marketing':
    'أفضل خيار إذا كنت تريد التركيز بشكل أكبر على التسويق عبر الفيديو',
  'Best choice if video marketing is growing your business like crazy':
    'أفضل خيار إذا كان التسويق عبر الفيديو ينمي عملك بشكل كبير',
  'in value': 'في القيمة',
  'Order deal!': 'صفقة الطلب!',
  bonus: 'مكافأة',
  ' - Best choice': ' - احسن خيار',
  professional: 'احترافي',
  premium: 'ممتاز',
  starter: 'مبتدئ',
  ultimate: 'نهائي',
  Amman: 'عمان',
  'Amman ': 'عمان',
  amman: 'عمان',
  Dubai: 'دبي',
  uae: 'الامارات',
  jordan: 'الاردن',
  Riyadh: 'الرياض',
  saudiArabia: 'السعودية',
  Dammam: 'الدمام',
  Jeddah: 'جدة',
  'Jeddah ': 'جدة',
  Tripoli: 'طرابلس',
  lebanon: 'لبنان',
  Nabeul: 'نابل',
  tunisia: 'تونس',
  'AlBatinah Governorat': ' محافظة شمال الباطنة',
  oman: 'سلطنة عمان',
  'Al Madina Al Monawara': 'المدينة المنورة',
  'Dubai ': 'دبي',
  'alamerat ': 'العامرات',
  Alwakra: 'الوكرة',
  qatar: 'قطر',
  jeddah: 'جدة',
  Makkah: 'مكة',
  morocco: 'المغرب',
  Ouarzazate: 'ورزازات',
  'Ouarzazate ': 'ورزازات',
  'Riyadh ': 'الرياض',
  Rashidiya3: 'الرشيدية',
  Arad: 'عراد',
  bahrin: 'البحرين',
  Doha: 'الدوحة',
  Sharjah: 'الشارقة',
  'Medina i': 'المدينة',
  Baghdad: 'بغداد',
  iraq: 'العراق',
  Taif: 'الطائف',
  'Al-Nairiya': 'النعيرية',
  'Kuwait city': 'مدينة الكويت',
  kuwait: 'الكويت',
  'Amman Aqaba': 'عمان العقبة',
  Muharraq: 'المحرق',
  'Find real people for': 'ابحث عن أشخاص حقيقيين لـ',
  'every niche': 'كل مجال متخصص',
  'Show all creators': 'عرض جميع المبدعين',
  Niche: 'مجال متخصص',
  'Work with Trusted': 'اعمل مع صانعي المحتوى',
  'Content Makers': 'الموثوقين'
};

export default aeJSON;
