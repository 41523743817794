import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'src/store/ConfigsReducer';

export const Accordion = ({ children }) => {
  const lang = useSelector(selectLanguage);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className={`${lang === 'en' ? 'accordion' : 'accordion ar'}`}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          isActive: activeIndex === index,
          onClick: () => handleClick(index)
        });
      })}
    </div>
  );
};

export const AccordionCell = ({ children, isActive, onClick }) => {
  return (
    <div
      className={`accordion-cell ${isActive ? 'expanded' : 'collapsed'}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
