/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Elli Platform API
 * The Elli Platform API documentation
 * OpenAPI spec version: 1.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
export type FileControllerUploadBody = {
  file?: Blob;
};

export type CreatorAdminControllerPitchStatusStatus = typeof CreatorAdminControllerPitchStatusStatus[keyof typeof CreatorAdminControllerPitchStatusStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreatorAdminControllerPitchStatusStatus = {
  edits_required: 'edits_required',
  approved: 'approved',
} as const;

export type CreatorAdminControllerPitchStatusParams = { user_id: number; status: CreatorAdminControllerPitchStatusStatus; message?: string };

export type CreatorControllerFindAllParams = { category_id?: number; country?: string };

export type DeliveryControllerFindAllStatus = typeof DeliveryControllerFindAllStatus[keyof typeof DeliveryControllerFindAllStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryControllerFindAllStatus = {
  due_to_ship: 'due_to_ship',
  shipped: 'shipped',
  delivered: 'delivered',
  due_to_return: 'due_to_return',
  shipped_to_brand: 'shipped_to_brand',
  delivered_to_brand: 'delivered_to_brand',
} as const;

export type DeliveryControllerFindAllParams = { status?: DeliveryControllerFindAllStatus };

export type ApplicationBrandControllerFindAllVideoStatus = typeof ApplicationBrandControllerFindAllVideoStatus[keyof typeof ApplicationBrandControllerFindAllVideoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplicationBrandControllerFindAllVideoStatus = {
  pending: 'pending',
  submitted: 'submitted',
  edits_required: 'edits_required',
  approved: 'approved',
} as const;

export type ApplicationBrandControllerFindAllStatus = typeof ApplicationBrandControllerFindAllStatus[keyof typeof ApplicationBrandControllerFindAllStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplicationBrandControllerFindAllStatus = {
  invited: 'invited',
  applied: 'applied',
  approved: 'approved',
  arriving: 'arriving',
  shoot_video: 'shoot_video',
  rejected: 'rejected',
  revoked: 'revoked',
} as const;

export type ApplicationBrandControllerFindAllParams = { status?: ApplicationBrandControllerFindAllStatus; video_status?: ApplicationBrandControllerFindAllVideoStatus };

export type TaskAdminControllerFindAllToApproveParams = { task: string };

export type AuthControllerApproveParams = { user: string };

export type Region = typeof Region[keyof typeof Region];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Region = {
  middle_east: 'middle_east',
  north_america: 'north_america',
  south_america: 'south_america',
  europe: 'europe',
  asia: 'asia',
  africa: 'africa',
  australia: 'australia',
  other: 'other',
} as const;

export type HowToHelp = typeof HowToHelp[keyof typeof HowToHelp];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HowToHelp = {
  brand: 'brand',
  creator: 'creator',
  press: 'press',
  other: 'other',
} as const;

export interface CreateContactDto {
  how_to_help: HowToHelp;
  region: Region;
  first_name: string;
  last_name: string;
  email: string;
  company?: string;
  message: string;
}

export type NewsletterType = typeof NewsletterType[keyof typeof NewsletterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsletterType = {
  early_access: 'early_access',
  newsletter: 'newsletter',
} as const;

export interface CreateNewsletterDto {
  type?: NewsletterType;
  email: string;
}

export interface CreateRateDto {
  performance: number;
  video_quality: number;
  audio_quality: number;
  turnaround: number;
  comment?: string;
}

export interface UpdateProductDto {
  type?: ProductType;
  name?: string;
  description?: string;
  url?: string;
  access_link?: string;
  access_instructions?: string;
  price?: number;
  offer?: string;
  photo_id?: number;
  product_preferences?: string[];
  pronunciation_id?: number;
}

export interface CreateProductDto {
  type: ProductType;
  name: string;
  description?: string;
  url?: string;
  access_link?: string;
  access_instructions?: string;
  price?: number;
  offer?: string;
  photo_id?: number;
  product_preferences?: string[];
  pronunciation_id?: number;
}

export interface SendNotificationDto {
  title: string;
  body: string;
  token: string;
}

export interface NotificationEntity {
  id: number;
  title: string;
  body: string;
  task_id: number | null;
  application_id: number | null;
  is_read: boolean;
  read_since: string;
  created_at: string;
  created_since: string;
  brand_id: number | null;
  brand: BrandEntity;
  creator_id: number | null;
  creator: CreatorEntity;
}

export interface UpdateDeliveryConditionDto {
  status: DeliveryCondition;
}

export interface UpdateDeliveryStatusDto {
  status: DeliveryStatus;
}

export interface UpdateDeliveryTrackingNumberDto {
  tracking_number: string;
  delivery_company?: string;
}

export interface CreatorEarningsEntity {
  applications: ApplicationEntity[];
  pending: number;
  approved: number;
  paid: number;
}

export interface ApplicationsOverviewEntity {
  invitations: ApplicationEntity[];
  edits_requested: ApplicationEntity[];
  approved: ApplicationEntity[];
  arriving: ApplicationEntity[];
  shoot_video: ApplicationEntity[];
}

export interface SubmitVideoDto {
  video_id?: number;
}

export interface CreateApplicationDto {
  task_id: number;
  answers?: string[];
  selected_visit_date?: string;
}

export interface ApplicationsEditsDto {
  edits: string[];
  comments: string;
}

export interface BrandDashboardEntity {
  creators: number;
  tasks: number;
  videos: number;
  spending: number;
  pending_applications: ApplicationEntity[];
  due_to_ship_delivery: ApplicationEntity[];
  pending_videos: ApplicationEntity[];
}

export interface PaymentMethodCard {
  brand: string;
  country: string | null;
  description?: string | null;
  exp_month: number;
  exp_year: number;
  issuer?: string | null;
  last4: string;
}

export interface PaymentMethodEntity {
  id: string;
  card: PaymentMethodCard;
  created: number;
  livemode: boolean;
  is_default: boolean;
}

export interface UpdateBalancePaymentDto {
  status: PaymentStatus;
  payment_intent_id: string;
}

export interface UpdatePaymentDto {
  status: PaymentStatus;
  task_id: number;
}

export interface MakeBalancePaymentDto {
  payment_method_id: string;
  payment_intent_id: string;
}

export interface MakePaymentDto {
  payment_source?: PaymentSource;
  payment_method_id?: string;
  task_id: number;
}

export type DealType = typeof DealType[keyof typeof DealType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DealType = {
  starter: 'starter',
  professional: 'professional',
  premium: 'premium',
  ultimate: 'ultimate',
} as const;

export interface CreateBalanceIntentDto {
  deal_type: DealType;
}

export type PaymentIntentEntityTransferData = { [key: string]: any };

export type PaymentIntentEntityStatus = { [key: string]: any };

export type PaymentIntentEntitySource = { [key: string]: any };

export type PaymentIntentEntityShipping = { [key: string]: any };

export type PaymentIntentEntitySetupFutureUsage = { [key: string]: any };

export type PaymentIntentEntityReview = { [key: string]: any };

export type PaymentIntentEntityProcessing = { [key: string]: any };

export type PaymentIntentEntityPaymentMethodOptions = { [key: string]: any };

export type PaymentIntentEntityPaymentMethod = { [key: string]: any };

export type PaymentIntentEntityOnBehalfOf = { [key: string]: any };

export type PaymentIntentEntityNextAction = { [key: string]: any };

export type PaymentIntentEntityMetadata = { [key: string]: any };

export type PaymentIntentEntityLatestCharge = { [key: string]: any };

export type PaymentIntentEntityLastPaymentError = { [key: string]: any };

export type PaymentIntentEntityInvoice = { [key: string]: any };

export type PaymentIntentEntityCustomer = { [key: string]: any };

export type PaymentIntentEntityConfirmationMethod = { [key: string]: any };

export type PaymentIntentEntityCaptureMethod = { [key: string]: any };

export type PaymentIntentEntityCancellationReason = { [key: string]: any };

export type PaymentIntentEntityAutomaticPaymentMethods = { [key: string]: any };

export type PaymentIntentEntityApplication = { [key: string]: any };

export type PaymentIntentEntityAmountDetails = { [key: string]: any };

export interface PaymentIntentEntity {
  id: string;
  object: string;
  amount: number;
  amount_capturable: number;
  amount_details?: PaymentIntentEntityAmountDetails;
  amount_received: number;
  application: PaymentIntentEntityApplication;
  application_fee_amount: number | null;
  automatic_payment_methods: PaymentIntentEntityAutomaticPaymentMethods;
  canceled_at: number | null;
  cancellation_reason: PaymentIntentEntityCancellationReason;
  capture_method: PaymentIntentEntityCaptureMethod;
  client_secret: string | null;
  confirmation_method: PaymentIntentEntityConfirmationMethod;
  created: number;
  currency: string;
  customer: PaymentIntentEntityCustomer;
  description: string | null;
  invoice: PaymentIntentEntityInvoice;
  last_payment_error: PaymentIntentEntityLastPaymentError;
  latest_charge?: PaymentIntentEntityLatestCharge;
  livemode: boolean;
  metadata: PaymentIntentEntityMetadata;
  next_action: PaymentIntentEntityNextAction;
  on_behalf_of: PaymentIntentEntityOnBehalfOf;
  payment_method: PaymentIntentEntityPaymentMethod;
  payment_method_options: PaymentIntentEntityPaymentMethodOptions;
  payment_method_types: string[];
  processing: PaymentIntentEntityProcessing;
  receipt_email: string | null;
  review: PaymentIntentEntityReview;
  setup_future_usage: PaymentIntentEntitySetupFutureUsage;
  shipping: PaymentIntentEntityShipping;
  source: PaymentIntentEntitySource;
  statement_descriptor: string | null;
  statement_descriptor_suffix: string | null;
  status: PaymentIntentEntityStatus;
  transfer_data: PaymentIntentEntityTransferData;
  transfer_group: string | null;
}

export interface CreatePaymentIntentDto {
  task_id: number;
}

export type PromoType = typeof PromoType[keyof typeof PromoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PromoType = {
  percentage: 'percentage',
  fixed: 'fixed',
} as const;

export interface PromoEntity {
  type: PromoType;
  code: string;
  amount: number;
}

export interface PromoDetails {
  promo: PromoEntity;
  original_amount: number;
  promo_discount: number;
  total_amount: number;
}

export interface ApplyPromoDto {
  promo_code: string;
  task_id: number;
}

export interface BrandTasksEntity {
  brand: UserEntity;
  tasks: TaskEntity[];
}

export interface TasksCountsEntity {
  category: number;
  brand: number;
  all: number;
}

export interface TaskSuggestedEntity {
  suggested: TaskEntity[];
  new_tasks: TaskEntity[];
  top_categories: CategoryEntity[];
}

export interface UpdateTaskDto {
  type?: TaskType;
  duration?: VideoDuration;
  creative_mode?: boolean;
  display?: VideoDisplay;
  format?: VideoFormat;
  preferred_gender?: CreatorSelectedGender;
  preferred_age?: CreatorAge;
  /** Array of creators user ids */
  creator_user_ids?: number[];
  mentions?: string[];
  scenarios?: string[];
  additional_details?: string | null;
  links?: string[];
  share_video?: boolean;
  creator_preference?: string | null;
  previous_creator?: boolean;
  quantity?: number;
  schedule_visit?: string[];
  is_giveaway?: boolean;
  is_delivery?: boolean;
  is_draft?: boolean;
  product_id?: number;
}

export interface CreateTaskDto {
  type: TaskType;
  duration: VideoDuration;
  creative_mode: boolean;
  display: VideoDisplay;
  format: VideoFormat;
  preferred_gender?: CreatorSelectedGender;
  preferred_age?: CreatorAge;
  /** Array of creators user ids */
  creator_user_ids?: number[];
  mentions?: string[];
  scenarios?: string[];
  additional_details: string | null;
  links: string[];
  share_video: boolean;
  creator_preference: string | null;
  previous_creator: boolean;
  quantity: number;
  schedule_visit: string[];
  is_giveaway: boolean;
  is_delivery: boolean;
  is_draft: boolean;
  product_id: number;
}

export interface GetDiscountDto {
  name: string;
  email: string;
}

export type PublicUserEntityAddress = PublicAddressEntity | null;

export type PublicUserEntityCreator = CreatorEntity | null;

export type PublicUserEntityBrand = BrandEntity | null;

export interface PublicAddressEntity {
  country: string | null;
  city: string | null;
}

export type DealPriceEntityType = { [key: string]: any };

export interface DealPriceEntity {
  type: DealPriceEntityType;
  price: number;
  balance: number;
}

export interface PricesEntity {
  fifteen_seconds_price: number;
  thirty_seconds_price: number;
  sixty_seconds_price: number;
  share_video_price: number;
}

export interface UpdateCategoryDto {
  category: number[];
}

export interface UpdateAddressDto {
  country?: string;
  state?: string;
  city?: string;
  address?: string;
  street?: string;
  postal_code?: string;
}

export type RequestPaymentType = typeof RequestPaymentType[keyof typeof RequestPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestPaymentType = {
  cliq: 'cliq',
  wallet: 'wallet',
  bank: 'bank',
} as const;

export interface UpdateCreatorDto {
  payment_type?: RequestPaymentType;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  linkedin?: string;
  youtube?: string;
  tiktok?: string;
  snapchat?: string;
  pinterest?: string;
  amazon?: string;
  payment_info?: string;
  profile_picture_id?: number;
  pitch_video_id?: number;
  categories?: number[];
}

export type HearAboutUs = typeof HearAboutUs[keyof typeof HearAboutUs];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HearAboutUs = {
  google: 'google',
  linkedIn: 'linkedIn',
  facebookAd: 'facebookAd',
  newsletter: 'newsletter',
  salesTeam: 'salesTeam',
  anotherPerson: 'anotherPerson',
  other: 'other',
} as const;

export interface UpdateBrandDto {
  /** Hex color code example: #000000 */
  color?: string;
  type?: BrandType;
  reference?: HearAboutUs;
  name?: string;
  description?: string;
  website?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  linkedin?: string;
  youtube?: string;
  tiktok?: string;
  snapchat?: string;
  pinterest?: string;
  amazon?: string;
  categories?: number[];
  logo_id?: number;
  cover_id?: number;
}

export interface UpdateUserDto {
  phone?: string;
  gender?: UserGender;
  first_name?: string;
  last_name?: string;
  email?: string;
  dob?: string;
  google_id?: string;
  apple_id?: string;
  brand?: UpdateBrandDto;
  creator?: UpdateCreatorDto;
  address?: UpdateAddressDto;
}

export interface BiometricsDto {
  bio_key: string | null;
  device_uuid: string;
}

export interface ResetPasswordDto {
  token: string;
  password: string;
}

export interface ForgotPasswordDto {
  email: string;
}

export interface CreatePasswordDto {
  new_password: string;
}

export interface ChangePasswordDto {
  old_password: string;
  new_password: string;
}

export interface AppleAuthDto {
  auth_token: string;
  first_name?: string;
  last_name?: string;
}

export interface GoogleAuthDto {
  auth_token: string;
}

export interface BiometricsLoginDto {
  bio_signature: string;
  device_uuid: string;
}

export interface LoginDto {
  email: string;
  password: string;
}

export type BrandEntityCover = FileEntity | null;

export type BrandEntityLogo = FileEntity | null;

export interface BrandBalanceEntity {
  amount: number;
  expiry_date: string | null;
}

export interface BrandEntityCounts {
  task: number;
  product: number;
}

export type UserEntityReferredBy = ReferralEntity | null;

export type UserEntityAddress = AddressEntity | null;

export type UserEntityCreator = CreatorEntity | null;

export type UserEntityBrand = BrandEntity | null;

export interface UserEntity {
  type: UserType;
  gender: UserGender;
  id: number;
  full_name: string;
  first_name: string | null;
  last_name: string | null;
  email: string;
  phone: string | null;
  google_id: string | null;
  apple_id: string | null;
  dob: string | null;
  password_less: boolean;
  is_verified: boolean;
  is_blocked: boolean;
  is_active: boolean;
  user_category: UserCategoryEntity[];
  brand: UserEntityBrand;
  creator: UserEntityCreator;
  address: UserEntityAddress;
  access_token: string | null;
  referred_by: UserEntityReferredBy;
  referral_code: string | null;
}

export interface BrandEntity {
  type: BrandType;
  stripe_id: string | null;
  name: string | null;
  description: string | null;
  website: string | null;
  color: string | null;
  facebook: string | null;
  instagram: string | null;
  twitter: string | null;
  linkedin: string | null;
  youtube: string | null;
  tiktok: string | null;
  snapchat: string | null;
  pinterest: string | null;
  amazon: string | null;
  is_first_time: boolean;
  logo_id: number | null;
  logo: BrandEntityLogo;
  cover_id: number | null;
  cover: BrandEntityCover;
  user: UserEntity;
  _count: BrandEntityCounts;
  task: TaskEntity[];
  balance: BrandBalanceEntity;
}

export interface ReferralEntity { [key: string]: any }

export interface AddressEntity {
  id: number;
  country: string | null;
  state: string | null;
  city: string | null;
  address: string | null;
  street: string | null;
  postal_code: string | null;
}

export type CreatorEntityPitchVideo = FileEntity | null;

export type CreatorEntityProfilePicture = FileEntity | null;

export interface CreatorEntity {
  payment_type: PaymentType;
  pitch_video_status: PitchVideoStatus;
  rating: number | null;
  facebook: string | null;
  instagram: string | null;
  twitter: string | null;
  linkedin: string | null;
  youtube: string | null;
  tiktok: string | null;
  snapchat: string | null;
  pinterest: string | null;
  amazon: string | null;
  payment_info: string | null;
  profile_picture_id: number | null;
  profile_picture: CreatorEntityProfilePicture;
  pitch_video_id: number | null;
  pitch_video: CreatorEntityPitchVideo;
  pitch_video_edits: string | null;
  user: UserEntity;
  number_of_videos: number | null;
  latest_videos: FileEntity[];
  application: ApplicationEntity[];
}

export type ApplicationEntityVideo = FileEntity | null;

export interface RateEntity {
  id: number;
  performance: number;
  video_quality: number;
  audio_quality: number;
  turnaround: number;
  overall: number;
  comment: string | null;
  created_at: string;
  application_id: number;
  application: ApplicationEntity;
}

export interface ApplicationEntity {
  status: ApplicationStatus;
  video_status: VideoStatus;
  earnings_status: EarningsStatus;
  id: number;
  answers: string[];
  requested_edits: EditsEntity;
  invited: boolean;
  earnings: number;
  selected_visit_date: string | null;
  video_submitted_at: string | null;
  status_updated_at: string | null;
  video_status_updated_at: string | null;
  revoked_at: string;
  created_at: string;
  creator_id: number;
  creator: CreatorEntity;
  task_id: number;
  task: TaskEntity;
  video_id: number | null;
  video: ApplicationEntityVideo;
  delivery: DeliveryEntity;
  rate: RateEntity;
}

export type DeliveryCondition = typeof DeliveryCondition[keyof typeof DeliveryCondition];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryCondition = {
  not_received: 'not_received',
  received: 'received',
  damaged: 'damaged',
} as const;

export type DeliveryStatus = typeof DeliveryStatus[keyof typeof DeliveryStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryStatus = {
  due_to_ship: 'due_to_ship',
  shipped: 'shipped',
  delivered: 'delivered',
  due_to_return: 'due_to_return',
  shipped_to_brand: 'shipped_to_brand',
  delivered_to_brand: 'delivered_to_brand',
} as const;

export interface DeliveryEntity {
  status: DeliveryStatus;
  delivery_condition: DeliveryCondition;
  id: number;
  delivery_company: string | null;
  tracking_number: string | null;
  shipped_at: string | null;
  returned_at: string | null;
  delivered_at: string | null;
  return_delivered_at: string | null;
  created_at: string;
  application_id: number;
  application: ApplicationEntity;
}

export type PaymentSource = typeof PaymentSource[keyof typeof PaymentSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSource = {
  balance: 'balance',
  credit_card: 'credit_card',
} as const;

export type PaymentStatus = typeof PaymentStatus[keyof typeof PaymentStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentStatus = {
  pending: 'pending',
  paid: 'paid',
  failed: 'failed',
  cancelled: 'cancelled',
  refunded: 'refunded',
} as const;

export interface PaymentEntity {
  status: PaymentStatus;
  payment_type: PaymentType;
  payment_source: PaymentSource;
  invoice_number: number | null;
  amount: number;
  discount: number;
  original_amount: number;
  creator_amount: number;
  created_at: string;
  task: TaskEntity;
}

export type TaskEntityPayment = PaymentEntity | null;

export type ProductEntityPronunciation = FileEntity | null;

export type ProductEntityPhoto = FileEntity | null;

export type ProductType = typeof ProductType[keyof typeof ProductType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductType = {
  physical: 'physical',
  digital: 'digital',
} as const;

export interface ProductEntity {
  type: ProductType;
  id: number;
  name: string;
  description: string | null;
  url: string | null;
  access_link: string | null;
  access_instructions: string | null;
  price: number;
  offer: string;
  product_preferences: string[];
  photo_id: number | null;
  photo: ProductEntityPhoto;
  pronunciation_id: number | null;
  pronunciation: ProductEntityPronunciation;
}

export type CreatorAge = typeof CreatorAge[keyof typeof CreatorAge];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreatorAge = {
  eighteen_to_twenty_four: 'eighteen_to_twenty_four',
  twenty_five_to_twenty_nine: 'twenty_five_to_twenty_nine',
  thirty_to_thirty_nine: 'thirty_to_thirty_nine',
  forty_to_forty_nine: 'forty_to_forty_nine',
  fifty_plus: 'fifty_plus',
} as const;

export type CreatorSelectedGender = typeof CreatorSelectedGender[keyof typeof CreatorSelectedGender];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreatorSelectedGender = {
  male: 'male',
  female: 'female',
  any: 'any',
} as const;

export type VideoFormat = typeof VideoFormat[keyof typeof VideoFormat];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoFormat = {
  horizontal_16x9: 'horizontal_16x9',
  square_1x1: 'square_1x1',
  vertical_4x5: 'vertical_4x5',
  vertical_9x16: 'vertical_9x16',
} as const;

export type VideoDisplay = typeof VideoDisplay[keyof typeof VideoDisplay];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoDisplay = {
  facebook: 'facebook',
  instagram: 'instagram',
  tiktok: 'tiktok',
  youtube: 'youtube',
  snapchat: 'snapchat',
  twitter: 'twitter',
  amazon: 'amazon',
} as const;

export type VideoDuration = typeof VideoDuration[keyof typeof VideoDuration];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoDuration = {
  fifteen_seconds: 'fifteen_seconds',
  thirty_seconds: 'thirty_seconds',
  sixty_seconds: 'sixty_seconds',
  ninety_seconds: 'ninety_seconds',
} as const;

export type TaskType = typeof TaskType[keyof typeof TaskType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskType = {
  video_ad: 'video_ad',
  tiktok: 'tiktok',
  promo: 'promo',
  testimonial: 'testimonial',
  how_to: 'how_to',
  unboxing: 'unboxing',
  explainer: 'explainer',
  preview: 'preview',
  three_sixty_showcase: 'three_sixty_showcase',
  honest_review: 'honest_review',
} as const;

export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskStatus = {
  draft: 'draft',
  in_review: 'in_review',
  in_progress: 'in_progress',
  completed: 'completed',
  cancelled: 'cancelled',
} as const;

export interface TaskEntity {
  status: TaskStatus;
  type: TaskType;
  duration: VideoDuration;
  display: VideoDisplay;
  format: VideoFormat;
  preferred_gender: CreatorSelectedGender;
  preferred_age: CreatorAge;
  id: number;
  creative_mode: boolean;
  mentions: string[];
  scenarios: string[];
  additional_details: string | null;
  links: string[];
  share_video: boolean;
  creator_preference: string | null;
  previous_creator: boolean;
  creator_user_ids: number[];
  creator_users: UserEntity[];
  quantity: number;
  schedule_visit: string[];
  is_giveaway: boolean;
  is_delivery: boolean;
  is_draft: boolean;
  is_deleted: boolean;
  deleted_at: string | null;
  created_at: string;
  brand_id: number;
  brand: BrandEntity;
  product_id: number;
  product: ProductEntity;
  payment_id: number | null;
  payment: TaskEntityPayment;
  applications: ApplicationEntity[];
}

export type EditsRequester = typeof EditsRequester[keyof typeof EditsRequester];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EditsRequester = {
  brand: 'brand',
  elli: 'elli',
} as const;

export interface EditsEntity {
  requested_by: EditsRequester;
  id: number;
  edits: string[];
  comments: string | null;
  created_at: string;
  application_id: number;
}

export type EarningsStatus = typeof EarningsStatus[keyof typeof EarningsStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EarningsStatus = {
  pending: 'pending',
  approved: 'approved',
  paid: 'paid',
} as const;

export type VideoStatus = typeof VideoStatus[keyof typeof VideoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoStatus = {
  pending: 'pending',
  submitted: 'submitted',
  edits_required: 'edits_required',
  approved: 'approved',
} as const;

export type ApplicationStatus = typeof ApplicationStatus[keyof typeof ApplicationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplicationStatus = {
  invited: 'invited',
  applied: 'applied',
  approved: 'approved',
  arriving: 'arriving',
  shoot_video: 'shoot_video',
  rejected: 'rejected',
  revoked: 'revoked',
} as const;

export type PitchVideoStatus = typeof PitchVideoStatus[keyof typeof PitchVideoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PitchVideoStatus = {
  pending: 'pending',
  submitted: 'submitted',
  edits_required: 'edits_required',
  approved: 'approved',
} as const;

export type PaymentType = typeof PaymentType[keyof typeof PaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentType = {
  cliq: 'cliq',
  wallet: 'wallet',
  bank: 'bank',
} as const;

export interface FileEntity {
  id: number;
  key: string;
  type: string;
}

export type BrandType = typeof BrandType[keyof typeof BrandType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BrandType = {
  agency: 'agency',
  physical: 'physical',
  digital: 'digital',
} as const;

export interface UserCategoryEntity {
  category: CategoryEntity;
}

export type UserGender = typeof UserGender[keyof typeof UserGender];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserGender = {
  male: 'male',
  female: 'female',
} as const;

export type UserType = typeof UserType[keyof typeof UserType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserType = {
  brand: 'brand',
  creator: 'creator',
  admin: 'admin',
} as const;

export interface PublicUserEntity {
  type: UserType;
  gender: UserGender;
  id: number;
  first_name: string | null;
  last_name: string | null;
  is_verified: boolean;
  is_blocked: boolean;
  is_active: boolean;
  user_category: UserCategoryEntity[];
  brand: PublicUserEntityBrand;
  creator: PublicUserEntityCreator;
  access_token: string | null;
  address: PublicUserEntityAddress;
}

export interface CreateUserDto {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  referral_code?: string;
}

export interface CategoryEntityCounts {
  task: number;
}

export interface CategoryEntity {
  id: number;
  name: string;
  _count: CategoryEntityCounts;
}

export type Language = typeof Language[keyof typeof Language];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Language = {
  ar: 'ar',
  en: 'en',
} as const;

export interface UpdateDeviceDto {
  language?: Language;
  fcm_token?: string;
  notifications_enabled?: boolean;
}

export type Os = typeof Os[keyof typeof Os];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Os = {
  ios: 'ios',
  android: 'android',
  browser: 'browser',
} as const;

export interface DeviceEntity {
  os: Os;
  language: Language;
  id: number;
  user_id: number | null;
  notifications_enabled: boolean;
  fcm_token: string | null;
  bio_key: string | null;
  device_uuid: string | null;
}

export interface CreateDeviceDto {
  os: Os;
  language: Language;
  fcm_token?: string;
}





  /**
 * Brand/Creator - Create a new device
 * @summary Create a new device - deviceControllerCreate
 */
export const deviceControllerCreate = <TData = AxiosResponse<DeviceEntity>>(
    createDeviceDto: CreateDeviceDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/device`,
      createDeviceDto,options
    );
  }

/**
 * Brand/Creator - Update device
 * @summary Update device - deviceControllerUpdate
 */
export const deviceControllerUpdate = <TData = AxiosResponse<DeviceEntity>>(
    updateDeviceDto: UpdateDeviceDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/device`,
      updateDeviceDto,options
    );
  }

/**
 * Public - Get all categories
 * @summary Get all Categories - categoryControllerFindAll
 */
export const categoryControllerFindAll = <TData = AxiosResponse<CategoryEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/category`,options
    );
  }

/**
 * Admin - Approve new User
 * @summary Approve new User - authControllerApprove
 */
export const authControllerApprove = <TData = AxiosResponse<boolean>>(
    params: AuthControllerApproveParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/auth/approve`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Brand/Creator Sign Up with email and password
 * @summary Sign Up - authControllerSignup
 */
export const authControllerSignup = <TData = AxiosResponse<UserEntity>>(
    createUserDto: CreateUserDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/auth/signup`,
      createUserDto,options
    );
  }

/**
 * Brand/Creator Login with email and password
 * @summary Login - authControllerLogin
 */
export const authControllerLogin = <TData = AxiosResponse<UserEntity>>(
    loginDto: LoginDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/auth/login`,
      loginDto,options
    );
  }

/**
 * Brand/Creator Login with email and password
 * @summary Login - authControllerBiometricsLogin
 */
export const authControllerBiometricsLogin = <TData = AxiosResponse<UserEntity>>(
    biometricsLoginDto: BiometricsLoginDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/auth/biometrics-login`,
      biometricsLoginDto,options
    );
  }

/**
 * Brand/Creator Signup/Login with Google
 * @summary Signup/Login with Google - authControllerGoogle
 */
export const authControllerGoogle = <TData = AxiosResponse<UserEntity>>(
    googleAuthDto: GoogleAuthDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/auth/google`,
      googleAuthDto,options
    );
  }

/**
 * Brand/Creator Signup/Login with Apple
 * @summary Signup/Login with Apple - authControllerApple
 */
export const authControllerApple = <TData = AxiosResponse<UserEntity>>(
    appleAuthDto: AppleAuthDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/auth/apple`,
      appleAuthDto,options
    );
  }

/**
 * Brand/Creator Connect with Google
 * @summary Connect with Google - authControllerGoogleConnect
 */
export const authControllerGoogleConnect = <TData = AxiosResponse<UserEntity>>(
    googleAuthDto: GoogleAuthDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/auth/google-connect`,
      googleAuthDto,options
    );
  }

/**
 * Brand/Creator Disconnect Google
 * @summary Disconnect Google - authControllerGoogleDisconnect
 */
export const authControllerGoogleDisconnect = <TData = AxiosResponse<UserEntity>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/auth/google-disconnect`,undefined,options
    );
  }

/**
 * Brand/Creator Connect with Apple
 * @summary Connect with Apple - authControllerAppleConnect
 */
export const authControllerAppleConnect = <TData = AxiosResponse<UserEntity>>(
    appleAuthDto: AppleAuthDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/auth/apple-connect`,
      appleAuthDto,options
    );
  }

/**
 * Brand/Creator Disconnect Apple
 * @summary Disconnect Apple - authControllerAppleDisconnect
 */
export const authControllerAppleDisconnect = <TData = AxiosResponse<UserEntity>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/auth/apple-disconnect`,undefined,options
    );
  }

/**
 * Brand/Creator Change Password
 * @summary Change Password - authControllerChangePassword
 */
export const authControllerChangePassword = <TData = AxiosResponse<boolean>>(
    changePasswordDto: ChangePasswordDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/auth/change-password`,
      changePasswordDto,options
    );
  }

/**
 * Brand/Creator Create Password
 * @summary Create Password - authControllerCreatePassword
 */
export const authControllerCreatePassword = <TData = AxiosResponse<UserEntity>>(
    createPasswordDto: CreatePasswordDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/auth/create-password`,
      createPasswordDto,options
    );
  }

/**
 * Brand/Creator Forgot Password
 * @summary Forgot Password - authControllerForgotPassword
 */
export const authControllerForgotPassword = <TData = AxiosResponse<boolean>>(
    forgotPasswordDto: ForgotPasswordDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/auth/forgot-password`,
      forgotPasswordDto,options
    );
  }

/**
 * Brand/Creator Reset Password
 * @summary Reset Password - authControllerResetPassword
 */
export const authControllerResetPassword = <TData = AxiosResponse<UserEntity>>(
    resetPasswordDto: ResetPasswordDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/auth/reset-password`,
      resetPasswordDto,options
    );
  }

/**
 * Brand/Creator Add or Remove Biometrics
 * @summary Add or Remove Biometrics - authControllerBiometrics
 */
export const authControllerBiometrics = <TData = AxiosResponse<boolean>>(
    biometricsDto: BiometricsDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/auth/biometrics`,
      biometricsDto,options
    );
  }

/**
 * Brand/Creator Logout from current device
 * @summary Logout - authControllerLogout
 */
export const authControllerLogout = <TData = AxiosResponse<boolean>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/auth/logout`,undefined,options
    );
  }

/**
 * Brand/Creator find current logged in user
 * @summary Current Logged In User - userControllerFindMe
 */
export const userControllerFindMe = <TData = AxiosResponse<UserEntity>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/user`,options
    );
  }

/**
 * Brand/Creator update current logged in user
 * @summary Update User - userControllerUpdate
 */
export const userControllerUpdate = <TData = AxiosResponse<UserEntity>>(
    updateUserDto: UpdateUserDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/user`,
      updateUserDto,options
    );
  }

/**
 * Brand/Creator delete current logged in user
 * @summary Delete User - userControllerDelete
 */
export const userControllerDelete = <TData = AxiosResponse<boolean>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/user`,options
    );
  }

/**
 * Brand/Creator find all my categories
 * @summary Find My Categories - userControllerFindCategories
 */
export const userControllerFindCategories = <TData = AxiosResponse<CategoryEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/user/categories`,options
    );
  }

/**
 * Brand/Creator update my categories
 * @summary Update My Categories - userControllerUpdateCategories
 */
export const userControllerUpdateCategories = <TData = AxiosResponse<CategoryEntity[]>>(
    updateCategoryDto: UpdateCategoryDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/user/categories`,
      updateCategoryDto,options
    );
  }

/**
 * Brand - Get Prices
 * @summary Get Prices - brandControllerGetPrices
 */
export const brandControllerGetPrices = <TData = AxiosResponse<PricesEntity>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/brand/prices`,options
    );
  }

/**
 * Brand - Get Deals Prices
 * @summary Get Deals Prices - brandControllerGetDealsPrices
 */
export const brandControllerGetDealsPrices = <TData = AxiosResponse<DealPriceEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/brand/deals`,options
    );
  }

/**
 * Public - Get all brands
 * @summary Get all Brands - brandControllerFindAll
 */
export const brandControllerFindAll = <TData = AxiosResponse<PublicUserEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/brand`,options
    );
  }

/**
 * Brand/Creator - Get brand by user ID
 * @summary Get Brand by User ID - brandControllerFindByUserId
 */
export const brandControllerFindByUserId = <TData = AxiosResponse<UserEntity>>(
    userId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/brand/${userId}`,options
    );
  }

/**
 * Brand - Get discount
 * @summary Get Discount - brandControllerGetDiscount
 */
export const brandControllerGetDiscount = <TData = AxiosResponse<boolean>>(
    getDiscountDto: GetDiscountDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/brand/get-discount`,
      getDiscountDto,options
    );
  }

/**
 * Brand - Create New Task
 * @summary Create New Task - taskBrandControllerCreate
 */
export const taskBrandControllerCreate = <TData = AxiosResponse<TaskEntity>>(
    createTaskDto: CreateTaskDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/task`,
      createTaskDto,options
    );
  }

/**
 * Brand - Find All My Tasks
 * @summary Find All My Tasks - taskBrandControllerFindMyTasks
 */
export const taskBrandControllerFindMyTasks = <TData = AxiosResponse<TaskEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task`,options
    );
  }

/**
 * Brand/Creator - Find Task By ID
 * @summary Find Task By ID - taskBrandControllerFindById
 */
export const taskBrandControllerFindById = <TData = AxiosResponse<TaskEntity>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task/${id}`,options
    );
  }

/**
 * Brand - Update Task By Id
 * @summary Update Task By Id - taskBrandControllerUpdate
 */
export const taskBrandControllerUpdate = <TData = AxiosResponse<TaskEntity>>(
    id: string,
    updateTaskDto: UpdateTaskDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/task/${id}`,
      updateTaskDto,options
    );
  }

/**
 * Brand - Reorder task
 * @summary Reorder task - taskBrandControllerReorder
 */
export const taskBrandControllerReorder = <TData = AxiosResponse<UserEntity[]>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task/reorder/${id}`,options
    );
  }

/**
 * Brand - Get All Applications by Task ID
 * @summary Get All Applications by Task ID - taskBrandControllerFindApplicationsByTaskId
 */
export const taskBrandControllerFindApplicationsByTaskId = <TData = AxiosResponse<ApplicationEntity[]>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task/applications/${id}`,options
    );
  }

/**
 * Brand - Get Deliveries By Task ID
 * @summary Get Deliveries By Task ID  - taskBrandControllerFindDeliveriesByTaskId
 */
export const taskBrandControllerFindDeliveriesByTaskId = <TData = AxiosResponse<DeliveryEntity[]>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task/deliveries/${id}`,options
    );
  }

/**
 * Brand - Get All Videos by Task ID
 * @summary Get All Videos by Task ID - taskBrandControllerFindVideosByTaskId
 */
export const taskBrandControllerFindVideosByTaskId = <TData = AxiosResponse<ApplicationEntity[]>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task/videos/${id}`,options
    );
  }

/**
 * Creator - Find All Tabs Counts
 * @summary Find All Tabs Counts - taskCreatorControllerTabsCounts
 */
export const taskCreatorControllerTabsCounts = <TData = AxiosResponse<TaskSuggestedEntity>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task-creator/counts`,options
    );
  }

/**
 * Creator - Find All Suggested Tasks
 * @summary Find All Suggested Tasks - taskCreatorControllerFindSuggested
 */
export const taskCreatorControllerFindSuggested = <TData = AxiosResponse<TasksCountsEntity>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task-creator/suggested`,options
    );
  }

/**
 * Creator - Find All Tasks
 * @summary Find All Tasks - taskCreatorControllerFindAll
 */
export const taskCreatorControllerFindAll = <TData = AxiosResponse<TaskEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task-creator`,options
    );
  }

/**
 * Creator - Find All Brands with Tasks
 * @summary Find All Brands with Tasks - taskCreatorControllerFindAllBrands
 */
export const taskCreatorControllerFindAllBrands = <TData = AxiosResponse<UserEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task-creator/brands`,options
    );
  }

/**
 * Creator - Find All Tasks by Brand ID
 * @summary Find All Tasks by Brand ID - taskCreatorControllerFindAllByBrandUserId
 */
export const taskCreatorControllerFindAllByBrandUserId = <TData = AxiosResponse<BrandTasksEntity>>(
    userId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task-creator/brands/${userId}`,options
    );
  }

/**
 * Creator - Find All Categories with Tasks
 * @summary Find All Categories with Tasks - taskCreatorControllerFindAllCategories
 */
export const taskCreatorControllerFindAllCategories = <TData = AxiosResponse<CategoryEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task-creator/categories`,options
    );
  }

/**
 * Creator - Find All Tasks by Category ID
 * @summary Find All Tasks by Category ID - taskCreatorControllerFindAllByCategoryId
 */
export const taskCreatorControllerFindAllByCategoryId = <TData = AxiosResponse<TaskEntity[]>>(
    categoryId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task-creator/categories/${categoryId}`,options
    );
  }

/**
 * Admin - Approve new Task
 * @summary Approve new Task - taskAdminControllerFindAllToApprove
 */
export const taskAdminControllerFindAllToApprove = <TData = AxiosResponse<boolean>>(
    params: TaskAdminControllerFindAllToApproveParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/task-admin/approve`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Brand - Find All Payments
 * @summary Find All Payments - paymentControllerFindAll
 */
export const paymentControllerFindAll = <TData = AxiosResponse<PaymentEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/payment`,options
    );
  }

/**
 * Brand - Create Payment Intent
 * @summary Create Payment Intent - paymentControllerCreateIntent
 */
export const paymentControllerCreateIntent = <TData = AxiosResponse<PaymentIntentEntity>>(
    createPaymentIntentDto: CreatePaymentIntentDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/payment`,
      createPaymentIntentDto,options
    );
  }

/**
 * Brand - Update Payment Status
 * @summary Update Payment Status - paymentControllerUpdateStatus
 */
export const paymentControllerUpdateStatus = <TData = AxiosResponse<boolean>>(
    updatePaymentDto: UpdatePaymentDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/payment`,
      updatePaymentDto,options
    );
  }

/**
 * Brand - Apply Promo Code
 * @summary Apply Promo Code - paymentControllerApplyPromo
 */
export const paymentControllerApplyPromo = <TData = AxiosResponse<PromoDetails>>(
    applyPromoDto: ApplyPromoDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/payment/apply-promo`,
      applyPromoDto,options
    );
  }

/**
 * Brand - Remove Promo Code
 * @summary Remove Promo Code - paymentControllerRemovePromo
 */
export const paymentControllerRemovePromo = <TData = AxiosResponse<boolean>>(
    taskId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/payment/remove-promo/${taskId}`,options
    );
  }

/**
 * Brand - Create Balance Payment Intent
 * @summary Create Balance Payment Intent - paymentControllerCreateBalanceIntent
 */
export const paymentControllerCreateBalanceIntent = <TData = AxiosResponse<PaymentIntentEntity>>(
    createBalanceIntentDto: CreateBalanceIntentDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/payment/balance`,
      createBalanceIntentDto,options
    );
  }

/**
 * Brand - Update Balance Payment Status
 * @summary Update Balance Payment Status - paymentControllerUpdateBalanceStatus
 */
export const paymentControllerUpdateBalanceStatus = <TData = AxiosResponse<boolean>>(
    updateBalancePaymentDto: UpdateBalancePaymentDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/payment/balance`,
      updateBalancePaymentDto,options
    );
  }

/**
 * Brand - Make Task Payment
 * @summary Make Task Payment - paymentControllerMakePayment
 */
export const paymentControllerMakePayment = <TData = AxiosResponse<boolean>>(
    makePaymentDto: MakePaymentDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/payment/make-task-payment`,
      makePaymentDto,options
    );
  }

/**
 * Brand - Make Balance Payment
 * @summary Make Balance Payment - paymentControllerMakeBalancePayment
 */
export const paymentControllerMakeBalancePayment = <TData = AxiosResponse<boolean>>(
    makeBalancePaymentDto: MakeBalancePaymentDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/payment/make-balance-payment`,
      makeBalancePaymentDto,options
    );
  }

/**
 * Brand - Get All My Cards
 * @summary Get All My Cards - paymentControllerGetCards
 */
export const paymentControllerGetCards = <TData = AxiosResponse<PaymentMethodEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/payment/cards`,options
    );
  }

/**
 * Brand - Add Card
 * @summary Add Card - paymentControllerAddCard
 */
export const paymentControllerAddCard = <TData = AxiosResponse<boolean>>(
    cardId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/payment/cards/${cardId}`,undefined,options
    );
  }

/**
 * Brand - Set Default Card
 * @summary Set Default Card - paymentControllerSetDefaultCard
 */
export const paymentControllerSetDefaultCard = <TData = AxiosResponse<boolean>>(
    cardId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/payment/cards/${cardId}`,undefined,options
    );
  }

/**
 * Brand - Delete Card
 * @summary Delete Card - paymentControllerDeleteCard
 */
export const paymentControllerDeleteCard = <TData = AxiosResponse<boolean>>(
    cardId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/payment/cards/${cardId}`,options
    );
  }

/**
 * Brand - Get brand dashboard data
 * @summary Get Dashboard Data - applicationBrandControllerDashboard
 */
export const applicationBrandControllerDashboard = <TData = AxiosResponse<BrandDashboardEntity>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application/dashboard`,options
    );
  }

/**
 * Brand - Get All Applications
 * @summary Get All Applications - applicationBrandControllerFindAll
 */
export const applicationBrandControllerFindAll = <TData = AxiosResponse<ApplicationEntity[]>>(
    params?: ApplicationBrandControllerFindAllParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Brand - Get All Creators
 * @summary Get All Creators - applicationBrandControllerFindAllCreators
 */
export const applicationBrandControllerFindAllCreators = <TData = AxiosResponse<UserEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application/creators`,options
    );
  }

/**
 * Brand - Get Delivery By Application ID
 * @summary Get Delivery By Application ID - applicationBrandControllerFindAllDeliveriesByApplicationId
 */
export const applicationBrandControllerFindAllDeliveriesByApplicationId = <TData = AxiosResponse<DeliveryEntity>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application/deliveries/${id}`,options
    );
  }

/**
 * Brand - Approve Application
 * @summary Approve Application - applicationBrandControllerApprove
 */
export const applicationBrandControllerApprove = <TData = AxiosResponse<ApplicationEntity>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/application/approve/${id}`,undefined,options
    );
  }

/**
 * Brand - Reject Application
 * @summary Reject Application - applicationBrandControllerReject
 */
export const applicationBrandControllerReject = <TData = AxiosResponse<ApplicationEntity>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/application/reject/${id}`,undefined,options
    );
  }

/**
 * Brand - Request Edits for Application
 * @summary Request Edits - applicationBrandControllerRequestEdits
 */
export const applicationBrandControllerRequestEdits = <TData = AxiosResponse<ApplicationEntity>>(
    id: string,
    applicationsEditsDto: ApplicationsEditsDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/application/request-edits/${id}`,
      applicationsEditsDto,options
    );
  }

/**
 * Brand - Approve Application Video
 * @summary Approve Application Video - applicationBrandControllerApproveVideo
 */
export const applicationBrandControllerApproveVideo = <TData = AxiosResponse<ApplicationEntity>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/application/approve-video/${id}`,undefined,options
    );
  }

/**
 * Creator - Create New Application
 * @summary Create New Application - applicationCreatorControllerCreate
 */
export const applicationCreatorControllerCreate = <TData = AxiosResponse<ApplicationEntity>>(
    createApplicationDto: CreateApplicationDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/application-creator`,
      createApplicationDto,options
    );
  }

/**
 * Creator - Submit Video
 * @summary Submit Video - applicationCreatorControllerSubmitVideo
 */
export const applicationCreatorControllerSubmitVideo = <TData = AxiosResponse<ApplicationEntity>>(
    id: string,
    submitVideoDto: SubmitVideoDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/application-creator/${id}`,
      submitVideoDto,options
    );
  }

/**
 * Creator - Revoke Application
 * @summary Revoke Application - applicationCreatorControllerRevoke
 */
export const applicationCreatorControllerRevoke = <TData = AxiosResponse<ApplicationEntity>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/application-creator/${id}`,options
    );
  }

/**
 * Creator - Applications Overview
 * @summary Applications Overview - applicationCreatorControllerOverview
 */
export const applicationCreatorControllerOverview = <TData = AxiosResponse<ApplicationsOverviewEntity>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application-creator/overview`,options
    );
  }

/**
 * Creator - Find Applied applications
 * @summary Find Applied applications - applicationCreatorControllerFindApplied
 */
export const applicationCreatorControllerFindApplied = <TData = AxiosResponse<ApplicationEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application-creator/applied`,options
    );
  }

/**
 * Creator - Find Invited Applications
 * @summary Find Invited Applications - applicationCreatorControllerFindInvited
 */
export const applicationCreatorControllerFindInvited = <TData = AxiosResponse<ApplicationEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application-creator/invited`,options
    );
  }

/**
 * Creator - Find Approved applications
 * @summary Find Approved applications - applicationCreatorControllerFindApproved
 */
export const applicationCreatorControllerFindApproved = <TData = AxiosResponse<ApplicationEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application-creator/approved`,options
    );
  }

/**
 * Creator - Find Arriving Applications
 * @summary Find Arriving Applications - applicationCreatorControllerFindArriving
 */
export const applicationCreatorControllerFindArriving = <TData = AxiosResponse<ApplicationEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application-creator/arriving`,options
    );
  }

/**
 * Creator - Find Shoot Video Applications
 * @summary Find Shoot Video Applications - applicationCreatorControllerFindShootVideo
 */
export const applicationCreatorControllerFindShootVideo = <TData = AxiosResponse<ApplicationEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application-creator/shoot_video`,options
    );
  }

/**
 * Creator - Find Edits Requested
 * @summary Find Edits Requested - applicationCreatorControllerFindEditsRequested
 */
export const applicationCreatorControllerFindEditsRequested = <TData = AxiosResponse<ApplicationEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application-creator/edits-requested`,options
    );
  }

/**
 * Creator - Find Completed Applications
 * @summary Find Completed Applications - applicationCreatorControllerFindCompleted
 */
export const applicationCreatorControllerFindCompleted = <TData = AxiosResponse<ApplicationEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application-creator/completed`,options
    );
  }

/**
 * Creator - Find All my Videos
 * @summary Find All my Videos - applicationCreatorControllerFindMyVideos
 */
export const applicationCreatorControllerFindMyVideos = <TData = AxiosResponse<ApplicationEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application-creator/my-videos`,options
    );
  }

/**
 * Creator - Find Earnings
 * @summary Find Earnings - applicationCreatorControllerFindEarnings
 */
export const applicationCreatorControllerFindEarnings = <TData = AxiosResponse<CreatorEarningsEntity>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/application-creator/earnings`,options
    );
  }

/**
 * Brand/Creator - Get All Deliveries
 * @summary Get All Deliveries - deliveryControllerFindAll
 */
export const deliveryControllerFindAll = <TData = AxiosResponse<DeliveryEntity[]>>(
    params?: DeliveryControllerFindAllParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/delivery`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Brand - Update Delivery Tracking Number
 * @summary Update Delivery Tracking Number - deliveryControllerUpdateTrackingNumber
 */
export const deliveryControllerUpdateTrackingNumber = <TData = AxiosResponse<DeliveryEntity>>(
    id: string,
    updateDeliveryTrackingNumberDto: UpdateDeliveryTrackingNumberDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/delivery/${id}`,
      updateDeliveryTrackingNumberDto,options
    );
  }

/**
 * Brand/Creator - Update Delivery Status
 * @summary Update Delivery Status - deliveryControllerUpdateStatus
 */
export const deliveryControllerUpdateStatus = <TData = AxiosResponse<DeliveryEntity>>(
    id: string,
    updateDeliveryStatusDto: UpdateDeliveryStatusDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/delivery/status/${id}`,
      updateDeliveryStatusDto,options
    );
  }

/**
 * Brand/Creator - Update Delivery Condition
 * @summary Update Delivery Condition - deliveryControllerUpdateCondition
 */
export const deliveryControllerUpdateCondition = <TData = AxiosResponse<DeliveryEntity>>(
    id: string,
    updateDeliveryConditionDto: UpdateDeliveryConditionDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/delivery/condition/${id}`,
      updateDeliveryConditionDto,options
    );
  }

/**
 * Brand/Creator - Find All Notifications
 * @summary Find All Notifications - notificationsControllerFindAll
 */
export const notificationsControllerFindAll = <TData = AxiosResponse<NotificationEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/notifications`,options
    );
  }

/**
 * Brand/Creator - Mark All Notifications as Read
 * @summary Mark All Notifications as Read - notificationsControllerReadAll
 */
export const notificationsControllerReadAll = <TData = AxiosResponse<boolean>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/notifications`,undefined,options
    );
  }

/**
 * Brand/Creator - Delete All Notifications
 * @summary Delete All Notifications - notificationsControllerDeleteAll
 */
export const notificationsControllerDeleteAll = <TData = AxiosResponse<boolean[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/notifications`,options
    );
  }

/**
 * Brand/Creator - Find Unread Notifications Count
 * @summary Find Unread Notifications Count - notificationsControllerUnreadCount
 */
export const notificationsControllerUnreadCount = <TData = AxiosResponse<number>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/notifications/unread`,options
    );
  }

/**
 * Brand/Creator - Mark Notification as Read
 * @summary Mark Notification as Read - notificationsControllerRead
 */
export const notificationsControllerRead = <TData = AxiosResponse<NotificationEntity>>(
    id: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/notifications/${id}`,undefined,options
    );
  }

/**
 * Brand/Creator - Delete Notification
 * @summary Delete Notification - notificationsControllerDelete
 */
export const notificationsControllerDelete = <TData = AxiosResponse<NotificationEntity>>(
    id: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/notifications/${id}`,options
    );
  }

/**
 * @summary Send Notification - notificationsAdminControllerSend
 */
export const notificationsAdminControllerSend = <TData = AxiosResponse<boolean>>(
    sendNotificationDto: SendNotificationDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/notifications-admin/send`,
      sendNotificationDto,options
    );
  }

/**
 * Public - Get all creators
 * @summary Get all Creators - creatorControllerFindAll
 */
export const creatorControllerFindAll = <TData = AxiosResponse<PublicUserEntity[]>>(
    params?: CreatorControllerFindAllParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/creator`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Brand/Creator - Get creator by user ID
 * @summary Get Creator by User ID - creatorControllerFindByUserId
 */
export const creatorControllerFindByUserId = <TData = AxiosResponse<UserEntity>>(
    userId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/creator/${userId}`,options
    );
  }

/**
 * Admin - Update Pitch Video Status & Send Notification
 * @summary Update Pitch Video Status - creatorAdminControllerPitchStatus
 */
export const creatorAdminControllerPitchStatus = <TData = AxiosResponse<boolean>>(
    params: CreatorAdminControllerPitchStatusParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/creator-admin/pitch-status`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Brand - Create New Product
 * @summary Create New Product - productControllerCreate
 */
export const productControllerCreate = <TData = AxiosResponse<ProductEntity>>(
    createProductDto: CreateProductDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/product`,
      createProductDto,options
    );
  }

/**
 * Brand - Get All My Products
 * @summary Get All My Products - productControllerFindAll
 */
export const productControllerFindAll = <TData = AxiosResponse<ProductEntity[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/product`,options
    );
  }

/**
 * Brand - Get One Product by ID
 * @summary Get One Product by ID - productControllerFindOne
 */
export const productControllerFindOne = <TData = AxiosResponse<ProductEntity>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/product/${id}`,options
    );
  }

/**
 * Brand - Update Product by ID
 * @summary Update Product by ID - productControllerUpdate
 */
export const productControllerUpdate = <TData = AxiosResponse<ProductEntity>>(
    id: string,
    updateProductDto: UpdateProductDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `/product/${id}`,
      updateProductDto,options
    );
  }

/**
 * Brand - Remove Product by ID
 * @summary Remove Product by ID - productControllerRemove
 */
export const productControllerRemove = <TData = AxiosResponse<ProductEntity>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/product/${id}`,options
    );
  }

/**
 * Brand - Create New Rate for an Application
 * @summary Create New Rate for an Application - rateControllerCreate
 */
export const rateControllerCreate = <TData = AxiosResponse<ApplicationEntity>>(
    id: number,
    createRateDto: CreateRateDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/application/rate/${id}`,
      createRateDto,options
    );
  }

/**
 * Brand/Creator - Upload a file [image, video, audio]
 * @summary Upload a file - fileControllerUpload
 */
export const fileControllerUpload = <TData = AxiosResponse<FileEntity>>(
    fileControllerUploadBody: FileControllerUploadBody, options?: AxiosRequestConfig
 ): Promise<TData> => {const formData = new FormData();
if(fileControllerUploadBody.file !== undefined) {
 formData.append('file', fileControllerUploadBody.file)
 }

    return axios.post(
      `/file/upload`,
      formData,options
    );
  }

/**
 * Public - Get an image by key
 * @summary Get an Image by Key - fileControllerImage
 */
export const fileControllerImage = <TData = AxiosResponse<void>>(
    key: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/file/image/${key}`,options
    );
  }

/**
 * Public - Get a video by key
 * @summary Get a Video by Key - fileControllerVideo
 */
export const fileControllerVideo = <TData = AxiosResponse<void>>(
    key: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/file/video/${key}`,options
    );
  }

/**
 * Public - Get an audio by key
 * @summary Get an Audio by Key - fileControllerAudio
 */
export const fileControllerAudio = <TData = AxiosResponse<void>>(
    key: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/file/audio/${key}`,options
    );
  }

/**
 * Public - Subscribe to Newsletter
 * @summary Subscribe to Newsletter - newsletterControllerCreate
 */
export const newsletterControllerCreate = <TData = AxiosResponse<boolean>>(
    createNewsletterDto: CreateNewsletterDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/newsletter`,
      createNewsletterDto,options
    );
  }

/**
 * Public - Contact Us
 * @summary Contact Us - contactControllerCreate
 */
export const contactControllerCreate = <TData = AxiosResponse<boolean>>(
    createContactDto: CreateContactDto, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/contact`,
      createContactDto,options
    );
  }

export type DeviceControllerCreateResult = AxiosResponse<DeviceEntity>
export type DeviceControllerUpdateResult = AxiosResponse<DeviceEntity>
export type CategoryControllerFindAllResult = AxiosResponse<CategoryEntity[]>
export type AuthControllerApproveResult = AxiosResponse<boolean>
export type AuthControllerSignupResult = AxiosResponse<UserEntity>
export type AuthControllerLoginResult = AxiosResponse<UserEntity>
export type AuthControllerBiometricsLoginResult = AxiosResponse<UserEntity>
export type AuthControllerGoogleResult = AxiosResponse<UserEntity>
export type AuthControllerAppleResult = AxiosResponse<UserEntity>
export type AuthControllerGoogleConnectResult = AxiosResponse<UserEntity>
export type AuthControllerGoogleDisconnectResult = AxiosResponse<UserEntity>
export type AuthControllerAppleConnectResult = AxiosResponse<UserEntity>
export type AuthControllerAppleDisconnectResult = AxiosResponse<UserEntity>
export type AuthControllerChangePasswordResult = AxiosResponse<boolean>
export type AuthControllerCreatePasswordResult = AxiosResponse<UserEntity>
export type AuthControllerForgotPasswordResult = AxiosResponse<boolean>
export type AuthControllerResetPasswordResult = AxiosResponse<UserEntity>
export type AuthControllerBiometricsResult = AxiosResponse<boolean>
export type AuthControllerLogoutResult = AxiosResponse<boolean>
export type UserControllerFindMeResult = AxiosResponse<UserEntity>
export type UserControllerUpdateResult = AxiosResponse<UserEntity>
export type UserControllerDeleteResult = AxiosResponse<boolean>
export type UserControllerFindCategoriesResult = AxiosResponse<CategoryEntity[]>
export type UserControllerUpdateCategoriesResult = AxiosResponse<CategoryEntity[]>
export type BrandControllerGetPricesResult = AxiosResponse<PricesEntity>
export type BrandControllerGetDealsPricesResult = AxiosResponse<DealPriceEntity[]>
export type BrandControllerFindAllResult = AxiosResponse<PublicUserEntity[]>
export type BrandControllerFindByUserIdResult = AxiosResponse<UserEntity>
export type BrandControllerGetDiscountResult = AxiosResponse<boolean>
export type TaskBrandControllerCreateResult = AxiosResponse<TaskEntity>
export type TaskBrandControllerFindMyTasksResult = AxiosResponse<TaskEntity[]>
export type TaskBrandControllerFindByIdResult = AxiosResponse<TaskEntity>
export type TaskBrandControllerUpdateResult = AxiosResponse<TaskEntity>
export type TaskBrandControllerReorderResult = AxiosResponse<UserEntity[]>
export type TaskBrandControllerFindApplicationsByTaskIdResult = AxiosResponse<ApplicationEntity[]>
export type TaskBrandControllerFindDeliveriesByTaskIdResult = AxiosResponse<DeliveryEntity[]>
export type TaskBrandControllerFindVideosByTaskIdResult = AxiosResponse<ApplicationEntity[]>
export type TaskCreatorControllerTabsCountsResult = AxiosResponse<TaskSuggestedEntity>
export type TaskCreatorControllerFindSuggestedResult = AxiosResponse<TasksCountsEntity>
export type TaskCreatorControllerFindAllResult = AxiosResponse<TaskEntity[]>
export type TaskCreatorControllerFindAllBrandsResult = AxiosResponse<UserEntity[]>
export type TaskCreatorControllerFindAllByBrandUserIdResult = AxiosResponse<BrandTasksEntity>
export type TaskCreatorControllerFindAllCategoriesResult = AxiosResponse<CategoryEntity[]>
export type TaskCreatorControllerFindAllByCategoryIdResult = AxiosResponse<TaskEntity[]>
export type TaskAdminControllerFindAllToApproveResult = AxiosResponse<boolean>
export type PaymentControllerFindAllResult = AxiosResponse<PaymentEntity[]>
export type PaymentControllerCreateIntentResult = AxiosResponse<PaymentIntentEntity>
export type PaymentControllerUpdateStatusResult = AxiosResponse<boolean>
export type PaymentControllerApplyPromoResult = AxiosResponse<PromoDetails>
export type PaymentControllerRemovePromoResult = AxiosResponse<boolean>
export type PaymentControllerCreateBalanceIntentResult = AxiosResponse<PaymentIntentEntity>
export type PaymentControllerUpdateBalanceStatusResult = AxiosResponse<boolean>
export type PaymentControllerMakePaymentResult = AxiosResponse<boolean>
export type PaymentControllerMakeBalancePaymentResult = AxiosResponse<boolean>
export type PaymentControllerGetCardsResult = AxiosResponse<PaymentMethodEntity[]>
export type PaymentControllerAddCardResult = AxiosResponse<boolean>
export type PaymentControllerSetDefaultCardResult = AxiosResponse<boolean>
export type PaymentControllerDeleteCardResult = AxiosResponse<boolean>
export type ApplicationBrandControllerDashboardResult = AxiosResponse<BrandDashboardEntity>
export type ApplicationBrandControllerFindAllResult = AxiosResponse<ApplicationEntity[]>
export type ApplicationBrandControllerFindAllCreatorsResult = AxiosResponse<UserEntity[]>
export type ApplicationBrandControllerFindAllDeliveriesByApplicationIdResult = AxiosResponse<DeliveryEntity>
export type ApplicationBrandControllerApproveResult = AxiosResponse<ApplicationEntity>
export type ApplicationBrandControllerRejectResult = AxiosResponse<ApplicationEntity>
export type ApplicationBrandControllerRequestEditsResult = AxiosResponse<ApplicationEntity>
export type ApplicationBrandControllerApproveVideoResult = AxiosResponse<ApplicationEntity>
export type ApplicationCreatorControllerCreateResult = AxiosResponse<ApplicationEntity>
export type ApplicationCreatorControllerSubmitVideoResult = AxiosResponse<ApplicationEntity>
export type ApplicationCreatorControllerRevokeResult = AxiosResponse<ApplicationEntity>
export type ApplicationCreatorControllerOverviewResult = AxiosResponse<ApplicationsOverviewEntity>
export type ApplicationCreatorControllerFindAppliedResult = AxiosResponse<ApplicationEntity[]>
export type ApplicationCreatorControllerFindInvitedResult = AxiosResponse<ApplicationEntity[]>
export type ApplicationCreatorControllerFindApprovedResult = AxiosResponse<ApplicationEntity[]>
export type ApplicationCreatorControllerFindArrivingResult = AxiosResponse<ApplicationEntity[]>
export type ApplicationCreatorControllerFindShootVideoResult = AxiosResponse<ApplicationEntity[]>
export type ApplicationCreatorControllerFindEditsRequestedResult = AxiosResponse<ApplicationEntity[]>
export type ApplicationCreatorControllerFindCompletedResult = AxiosResponse<ApplicationEntity[]>
export type ApplicationCreatorControllerFindMyVideosResult = AxiosResponse<ApplicationEntity[]>
export type ApplicationCreatorControllerFindEarningsResult = AxiosResponse<CreatorEarningsEntity>
export type DeliveryControllerFindAllResult = AxiosResponse<DeliveryEntity[]>
export type DeliveryControllerUpdateTrackingNumberResult = AxiosResponse<DeliveryEntity>
export type DeliveryControllerUpdateStatusResult = AxiosResponse<DeliveryEntity>
export type DeliveryControllerUpdateConditionResult = AxiosResponse<DeliveryEntity>
export type NotificationsControllerFindAllResult = AxiosResponse<NotificationEntity[]>
export type NotificationsControllerReadAllResult = AxiosResponse<boolean>
export type NotificationsControllerDeleteAllResult = AxiosResponse<boolean[]>
export type NotificationsControllerUnreadCountResult = AxiosResponse<number>
export type NotificationsControllerReadResult = AxiosResponse<NotificationEntity>
export type NotificationsControllerDeleteResult = AxiosResponse<NotificationEntity>
export type NotificationsAdminControllerSendResult = AxiosResponse<boolean>
export type CreatorControllerFindAllResult = AxiosResponse<PublicUserEntity[]>
export type CreatorControllerFindByUserIdResult = AxiosResponse<UserEntity>
export type CreatorAdminControllerPitchStatusResult = AxiosResponse<boolean>
export type ProductControllerCreateResult = AxiosResponse<ProductEntity>
export type ProductControllerFindAllResult = AxiosResponse<ProductEntity[]>
export type ProductControllerFindOneResult = AxiosResponse<ProductEntity>
export type ProductControllerUpdateResult = AxiosResponse<ProductEntity>
export type ProductControllerRemoveResult = AxiosResponse<ProductEntity>
export type RateControllerCreateResult = AxiosResponse<ApplicationEntity>
export type FileControllerUploadResult = AxiosResponse<FileEntity>
export type FileControllerImageResult = AxiosResponse<void>
export type FileControllerVideoResult = AxiosResponse<void>
export type FileControllerAudioResult = AxiosResponse<void>
export type NewsletterControllerCreateResult = AxiosResponse<boolean>
export type ContactControllerCreateResult = AxiosResponse<boolean>
