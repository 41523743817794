import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '.';
interface InitialState {
  loggedIn: boolean;
  language: string;
  deviceId: number;
  loading: boolean;
  error: string;
}

const slice = createSlice({
  name: 'configsReducer',
  initialState: {
    deviceId: 0,
    loading: false,
    loggedIn: false,
    language: 'en',
    error: ''
  } as InitialState,
  reducers: {
    setDeviceId: (state, action: { payload: number }) => {
      state.deviceId = action.payload;
    },
    setLanguage: (state, action: { payload: string }) => {
      state.language = action.payload;
    },
    setLoading: (state, action: { payload: boolean }) => {
      state.loading = action.payload;
    },
    setLoggedIn: (state, action: { payload: boolean }) => {
      state.loggedIn = action.payload;
    },
    setError: (state, action) => {
      const e = action.payload;
      const error =
        typeof e === 'string'
          ? e
          : e?.response?.data?.errors?.length > 0
          ? e?.response?.data?.errors[0]
          : e?.message;
      state.error = error;
    }
  }
});

export default slice.reducer;

export const { setDeviceId, setLanguage, setLoading, setError, setLoggedIn } =
  slice.actions;

export const selectDeviceId = (state: RootState) =>
  state.ConfigsReducer.deviceId;

export const selectLanguage = (state: RootState) =>
  state.ConfigsReducer.language;

export const setDeviceIdAction =
  ({ deviceId }: { deviceId: number }): AppThunk =>
  async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setDeviceId({ deviceId }));
    } catch (e) {}
  };
