import {
  Box,
  styled,
  Typography,
  useTheme,
  Container,
  Grid,
  Divider
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import Brands from '../Home/Brands';
import { useNavigate } from 'react-router-dom';
import { AccessTime, ArrowForwardIos, Event } from '@mui/icons-material';

const OverviewWrapper = styled(Box)(
  ({ theme }) => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
`
);
const Content = styled('div')(
  ({ theme }) => `
   margin-top: 100px;
   padding: 30px 0;
`
);

const CustomImg = styled('img')(
  ({ theme }) => `
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
  
`
);
const CoverImageBox = styled('div')(
  ({ theme }) => `
  width: 100%;
`
);

const FooterText = styled('div')(
  ({ theme }) => `
  grid-column-gap: 1rem;
  padding-top: 0.8rem;
  display: flex;
  margin-top: 30px;
`
);
const Text = styled('div')(
  ({ theme }) => `
  grid-column-gap: 0.3rem;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: .5fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: flex;
`
);

const CustomBreadcrumb = styled('a')(
  ({ theme }) => `
  text-decoration: none;
  color: ${theme.colors.alpha.black[100]};
  font-weight: 700;
  transition: all 300ms ease-in-out;

  &:hover {
    color: ${theme.colors.primary.main};
  }
`
);

const TextContainer = styled(Box)(
  ({ theme }) => `
  border-radius: 1rem;
  padding: 4rem 4rem 2rem;
  margin-top: 50px;
  background: #f9f9f9;
`
);
const BoldText = styled('span')(
  ({ theme }) => `
  font-weight: 700;
`
);

function StoryTelling() {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <OverviewWrapper>
      <Helmet>
        <title>{t('Elli Creators - Video Storytelling?')}</title>
      </Helmet>
      <Header />

      <Content>
        <Container>
          <Box mb={3}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} md={6} lg={6}>
                <Box display={'flex'} alignItems={'center'} pt={2}>
                  <CustomBreadcrumb href="/blog">{t('Blog')}</CustomBreadcrumb>
                  <ArrowForwardIos style={{ fontSize: 12, margin: '0 5px' }} />
                  <Typography fontWeight={'700'}>
                    {t('Video Storytelling')}
                  </Typography>
                </Box>
                <Box mt={5}>
                  <Typography variant="h1">
                    {t('Mastering Video Storytelling: Connect, Engage, Inspire')}
                  </Typography>
                </Box>
                <FooterText>
                  <Text>
                    <Event />
                    <div>{t('Nov')} 09, 2024</div>
                  </Text>
                  <Text>
                    <AccessTime />
                    <div>5 - 6</div>
                    <div>{t('Min')}</div>
                  </Text>
                </FooterText>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CoverImageBox>
                  <CustomImg
                    alt=""
                    src="/static/images/blog/storytelling.jpeg"
                  />
                </CoverImageBox>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <TextContainer>
            <Typography mb={2} variant="h3">
              {t('What is Video Storytelling?')}
            </Typography>
            <Typography mb={3}>
              {t(
                "Video storytelling is more than just a marketing tactic—it's a way to build connections and create lasting impressions. Instead of simply describing a product or service, video storytelling uses narrative to weave together emotions, ideas, and experiences that resonate with audiences. By crafting a compelling story, brands can communicate in ways that are memorable, engaging, and ultimately more impactful."
              )}{' '}
            </Typography>
            <Typography mb={4}>
              {t(
                'When done well, a storytelling video doesn’t feel like an ad; it feels like a shared moment. This approach allows brands to reach people on a human level, forging bonds that go beyond the screen and encouraging viewers to see themselves in the story being told.'
              )}{' '}
            </Typography>

            <Typography variant="h3" mb={1}>
              {t('Why Video Storytelling Works')}
            </Typography>
            <Typography mb={3}>
              {t(
                'With attention spans decreasing and audiences facing more noise than ever, capturing interest isn’t easy. Yet stories have an incredible power to cut through this noise. They draw people in, tap into their emotions, and leave a lasting impression. Whether your story is inspirational, humorous, or heartfelt, it gives viewers something meaningful to connect with—something that makes your brand memorable.'
              )}{' '}
            </Typography>
            <Typography mb={4}>
              {t(
                'Through well-crafted narratives, brands can do more than just inform; they can inspire, entertain, and build trust. With the right approach, video storytelling can become one of the most powerful tools in your marketing arsenal.'
              )}{' '}
            </Typography>
            <Typography variant="h3" mb={1}>
              {t('The Building Blocks of Great Video Storytelling')}
            </Typography>
            <Typography mb={3}>
              {t(
                'If you’re considering a storytelling approach to video, it’s essential to understand the key elements that make a story effective. Here’s what to focus on to craft a video that engages and resonates:'
              )}
            </Typography>
            <Typography variant="h4" mb={2}>
              {t('1. Clarify Your Purpose')}
            </Typography>
            <Typography mb={4}>
              {t('Start with the question: ')}{' '}
              <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                {t('What is the purpose of this story?')}{' '}
              </span>{' '}
              {t(
                'Defining this goal will help guide the narrative and ensure the message is clear. Whether you aim to inspire, inform, or entertain, keeping your purpose in focus will help create a video that feels cohesive and impactful.'
              )}
            </Typography>

            <Typography variant="h4" mb={2}>
              {t('2. Know Your Audience')}
            </Typography>
            <Typography mb={4}>
              {t(
                'Great stories speak directly to the people watching them. Understanding your audience’s values, challenges, and aspirations allows you to craft a story they’ll care about. Research their preferences and consider what emotional response you want to evoke, then tailor your story accordingly.'
              )}
            </Typography>
            <Typography variant="h4" mb={2}>
              {t('3. Create Relatable Characters')}
            </Typography>
            <Typography mb={4}>
              {t(
                'Characters are the heart of any story, and relatable characters are the ones that make stories unforgettable. The people (or even objects!) in your video should feel familiar to your audience, representing their struggles, dreams, or values. When viewers see themselves reflected in the story, they’re far more likely to connect with it.'
              )}
            </Typography>
            <Typography variant="h4" mb={2}>
              {t('4. Choose the Right Format and Style')}
            </Typography>
            <Typography mb={4}>
              {t(
                'Not all stories are suited to every platform. A brief, engaging video might be perfect for social media, while a more in-depth story could shine on your website or YouTube channel. Matching your format to your story and audience will ensure that your message feels right at home wherever it’s shared.'
              )}
            </Typography>
            <Typography variant="h4" mb={2}>
              {t('5. End with a Call to Action')}
            </Typography>
            <Typography mb={4}>
              {t(
                'Even the best stories need a purpose. Think about what action you’d like viewers to take after watching. Whether it’s visiting your website, exploring a new product, or simply learning more, a clear call to action can give viewers an easy next step to continue the journey with your brand.'
              )}
            </Typography>

            <Typography variant="h3" mb={1}>
              {t('Techniques for Engaging Video Storytelling')}
            </Typography>
            <Typography mb={4}>
              {t(
                'Building an effective storytelling video is both art and science. Here are some techniques that can help you make your story stand out:'
              )}
            </Typography>

            <Typography mb={1} variant="h4">
              ● Create a Strong Plotline
            </Typography>

            <Typography mb={4}>
              A good story has a clear beginning, middle, and end. It introduces
              a relatable challenge, builds tension, and leads to a satisfying
              resolution. This classic structure works because it keeps viewers
              engaged and leaves them with a feeling of closure and
              understanding.
            </Typography>

            <Typography mb={1} variant="h4">
              ● Incorporate Compelling Visuals and Audio
            </Typography>

            <Typography mb={4}>
              Visuals and sound go hand-in-hand with storytelling. Carefully
              chosen visuals and music can evoke emotions and reinforce the
              story’s mood, while sound effects and voiceovers can help clarify
              key points. Every element should serve the story, adding depth and
              enhancing the viewing experience.
            </Typography>

            <Typography mb={1} variant="h4">
              ● Keep It Short and Impactful
            </Typography>

            <Typography mb={4}>
              Respect your viewers' time by keeping your video concise and to
              the point. Every second should add value, moving the story forward
              without unnecessary distractions. A focused video not only holds
              attention but also leaves a stronger impression.
            </Typography>

            <Typography mb={1} variant="h4">
              ● Edit with Purpose
            </Typography>

            <Typography mb={5}>
              Editing can transform a video from ordinary to captivating.
              Effective editing can enhance the story’s emotional impact,
              highlight key moments, and help maintain pacing. Think of it as
              the final polish that makes the story shine.
            </Typography>

            <Typography variant="h3" mb={1}>
              {t('Final Thoughts: Crafting Stories that Matter')}
            </Typography>

            <Typography mb={4}>
              Video storytelling is a powerful tool, offering a way to
              communicate that goes beyond mere words. By focusing on
              authenticity, relatable characters, and a well-crafted narrative,
              brands can create videos that resonate deeply with audiences. With
              the right approach, storytelling can transform your brand’s
              message into something people remember, share, and connect with.
            </Typography>

            <Typography>
              <span style={{fontWeight: 'bold'}}>At Elli Creators,</span> we believe in helping brands and creators tell
              these powerful stories—stories that don’t just sell but build
              lasting relationships. So whether you’re crafting your first video
              or refining a series, remember that storytelling is your way to
              connect in a world that’s craving real, meaningful interactions.
              Let’s tell stories that matter, together.
            </Typography>
          </TextContainer>
        </Container>
      </Content>
      <Footer />
    </OverviewWrapper>
  );
}

export default StoryTelling;
