import {
  Box,
  Card,
  Typography,
  styled,
  FormHelperText,
  useTheme,
  Container,
  Zoom
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { newsletterControllerCreate } from 'src/utils/APIs';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'src/store/ConfigsReducer';
import { ArrowBackTwoTone } from '@mui/icons-material';

const icons = {
  Facebook: '/static/images/svg/facebook.svg',
  Instagram: '/static/images/svg/instagram.svg',
  Tiktok: '/static/images/svg/tiktok.svg'
};

const FooterWrapper = styled(Card)(
  ({ theme }) => `
    border-radius: 0;
    margin-top: ${theme.spacing(4)};
    padding: 0 0 10px;
    box-shadow: none;
    `
);

const CustomInput = styled('input')(
  ({ theme }) => `
  width: 400px;
  height: 50px;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: ${theme.colors.primary.dark};
  padding: 7px;
  border-color: ${theme.colors.primary.dark};
  outline: none;
  border: 1px solid;

  
  @media (max-width: 960px) {
    width: 250px;
  }
  @media (max-width: 425px) {
    font-size: 15px;
    width: 100%;
  }`
);

const LogoWrapper = styled(RouterLink)(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  display: flex;
  text-decoration: none;
  margin: 25px auto;
  font-weight: ${theme.typography.fontWeightBold};

  @media (max-width: 768px) {
    justify-content: center;
  }
`
);
const CustomFormHelperText = styled(FormHelperText)(
  ({ theme }) => `
      color: ${theme.colors.error.main};
      font-size: 10px;
      margin-left: 0;
`
);

const CustomButton = styled('button')(
  ({ theme }) => `
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 50px;
  background: ${theme.colors.primary.dark};
  padding: 15px;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
  transition: opacity 300ms ease-in-out;
  &:hover {
    opacity: 0.8;
  }

 
`
);

const CustomRouterLink = styled(RouterLink)(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  margin: 0 8px 10px;
  text-decoration: none;
  font-weight: 700;
  transition: all 300ms ease-in-out;

  &:hover {
    text-decoration: none;
    color: ${theme.colors.lightPurple.main};
  }

  @media (max-width: 768px) {
    font-size: 12px;
   }
`
);

const CustomLink = styled('a')(
  ({ theme }) => `
  margin: 0 5px ;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`
);
const CustomBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
   flex-direction: column;
  }
`
);
const LinksBox = styled(Box)(
  ({ theme }) => `
  @media (max-width: 768px) {
   margin-top: 20px;
  }
`
);

function Footer() {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const lang = useSelector(selectLanguage);
  const theme = useTheme();

  return (
    <FooterWrapper className="footer-wrapper">
      <Container>
        <LogoWrapper to="/overview">
          <img
            style={{ height: 70 }}
            alt="Elli Creators"
            src="/static/images/logo/logo.svg"
            loading="lazy"
          />
        </LogoWrapper>
      </Container>

      <Container>
        <CustomBox>
          <Box>
            <Typography fontSize={20} marginBottom={2}>
              {t('Subscribe to the newsletter')} <br />{' '}
              {t('and stay in the know')}.
            </Typography>

            <Formik
              initialValues={{
                email: '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(
                    t('The email provided should be a valid email address')
                  )
                  .required(t('Field is required'))
              })}
              onSubmit={async (_values, { resetForm }) => {
                await newsletterControllerCreate({
                  email: _values.email
                })
                  .then((res) => {
                    enqueueSnackbar(
                      t('Subscribed to newsletter successfully!'),
                      {
                        variant: 'success',
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'center'
                        },
                        TransitionComponent: Zoom
                      }
                    );
                    resetForm();
                  })
                  .catch((err) => {
                    enqueueSnackbar(err?.response?.data?.message, {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                      },
                      TransitionComponent: Zoom
                    });
                  });
              }}
            >
              {({ errors, handleChange, handleSubmit, touched, values }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="flex" align-items="center">
                    <CustomInput
                      className={`${lang === 'en' ? '' : 'ar'}`}
                      placeholder={t('Email Address')}
                      name={'email'}
                      onChange={handleChange}
                      style={
                        Boolean(touched.email && errors.email)
                          ? { borderColor: theme.colors.error.main }
                          : {}
                      }
                      value={values.email}
                    ></CustomInput>
                    <CustomButton
                      className={`${lang === 'en' ? '' : 'ar'}`}
                      onClick={() => handleSubmit()}
                      type="submit"
                      style={
                        Boolean(touched.email && errors.email)
                          ? { borderColor: theme.colors.error.main }
                          : {}
                      }
                    >
                      {lang === 'en' ? (
                        <ArrowForwardTwoToneIcon
                          fontSize="small"
                          color="white"
                        />
                      ) : (
                        <ArrowBackTwoTone fontSize="small" color="white" />
                      )}
                    </CustomButton>
                  </Box>
                  <CustomFormHelperText>{errors.email}</CustomFormHelperText>
                </form>
              )}
            </Formik>
          </Box>

          <LinksBox display="flex">
            <Box display="flex" flexDirection="column">
              <CustomRouterLink to="/about-us">
                {t('About us')}
              </CustomRouterLink>
              <CustomRouterLink to="/blog">{t('Blogs')}</CustomRouterLink>
              <CustomRouterLink to="/contact-us">
                {t('Contact us')}
              </CustomRouterLink>
            </Box>
            <Box display="flex" flexDirection="column">
              {/* <CustomRouterLink to="/pricing">{t('Pricing')}</CustomRouterLink> */}
              <CustomRouterLink to="/faq">{t('FAQs')}</CustomRouterLink>
            </Box>
            <Box display="flex" flexDirection="column">
              <CustomRouterLink to="/terms-and-conditions">
                {t('Terms & conditions')}
              </CustomRouterLink>
              <CustomRouterLink to="/privacy-policy">
                {t('Privacy policy')}
              </CustomRouterLink>
            </Box>
          </LinksBox>
        </CustomBox>
      </Container>

      <Container style={{ marginTop: 30 }}>
        <Box display="flex" align-items="center" justifyContent="space-between">
          <Box>
            <Typography fontSize={12}>
              {t('© 2024 Elli. All rights reserved')}.
            </Typography>
          </Box>
          <Box>
            <CustomLink
              href="https://web.facebook.com/elliCreators"
              target="_blank"
            >
              <img height={20} alt="Facebook" src={icons['Facebook']} />
            </CustomLink>
            <CustomLink
              href="https://www.instagram.com/elli_creators"
              target="_blank"
            >
              <img height={20} alt="Instagram" src={icons['Instagram']} />
            </CustomLink>
            <CustomLink href="https://tiktok.com/elli_creators" target="_blank">
              <img height={20} alt="Tiktok" src={icons['Tiktok']} />
            </CustomLink>
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
}

export default Footer;
