import {
  AnyAction,
  configureStore,
  EmptyObject,
  ThunkAction
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import ConfigsReducer from './ConfigsReducer';
import ProductsReducer from './ProductsReducer';
import CategoriesReducer from './CategoriesReducer';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    return { ...(inboundState as Record<any, any>) };
  },
  // transform state being rehydrated
  (outboundState) => {
    return { ...outboundState };
  }
);

const rootReducer = combineReducers({
  AuthReducer,
  ConfigsReducer,
  ProductsReducer,
  CategoriesReducer
});

const persistConfig = {
  timeout: 1000,
  key: 'root',
  storage,
  transforms: [SetTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    });
  },
  enhancers: []
});

export const persistedStore = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
// export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
// export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppThunk = ThunkAction<
  void,
  EmptyObject & RootState & PersistPartial,
  undefined,
  AnyAction
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
