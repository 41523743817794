import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { UserEntity } from '../utils/APIs';
import { Notifications } from './Types';

interface InitialState {
  user: UserEntity | null;
  access_token: string | null;
}

const initialState: InitialState = {
  user: null,
  access_token: null
};

const slice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    setUser: (state, action: { payload: UserEntity }) => {
      state.user = action.payload;
    },
    setAccessToken: (state, action: { payload: string }) => {
      state.access_token = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.access_token = null;
    }
  }
});
export default slice.reducer;

export const { setUser, setAccessToken, logout } = slice.actions;
export const selectAccount = (state: RootState) => state.AuthReducer.user;
