import { Box, Button, Container, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import UserBox from 'src/content/Home/UserBox';
import { selectAccount } from 'src/store/AuthReducer';

import Logo from '../Logo';
import BurgerMenu from './BurgerMenu';
import LanguageSwitcher from 'src/layouts/TopNavigationLayout/TopBar/LanguageSwitcher';

const CustomBox = styled(Box)(
  ({ theme }) => `
  display: flex ;
  align-items: center;

  @media (max-width: 650px) {
    display: none;
  }
  `
);

const HeaderWrapper = styled('div')(
  ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    height: ${theme.spacing(10)};
    box-shadow: none;
    background: #fff;
    z-index: 9;
    

    &.navbar {
      position: absolute;
      width: 100%;
      padding: 0 0;
    }
    &.navbarSticky {
      position: fixed;
      top: 0;
      left: 0;
      border-bottom: 1px solid #f1f1f1;
      animation: moveDown 0.5s ease-in-out;
    }
    
    @keyframes moveDown {
      from {
        transform: translateY(-5rem);
      }
      to {
        transform: translateY(0rem);
      }
    }
    
    @keyframes rotate {
      0% {
        transform: rotateY(360deg);
      }
      100% {
        transform: rotateY(0rem);
      }
    }
`
);

const MobileNav = styled('div')(
  ({ theme }) => `
  display: none;
  
  @media (max-width: 768px) {
    display: block;
  }
`
);

const Link = styled(RouterLink)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 10px;
        font-weight: 700;

        &:hover,
        &:focus {
          color: ${theme.colors.lightPurple.main};
          text-decoration: none;
        }
        &:active {
          text-decoration: none;
        }
`
);
const LinksContainer = styled('div')(
  ({ theme }) => `
      display: flex;
      align-items: center;
      margin: 0 30px;

      @media (max-width: 768px) {
        display: none;
      }
`
);

function Header() {
  const { t }: { t: any } = useTranslation();
  const account = useSelector(selectAccount);

  const [show, setShow] = useState(false);

  const handleShow = () => {
    if (typeof window !== 'undefined') {
      if (window.pageYOffset > 120) {
        if (!show) {
          setShow(true);
        }
      }
      if (window.pageYOffset < 120) {
        setShow(false);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleShow);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleShow);
      }
    };
  }, []);

  return (
    <>
      <MobileNav>
        <BurgerMenu pageWrapId={'page-wrap'} outerContainerId={'App'} />
        <div id="page-wrap"></div>
      </MobileNav>

      <HeaderWrapper className={show ? 'navbar navbarSticky' : 'navbar'}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Logo />

              <LinksContainer>
                <Link to="/creators">{t('Creators')}</Link>
                {/* <Link to="/pricing">{t('Pricing')}</Link> */}
                <Link to="/about-us">{t('About us')}</Link>
                <Link to="/contact-us">{t('Contact us')}</Link>
              </LinksContainer>
            </Box>

            <CustomBox>
              <Box>
                <LanguageSwitcher />
              </Box>
              <Link to="/become-a-creator">{t('Become a creator')}</Link>
              <Box>
                {account ? (
                  <UserBox />
                ) : (
                  <Button
                    component={RouterLink}
                    to={account === null ? '/account/login' : '/business'}
                    variant="contained"
                    sx={{
                      ml: 2
                    }}
                  >
                    {t('Log in / Sign up')}
                  </Button>
                )}
              </Box>
            </CustomBox>
          </Box>
        </Container>
      </HeaderWrapper>
    </>
  );
}

export default Header;
