import { Box, Button, Typography, styled, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store';

const CustomContainer = styled('div')(
  ({ theme }) => `
    background: ${theme.colors.primary.lighter};
  
    border-radius: 10px;
`
);
const CustomTextContainer = styled('div')(
  ({ theme }) => `
  padding: 80px;

  @media (max-width: 425px) {
    padding: 30px;
  }
`
);
const CustomBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
);
const CustomImg = styled('img')(
  ({ theme }) => `
  height: 415px;

  @media (max-width: 1024px) {
    height: 300px;
  }
  @media (max-width: 425px) {
    height: 200px;
  }
`
);
const CustomGradient = styled('span')(
  ({ theme }) => `
  background: -webkit-linear-gradient(360deg,#020024 0%,${theme.colors.primary.main} 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
);

function OrderUGC() {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const {
    ConfigsReducer: { loggedIn }
  } = useSelector((state: RootState) => state);

  return (
    <Container>
      <CustomContainer>
        <CustomBox>
          <CustomTextContainer>
            <Typography
              variant="h1"
              mb={3}
              sx={{
                fontSize: {
                  md: 65,
                  sm: 30,
                  xs: 20
                }
              }}
            >
              {t('Authentic UGC Videos Tailored to')}{' '}
              {/* Original UGC Videos, Made for{' '} */}
              <CustomGradient>{t('Your Product')}</CustomGradient>
            </Typography>
            <Typography variant="h4" mb={3} lineHeight={1.6}>
              {t(
                "Don't wait any longer - sign up and start benefiting from the impact of UGC today! What's more, we proudly offer a 30-day money-back guarantee, ensuring you have nothing to lose"
              )}
              .
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() =>
                navigate(loggedIn ? '/dashboard/main' : '/account/login')
              }
            >
              {t('Order UGC Video Now!')}
            </Button>
          </CustomTextContainer>
          <Box>
            <CustomImg
              alt="task"
              src="/static/images/placeholders/orderUgc.png"
              loading="lazy"
            />
          </Box>
        </CustomBox>
      </CustomContainer>
    </Container>
  );
}

export default OrderUGC;
